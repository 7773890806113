import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { cloneDeep } from 'lodash'
import { fetchJobList, fetchJobSummary } from '../../../../states/actions'
import {
  clientService,
  employeeService,
  employeeFileService,
  funderService,
  fvpClientFundingService,
  fvpRatesService,
  jvpJobFileService,
  jvpJobService,
  jobTimesheetService,
  authService,
  settingFileCategoryService,
  settingCancellationService,
  settingFileTypeService,
  settingGeneralService,
  settingHolidayService,
  settingOtherService
} from '../../../../services'
import { common, formatter, log, validator } from '../../../../util'
import { jobURL, timezone } from '../../../../config'
import moment from 'moment-timezone'

// UI
import { DateTimePicker, List, Loading, Page, Panel, SideModal } from '../../../../components'
import notify from '../../../../components/Notification'
import ActivityLog from '../../ActivityLog'
import AddFileModal from '../../AddFileModal'
import Communication from '../../Communication'
import Cost from '../../Cost'
import Feedback from '../../Feedback'
import File from '../../File'
import TimesheetTemplate from '../../Timesheet'
import DurationBreakdown from '../../../../util/durationFvp'
import DurationExtendPeriod from '../../../../util/extendPeriod'

import '../../styles.css'
import Alert from 'antd/lib/alert'
import Badge from 'antd/lib/badge'
import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Popconfirm from 'antd/lib/popconfirm'
import Skeleton from 'antd/lib/skeleton'
import Select from 'antd/lib/select'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Notification from 'antd/lib/notification'
import TimePicker from 'antd/lib/time-picker'

const { Item: FormItem } = Form
const { confirm, error, info, warning } = Modal
const { TextArea } = Input
const TabPane = Tabs.TabPane
const { Option, OptGroup } = Select

const TIMEOUT = 150

moment.tz.setDefault(timezone)

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 17 }
}

const shortFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 8 },
  wrapperCol: { sm: 14, md: 14, lg: 12 }
}

const threeFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 12 },
  wrapperCol: { sm: 14, md: 14, lg: 12 }
}

// const fourFormItemLayout = {
//   labelCol: { sm: 6, md: 6, lg: 19 },
//   wrapperCol: { sm: 14, md: 14, lg: 5 }
// }

const TabList = [
  { tabId: 1, path: '/info' },
  { tabId: 2, path: '/comm' },
  { tabId: 8, path: '/feedbacks' },
  { tabId: 3, path: '/files' },
  { tabId: 6, path: '/timesheet' },
  { tabId: 7, path: '/costing' },
  { tabId: 4, path: '/logs' }
]

const dateFormat2 = 'YYYY-MM-DD'
const dateFormatComplete = 'DD/MM/YYYY, dddd'
const PmsCreateJob = 'createJob'
const PmsUpdateJob = 'updateJob'
const PmsUpdateJobFeedback = 'updateJobFeedback'
const PmsReadJobCosting = 'readJobCosting'
const JobDefaultAssignedHours = 2  // default hours to auto assign end date time when start date time is selected
const JobSleepoverDetectionMinutes = 240 // default minutes to detect sleepover alert. if the job start date within the range of minutes, a sleepover asking modal is triggered


class JvpSingleJobPage extends Component {
  constructor(props) {
    super(props)
    const { match, location } = props
    const { key = undefined } = location
    const { params = {} } = match
    const { tab = '' } = params
    const selectedTab = TabList.find(e => e.path === tab || e.path === `/${tab}`)

    this.state = {
      // ids
      clientId: null,
      employeeId: null,
      upcomingEmployeeId: null,
      funderId: null,
      // info
      clientInfo: {},
      employeeInfo: {},
      funderInfo: {},
      prevFunderInfo: {},
      fileInfo: {},
      rateSetInfo: {},
      clientList: [],
      employeeList: [],
      employeePrevList: [],
      funderList: [],
      funderPeriodList: [],
      billingCategoryList: [],
      fileList: [],
      // info misc
      currentFundingPeriodInfo: {},
      clientCheckInfo: {},
      clientPrivateAlert: '',
      clientPublicAlert: '',
      clientLanguages: [],
      clientLanguagesName: [],
      clientSkills: [],
      clientSkillsName: [],
      employeeExpiredFiles: [],
      employeeJobHoursInfo: {},
      employeePrivateAlert: '',
      employeePublicAlert: '',
      employeeLanguages: [],
      employeeSkills: [],
      // job item
      cachedBillingCategoryId: null,
      jobDurationTextBreakdown: null,
      jobDurationTextHours: null,
      item: {},
      itemOri: {},
      // holidayInfo,
      isHoliday: false,
      holidayInfoList: [], // different with recurring, holiday info in single job is array type because fetching d1 & d2 holiday details
      // leave info
      clientLeaveInfo: {},
      employeeLeaveInfo: {},
      isClientLeave: false,
      isEmployeeLeave: false,
      // mismatch info
      isMismatchLanguage: false,
      isMismatchSkill: false,
      mismatchedLanguageList: [],
      mismatchedSkillList: [],
      // clashed info
      clashedClients: null,
      clashedEmployees: null,
      // conflicted info,
      clientConflictInfo: {},
      // employee change reason info
      employeeChangeReasonId: null,
      employeeChangeReasonName: null,
      employeeChangeReasonTypeId: null,
      employeeChangeReasonTypeName: null,
      employeeChangeOtherReason: null,
      employeeChangeNote: null,
      isEmployeeChangeDone: false,
      isEmployeeChangeReasonOther: false,
      // job cancel reason info
      jobCancelReasonId: null,
      jobCancelReasonName: null,
      jobCancelReasonTypeId: null,
      jobCancelReasonTypeName: null,
      jobCancelOtherReason: null,
      jobCancelNote: null,
      isJobCancelReasonOther: false,
      // files related
      fileMainCategoryList: [],
      fileSubCategoryList: [],
      // conflict job lists
      conflictJobList: [],
      conflictJobListClient: [],
      conflictJobListEmp: [],
      conflictJobErrorMsg: null,
      // loading flags
      loading: false,
      loadingSave: false,
      loadingJobCancelUpdate: false,
      loadingAffectedJobList: false,
      loadingCheckClient: false,
      loadingFileCats: false,
      // normal flags
      isCustomSO: false,
      isEmergency: false,
      isDisableCategoriesSelect: false,
      isEmployeePending: false,
      isTimesheetSignoff: null,
      isShowSave: false,
      isShowSleepoverAlert: false,
      isFrontendUnhide: false,
      // sleepover related
      isExtSleepover: false,
      isOvernightSleepover: false,
      extHoursList: [],
      extHoursOriList: [],
      extSleepoverList: [],
      extSleepoverOriList: [],
      textSleepoverMismatch: null,
      extBreakdownInfo: new DurationExtendPeriod({}),
      // setting lists
      settingsAll: [],
      settingsPayroll: [],
      settingsOthers: {},
      settingsCancelList: [],
      settingsCancelListReasonAll: [],
      settingsCancelListCurrentReason: [],
      // modal related
      isShowEmployeeReasonModal: false,
      isShowFunderModal: false,
      // cancel modal related
      isJobCancelModal: false,
      isJobCancelConfirmModal: false,
      // employee emergency change related
      isEmployeeEmergencyModal: false,
      // add file modal related
      isShowAddFileModal: false,
      modalFileInfo: {},
      // UI
      isLogRefresh: false,
      currentTab: selectedTab && selectedTab.tabId ? String(selectedTab.tabId) : '1',
      prevUnsentCommTotal: null,
      unsentCommTotal: 0,
      pageKey: key,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { match, location } = nextProps
    const { params = {} } = match

    // must check whether prev state key and locaction key are both undefined, and skip if it is
    if (prevState.pageKey === undefined && location.key === undefined) {

    } else if (prevState.pageKey !== location.key) {
      // not only check the page key but also need to check current path params. if within the same url path (navigate between tabs), do not reload the page.
      if (validator.isNumberText(params.id) && params.tab !== undefined) {

      } else {
        if (window) window.location.reload()
      }
    }

    return { ...prevState, pageKey: location.key }
  }

  componentDidMount () {
    this.checkUrl()
    this.initialize()
  }

  checkUrl = () => {
    // if the url is /:id only (but not /add), redirect to /:id/:tab,
    const { history, match, location } = this.props
    const { currentTab } = this.state
    const { params = {} } = match
    const { id, tab = '' } = params

    if (!(id === 'add' || id === 'new')) {
      const t = TabList.find(e => `${e.tabId}` === currentTab)

      if (!tab) {
        if (location && location.pathname) {
          history.replace(`${location.pathname}${t && t.tabId ? `${t.path}` : ''}`)
        }
      }
    }
  }

  render () {
    const { history, form } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const {
      clashedClients,
      clashedEmployees,
      clientInfo,
      employeeChangeNote,
      employeeChangeOtherReason,
      employeeChangeReasonId,
      employeeChangeReasonTypeId,
      funderInfo,
      funderList,
      clientPrivateAlert,
      clientPublicAlert,
      fileMainCategoryList,
      fileSubCategoryList,
      isShowSave,
      item,
      isClientLeave,
      isEmployeeChangeReasonOther,
      isEmployeeEmergencyModal,
      isFrontendUnhide,
      isJobCancelModal,
      isJobCancelReasonOther,
      isJobCancelConfirmModal,
      isLogRefresh,
      isShowAddFileModal,
      isShowEmployeeReasonModal,
      isShowFunderModal,
      isTimesheetSignoff,
      jobCancelReasonId,
      jobCancelReasonTypeId,
      jobCancelOtherReason,
      jobCancelNote,
      jobDurationTextHours,
      modalFileInfo,
      settingsCancelList,
      settingsCancelListCurrentReason,
      settingsOthers,
      loading,
      loadingJobCancelUpdate,
      currentTab,
      unsentCommTotal,
    } = this.state

    const jobId = this.getJobId()
    const modeText = !this.isEdit() ? '' : isShowSave ? `(Edit Mode)` : `(View Mode)`
    const isInfoTab = currentTab === '1'
    const isFeedbackTab = currentTab === '8'
    const isBaseJob = !!item.base_job_id
    const isCancelled = !!item.is_cancel

    const title = `${this.isEdit() ? '' : `New `}Single Job ${clientInfo && clientInfo.id ? `For ${clientInfo.first_name} ${clientInfo.last_name} ` : ''}${isBaseJob ? `(Recurring Job) ` : ''}${isCancelled ? `(Cancelled) ` : ''}${modeText}`

    const isShowClientPrivateAlert = clientPrivateAlert && clientPrivateAlert.length > 0
    const isShowClientPublicAlert = clientPublicAlert && clientPublicAlert.length > 0

    const cancellationChargeHours = this.getCancellationChargeHours()
    const cancellationOriginalHours = this.getCancellationOriginalHours()

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={`${title}`}>
            { this.isEdit() && isBaseJob && isInfoTab
              ? (
                <Link to={`${jobURL}/recurring/${item.base_job_id}`}>
                  <div className='btn btn-ghost'>
                    { !isCancelled ? 'Edit Base Job' : 'Show Base Job' }
                  </div>
                </Link>
              ) : null }

            { isShowSave && this.isEdit() && this.hasAccess(PmsUpdateJob) && isInfoTab
              ? (isCancelled
                ? <div className='btn' onClick={() => this.handleJobUncancelAlert()} style={{ marginRight: 15 }}>Uncancel Job</div>
                : <div className='btn' onClick={() => this.triggerJobCancelModal(true)} style={{ marginRight: 15 }}>Cancel Job</div>)
              : null}

            { !isShowSave && this.isEdit() && this.hasAccess(PmsUpdateJob) && isInfoTab
              ? <div className='btn' onClick={this.handleEditButton}>Edit</div>
              : null }

            { ((isShowSave && this.hasAccess(PmsUpdateJob) && !isCancelled) || (!this.isEdit() && this.hasAccess(PmsCreateJob))) && isInfoTab
              ? <div className='btn' onClick={() => this.checkBeforeSave()}>
                { loading ? <Icon type='loading' style={{ fontSize: 13 }} /> : 'Save' }
              </div>
              : null }

            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>

          <Form className='jobs-header-form' layout="inline">
            { this.isEdit()
              ? <FormItem className='hidden' label="">
                {getFieldDecorator(`job_feedback_text`, {
                  initialValue: this.replacer(item.job_feedback_text)
                })(
                  <Input type='hidden' disabled={!this.hasAccess(PmsUpdateJobFeedback) || !getFieldValue(`is_job_feedback`)} />
                )}
              </FormItem>
              : null }

            { this.isEdit() && this.hasAccess(PmsUpdateJob) && isInfoTab && isClientLeave
              ? <FormItem label='Show this job to Employee?'>
                {getFieldDecorator('is_frontend_unhide', {
                  initialValue: isFrontendUnhide || false,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                    onChange={this.handleJobFrontendUnhide}
                  />
                )}
              </FormItem>
              : null }

            { this.isEdit() && (isInfoTab || isFeedbackTab)
              ? <FormItem label="Feedback">
                {getFieldDecorator('is_job_feedback', {
                  initialValue: item.is_job_feedback ? item.is_job_feedback : false,
                  valuePropName: 'checked'
                })(
                  <Switch
                    onChange={this.handleJobFeedbackChange}
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                    disabled={!this.hasAccess(PmsUpdateJobFeedback)}
                  />
                )}
              </FormItem>
              : null }

            { this.isEdit() && this.hasAccess('createFeedback') && (isInfoTab || isFeedbackTab)
              ? <FormItem label="">
                <Link to={`/feedbacks/add?jobId=${jobId}`}>
                  <div className='btn btn-ghost'>
                    Add Feedback
                  </div>
                </Link>
              </FormItem>
              : null }
          </Form>

          {/* { this.isEdit() && this.hasAccess(PmsUpdateJob) && isInfoTab
            ? <Row>
              <Col lg={20} />
              <Col style={{alignItems: 'center', justifyContent: 'center' }} lg={4}>
                <FormItem {...fourFormItemLayout} label='Show this job to Employee?'>
                  {getFieldDecorator('is_frontend_unhide', {
                    initialValue: isFrontendUnhide || false,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                      onChange={this.handleJobFrontendUnhide}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            : null } */}

          { isShowClientPrivateAlert || isShowClientPublicAlert
            ? <div>
              <Alert
                message={
                  <div dangerouslySetInnerHTML={{
                    __html: `<span style="font-weight: bold;">Client: ${clientInfo.first_name} ${clientInfo.last_name}</span><br />` +
                      `${clientPublicAlert ? `${clientPublicAlert}<br />` : ''}` +
                      `${clientPrivateAlert ? `${clientPrivateAlert}<br />` : ''}`
                  }} />
                }
                type='warning'
                showIcon
              />
              <br />
            </div>
            : null }

          { clashedClients || clashedEmployees
            ? <div>
              <Alert
                message={
                  <div dangerouslySetInnerHTML={{
                    __html: `<span style="font-weight: bold;">Address</span><br />` +
                    `${clashedClients ? `Same address with client: ${clashedClients}<br />` : ''}` +
                    `${clashedEmployees ? `Same address with employee: ${clashedEmployees}<br />` : ''}`
                  }} />
                }
                type='warning'
                showIcon
              /><br />
            </div>
            : null }
          { this.isEdit() && item.emergency
            ? (item.emergency_pay && item.emergency_invoice
              ? <div className='job-remark-div' style={{ backgroundColor: '#ff526ebb' }}><Icon type='exclamation-circle' /> This is an Emergency job <b>with Emergency Pay and Emergency Invoice</b>.</div>
              : item.emergency_pay
                ? <div className='job-remark-div' style={{ backgroundColor: '#ea3471bb' }}><Icon type='exclamation-circle' /> This job is <b>Emergency Pay only</b>.</div>
                : item.emergency_invoice
                  ? <div className='job-remark-div' style={{ backgroundColor: '#ff5b5bbb' }}><Icon type='exclamation-circle' /> This job is <b>Emergency Invoice only</b>.</div>
                  : <div className='job-remark-div' style={{ backgroundColor: '#ff0000bb' }}><Icon type='exclamation-circle' /> This is an Emergency job.</div>
            )
            : null }

          { this.isEdit() && !!item.cancellation_penalty && cancellationChargeHours !== null
            ? <div className='job-remark-div' style={{ backgroundColor: '#b17bcd' }}><Icon type='info-circle' /> This job has Late Cancellation {cancellationChargeHours} hour{cancellationChargeHours === 1 ? '' : 's'} charge (original {cancellationOriginalHours} hour{cancellationOriginalHours === 1 ? '' : 's'}).</div>
            : null }

          { !!isBaseJob
            ? <div className='job-remark-div' style={{ backgroundColor: '#1890ffbb' }}><Icon type='info-circle' /> This is a Recurring job</div>
            : null }

          { funderInfo && funderInfo.id === null
            ? <div className='job-remark-div' style={{ backgroundColor: '#faad13', color: '#f4f4f4' }}><Icon type='exclamation-circle' /> The client does not have active funding period so unable to show the funder properly.</div>
            : null }

          <Tabs defaultActiveKey={currentTab} activeKey={currentTab} onChange={(e) => this.handleTabChange(e)}>
            <TabPane tab={<div><Icon type='info-circle' /> Information</div>} key='1'>
              { this.renderInfoTab() }
            </TabPane>

            { this.isEdit()
              ? <TabPane tab={<div><Icon type='mail' /> Communication <Badge count={unsentCommTotal} /></div>} key='2' forceRender>
                <Communication key={`cmssc${currentTab}`} jobId={jobId} moduleType={'job'} onUpdate={(value) => this.handleCommUpdate(value)} />
              </TabPane>
              : null }

            { this.isEdit()
              ? <TabPane tab={<div><Icon type='notification' /> Feedbacks</div>} key='8' forceRender>
                <Feedback key={`fsc${currentTab}`} jobId={jobId} history={this.props.history} />
              </TabPane>
              : null }

            { this.isEdit()
              ? <TabPane tab={<div><Icon type='safety' /> Files</div>} key='3'>
                <File key={`fcssc${currentTab}`} jobId={jobId} moduleType={'job'} history={history} onRefreshPage={() => this.refreshPage()} />
              </TabPane>
              : null }

            { this.isEdit()
              ? <TabPane tab={<span><Icon type='edit' /> Timesheet { isTimesheetSignoff === true ? <span style={{ color: '#4fbc85', fontSize: '11pt' }}>&nbsp;<Icon type='check-circle' theme='filled' /></span> : isTimesheetSignoff === false ? <span style={{ color: 'red', fontSize: '11pt' }}>&nbsp;<Icon type='close-circle' theme='filled' /></span> : null }</span>} key='6'>
                { this.renderTimesheetTab() }
              </TabPane>
              : null }

            { this.isEdit() && this.hasAccess(PmsReadJobCosting)
              ? <TabPane tab={<div><Icon type='dollar' /> Costing </div>} key='7'>
                <Cost key={`cstsc${currentTab}`} jobId={jobId} job={item} breakdown={jobDurationTextHours}/>
              </TabPane>
              : null }

            { this.isEdit()
              ? <TabPane tab={<div><Icon type='bars' /> Activity Log</div>} key='4'>
                <ActivityLog key={`actsc${currentTab}`} jobId={jobId} moduleType={'job'} isLogRefresh={isLogRefresh} onUpdateRefresh={() => this.updateRefreshLogComplete()} />
              </TabPane>
              : null }
          </Tabs>
        </Page.Content>


        {/* --------------------------------------CANCEL JOB SIDE MODAL START---------------------------------------- */}
        <SideModal
          title='Cancel Job'
          showModal={isJobCancelModal}
          onClose={() => this.triggerJobCancelModal(false)}
          buttons={[
            <Button key='cancel-job-cancel' type='primary' loading={loadingJobCancelUpdate} onClick={() => this.onSaveJobCancel()}>Cancel Job</Button>
          ]}
        >
          { isJobCancelModal
            ? <div>
              <FormItem label='Cancellation Type'>
              {getFieldDecorator('job_cancel_reason_type_id', {
                initialValue: jobCancelReasonTypeId,
                rules: [
                  { required: true, message: 'Please select cancellation type' }
                ]
              })(
                <Select disabled={loadingJobCancelUpdate} style={{ width: '100%' }} onChange={this.handleJobCancelTypeChange}>
                  {
                    settingsCancelList.map((items, idx) => {
                      return <Option key={`crs-${idx}`} value={items.id}>{items.name}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
            <FormItem label='Cancellation Reason'>
              {getFieldDecorator('job_cancel_reason_id', {
                initialValue: jobCancelReasonId,
                rules: [
                  { required: true, message: 'Please select reason' }
                ]
              })(
                <Select disabled={loadingJobCancelUpdate} style={{ width: '100%' }} onChange={this.handleJobCancelReasonChange}>
                  { settingsCancelListCurrentReason.map((items, idx) => {
                      return <Option key={`crsl-${idx}`} value={items.id}>{items.name}</Option>
                    }) }
                </Select>
              )}
            </FormItem>
            { isJobCancelReasonOther
              ? (
                <FormItem label='Other Reason For Cancellation'>
                  {getFieldDecorator('job_cancel_other_reason', {
                    initialValue: jobCancelOtherReason,
                  })(
                    <TextArea disabled={loadingJobCancelUpdate} row={2} />
                  )}
                </FormItem>
              )
              : null }
            <FormItem label='Notes (Optional)'>
              {getFieldDecorator('job_cancel_note', {
                initialValue: jobCancelNote,
              })(
                <TextArea disabled={loadingJobCancelUpdate} rows={2} />
              )}
            </FormItem>
          </div>
          : null }
        </SideModal>
        {/* --------------------------------------CANCEL JOB SIDE MODAL END---------------------------------------- */}

        {/* --------------------------------------CANCEL CONFIRM MODAL START---------------------------------------- */}
        <Modal
          width={550}
          title='Are you sure you want to cancel this job?'
          visible={isJobCancelConfirmModal}
          onOk={() => this.onSaveJobCancellation()}
          onCancel={() => this.triggerJobCancelConfirmModal(false)}
          footer={[
            <Button key='job-cancel-late' type='primary' loading={loadingJobCancelUpdate} onClick={() => this.onSaveJobCancellation(true)}>Late Cancel</Button>,
            <Button key='job-cancel-normal' ghost type='primary'  loading={loadingJobCancelUpdate} onClick={() => this.onSaveJobCancellation(false)}>Normal Cancel</Button>,
            <Button key='job-cancel-cancel' loading={loadingJobCancelUpdate} onClick={() => this.triggerJobCancelConfirmModal(false)}>Do Not Cancel</Button>
          ]}
        >
          <div className='modal-body-div'>
            <div className='icon-large'>
              <Icon type='question-circle' />
            </div>

            <div>
              You are cancelling this job within {settingsOthers.cancellation_notice} hours of start time making it a Late Cancellation.<br /><br />
              Please confirm if Late Cancellation charges should be applied.<br /><br />
              <div>Late Charge: <b>{cancellationChargeHours} hour{cancellationChargeHours === 1 ? '' : 's'}</b></div><br /><br />
              <div>Note: The updated info will not be saved when performing job cancellation.</div>
            </div>

          </div>
        </Modal>
        {/* --------------------------------------CANCEL CONFIRM MODAL END---------------------------------------- */}

        {/* --------------------------------------CHANGE EMPLOYEE MODAL START ---------------------------------------- */}
        <SideModal
          title='You Have Changed Employee'
          showModal={isShowEmployeeReasonModal}
          onClose={() => this.triggerEmployeeChangeModal(false)}
          buttons={[
            <Button key={`empsump`} type='primary' onClick={() => this.handleSubmitEmployeeChangeReason()}>Submit</Button>
          ]}
        >
          { isShowEmployeeReasonModal
            ? <div>
              <FormItem label='Reason Type'>
                {getFieldDecorator('employee_change_reason_type_id', {
                  initialValue: employeeChangeReasonTypeId,
                  rules: [
                    { required: true, message: 'Please select change type' }
                  ]
                })(
                  <Select style={{ width: '100%' }} onChange={this.handleJobCancelTypeChange}>
                    { settingsCancelList.map((items, idx) => {
                        return <Option key={`cts${idx}`} value={items.id}>{items.name}</Option>
                      }) }
                  </Select>
                )}
              </FormItem>

              <FormItem label='Reason To Change'>
                {getFieldDecorator('employee_change_reason_id', {
                  initialValue: employeeChangeReasonId,
                  rules: [
                    { required: true, message: 'Please select reason' }
                  ]
                })(
                  <Select style={{ width: '100%' }} onChange={this.handleEmployeeChangeReasonChange}>
                    { settingsCancelListCurrentReason.map((items, idx) => {
                        return <Option key={`ctsl-${idx}`} value={items.id}>{items.name}</Option>
                      }) }
                  </Select>
                )}
              </FormItem>

              { isEmployeeChangeReasonOther
                ? <FormItem label='Other Reason To Change'>
                  {getFieldDecorator('employee_change_other_reason', {
                    initialValue: employeeChangeOtherReason,
                  })(
                    <TextArea row={2} />
                  )}
                </FormItem>
                : null }

              <FormItem label='Notes (Optional)'>
                {getFieldDecorator('employee_change_note', {
                  initialValue: employeeChangeNote,
                })(
                  <TextArea rows={2} />
                )}
              </FormItem>
            </div>
            : null }
        </SideModal>
        {/* --------------------------------------CHANGE EMPLOYEE MODAL END---------------------------------------- */}

        {/* --------------------------------------CHANGE EMPLOYEE EMG CONFIRM MODAL START---------------------------------------- */}
        <Modal
          width={550}
          title='Do you want to change Employee?'
          visible={isEmployeeEmergencyModal}
          onOk={() => this.handleEmployeeChangeEmergency()}
          onCancel={() => this.handleEmployeeChangeCancel()}
          footer={[
            <Button key='emp-emg-emergency' type='primary' onClick={() => this.handleEmployeeChangeEmergency(true)}>Emergency</Button>,
            <Button key='emp-emg-inemergency' onClick={() => this.handleEmployeeChangeEmergency(false)}>Not Emergency</Button>,
            <Button key='emp-emg-cancel' onClick={() => this.handleEmployeeChangeCancel()}>Cancel</Button>
          ]}
        >
          <div className='modal-body-div'>
            <div className='icon-large'>
              <Icon type='question-circle' />
            </div>
            <div><b>Changing the employee now will incur emergency charge.</b> Are you sure to proceed?</div>
          </div>
        </Modal>
        {/* --------------------------------------CHANGE EMPLOYEE EMG MODAL END---------------------------------------- */}

        {/* --------------------------------------FUNDER MODAL START---------------------------------------- */}
        <Modal
          width={600}
          title='Change Funder'
          visible={isShowFunderModal}
          onOk={this.handleFunderSubmit}
          onCancel={this.handleFunderCancel}
          footer={[
            <Row>
              <Button key='change-funder-confirm' type='primary' onClick={this.handleFunderSubmit}>Confirm</Button>
              <Button key='change-funder-cancel' onClick={this.handleFunderCancel}>Cancel</Button>

            </Row>

          ]}
        >
          { isShowFunderModal
            ? <div>
                <FormItem {...formItemLayout} label='Funder' hasFeedback>
                {getFieldDecorator('funder_id', {
                  initialValue: funderInfo.id
                })(
                  <Select
                    style={{ width: '100%' }}
                    placeholder='Funders'
                    showSearch
                    filterOption={this.findOption}>
                    { funderList.map((funder, idx) => (<Option key={`fusx-${idx}`} value={funder.funder_id}>
                        {funder.funder_fullname}
                      </Option>)) }
                  </Select>
                )}
              </FormItem>
            </div>
            : null }
        </Modal>
        {/* --------------------------------------FUNDER MODAL END---------------------------------------- */}

        {/* --------------------------------------ADD FILE MODAL START---------------------------------------- */}
        <AddFileModal
          jobId={'add'}
          module={'job'}
          key={`job_addfile_new`}
          item={modalFileInfo}
          categoriesList={fileMainCategoryList}
          subCategoriesList={fileSubCategoryList}
          onClose={() => this.handleAddFileModal(false)}
          onSetFile={(values) => this.updateFileAdded(values)}
          visible={isShowAddFileModal}
        />
        {/* --------------------------------------ADD FILE MODAL END---------------------------------------- */}
      </Page.Body>
    )
  }

  renderTimesheetTab = () => {
    const { clientInfo = {}, currentTab, item = {}, loading } = this.state

    return (
      <Loading key={`tstsc${currentTab}`} loading={loading} blur>
        <TimesheetTemplate
          info={item}
          clientInfo={clientInfo}
          mode={'view'}
          onDeleteTimesheet={() => this.handleDeleteTimesheet(item)}
          onSavedJob={() => this.refreshPage()}
          loading={loading}
        />
      </Loading>
    )
  }

  renderInfoTab = () => {
    const { form } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const {
      billingCategoryList,
      clientInfo,
      clientLeaveInfo,
      clientPrivateAlert,
      clientPublicAlert,
      clientLanguagesName,
      clientSkillsName,
      clientConflictInfo,
      clashedClients,
      clashedEmployees,
      conflictJobList,
      conflictJobListClient,
      conflictJobListEmp,
      conflictJobErrorMsg,
      currentFundingPeriodInfo,
      employeeExpiredFiles,
      // employeeChangeNote,
      // employeeChangeOtherReason,
      // employeeChangeReasonId,
      // employeeChangeReasonTypeId,
      employeeId,
      employeeInfo,
      employeeLeaveInfo,
      employeeJobHoursInfo,
      employeePrivateAlert,
      employeePublicAlert,
      employeeLanguages,
      employeeSkills,
      employeeList,
      employeePrevList,
      extSleepoverList,
      fileList,
      fileMainCategoryList,
      fileSubCategoryList,
      funderInfo,
      // funderList,
      holidayInfoList,
      item,
      isClientLeave,
      isEmployeeLeave,
      isCustomSO,
      isDisableCategoriesSelect,
      // isEmployeeEmergencyModal,
      isEmployeePending,
      // isEmployeeChangeReasonOther,
      // isJobCancelReasonOther,
      isExtSleepover,
      isEmergency,
      isHoliday,
      // isJobCancelModal,
      // isJobCancelConfirmModal,
      isMismatchLanguage,
      isMismatchSkill,
      // isShowAddFileModal,
      // isShowEmployeeReasonModal,
      // isShowFunderModal,
      isShowSleepoverAlert,
      jobDurationTextBreakdown,
      jobDurationTextHours,
      jobCancelReasonId,
      jobCancelReasonTypeId,
      jobCancelOtherReason,
      jobCancelNote,
      loading,
      loadingSave,
      // loadingJobCancelUpdate,
      mismatchedLanguageList,
      mismatchedSkillList,
      // modalFileInfo,
      // settingsOthers,
      settingsPayroll,
      // settingsCancelList,
      // settingsCancelListCurrentReason,
      // settingsCancelListReasonAll,
      textSleepoverMismatch,
      upcomingEmployeeId,
    } = this.state

    const billingCategoryListPreferred = validator.isNotEmptyArray(billingCategoryList) ? billingCategoryList.filter(e => e.is_preferred === true) : []
    const billingCategoryListOther = validator.isNotEmptyArray(billingCategoryList) ? billingCategoryList.filter(e => e.is_preferred === false) : []

    const jsd = form.getFieldValue('job_start_date')
    const jed = form.getFieldValue('job_end_date')
    const isSleepoverToggleEnabled = !!jsd && funderInfo && funderInfo.id && validator.isNotEmptyArray(billingCategoryList) && billingCategoryList.findIndex(e => e.is_sleepover === true) > -1

    const isSleepoverListEnabled = !jsd
      ? -1 // no job start date, the list not showing up
      : jsd > moment(new Date())
      ? 0 // job start date before current date, the list not showing up
      : !!jsd && funderInfo && funderInfo.id && jsd && isSleepoverToggleEnabled
        ? 3 // allow to edit get ups
        : funderInfo && funderInfo.id === null
          ? 2 // unable to edit get ups, mostly due to no funder info
          : 1 // waiting funder info to get loaded


    const isCurrentFundingPeriod = currentFundingPeriodInfo && currentFundingPeriodInfo.id
      ? 2 // a valid funding period (with id) is available
       : currentFundingPeriodInfo && currentFundingPeriodInfo.id === null
        ? 1 // the current job date is out of any available funding period (but available in appended funding)
        : 0 // no current funding period info
    const fundingPeriodText = isCurrentFundingPeriod === 2 ? `${formatter.toShortDate(currentFundingPeriodInfo.start_date)} - ${formatter.toShortDate(currentFundingPeriodInfo.end_date)}` : ''

    const isNoEmployeeAlert = this.isEdit() && isEmployeePending

    const isShowEmployeePrivateAlert = employeePrivateAlert && employeePrivateAlert.length > 0
    const isShowEmployeePublicAlert = employeePublicAlert && employeePublicAlert.length > 0

    const isShowEmpMaxHoursAlert = employeeJobHoursInfo.is_employee_has_max_hours !== undefined && employeeJobHoursInfo.is_employee_has_max_hours

    const fileColumns = [
      {
        title: 'Main Category',
        width: 4,
        render: ({ main_category_id }) => {
          const mainCat = fileMainCategoryList.find(e => e.id === main_category_id)

          return <div>{mainCat ? mainCat.name : ''}</div>
        }
      },
      {
        title: 'Sub Category',
        width: 4,
        render: ({ sub_category_id }) => {
          const subCat = fileSubCategoryList.find(e => e.id === sub_category_id)

          return <div>{subCat ? subCat.name : ''}</div>
        }
      },
      {
        title: 'Label',
        width: 6,
        render: ({ label, file_name }) => {
          return (
            <div>
              <div>{label}</div>
              <div style={{ color: '#a5a5a5', fontSize: '8pt' }}>{file_name ? `[${formatter.toStandardFileName(file_name)}]` : ''}</div>
            </div>
          )
        }
      },
      {
        title: 'Issuance Date',
        width: 3,
        render: ({ issuance_date }) => formatter.toShortDate(issuance_date)
      },
      {
        title: 'Expiry Date',
        width: 3,
        render: ({ expiry_date }) => formatter.toShortDate(expiry_date)
      },
      {
        title: 'Action',
        width: 1,
        render: (item) => <div className='action-buttons'>
          <Tooltip mouseLeaveDelay={0} title='Edit'>
            <div onClick={() => this.handleAddFileModal(true, item)} style={{ cursor: 'pointer' }}>
              <Icon type='form' />
            </div>
          </Tooltip>
          <Tooltip mouseLeaveDelay={0} title='Delete'>
            <Popconfirm
              title={`Confirm to delete ${item.label ? item.label : 'this'}?`}
              onConfirm={() => this.handleFileDelete(item)}
              okText='Yes'
              cancelText='No'
            ><Icon type='delete' />
            </Popconfirm>
          </Tooltip>
        </div>
      }
    ]

    const isCancelled = !!item.is_cancel
    const jobStartDateText = jsd
      ? (moment.isMoment(jsd)
        ? jsd.format(dateFormatComplete)
        : moment(jsd).format(dateFormatComplete))
      : null
    const jobDatePanelTitle = loading ? '' : `${jobStartDateText ? ` Job Schedule on ${jobStartDateText}` : `Job Schedule is not set`}`

    const cancellationChargeHours = this.getCancellationChargeHours()
    const cancellationOriginalHours = this.getCancellationOriginalHours()

    const isJobCostingUpdated = this.isEdit() && !!item.is_costing_updated

    return (
      <div className='jobs-jvp'>
        <Loading loading={loading || loadingSave} blur>
          <Row>
            <Col lg={24}>
              { isJobCostingUpdated
                ? <div className='alert-block' style={{marginBottom: '10px'}}>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Job Costing is Updated</span> This Job cannot be updated because its costing has been manually overridden. Please revert to the original costing in order to update any changes.</div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div>
                : null }
            </Col>
          </Row>

          <Row className='row-info' gutter={24}>
            <Col className='row-info-col' md={12} lg={12}>
              <div className='info'>
                { !(clientInfo && clientInfo.id)
                  ? <Row>
                    <Col lg={24} className='panel-empty'>
                      <Skeleton paragraph={{ rows: 1 }} />
                    </Col>
                  </Row>
                  : <Row>
                    <Col className='panel-info'>
                      <div className='space-between'>
                        <span className='space-between'>
                          <span className='name'>
                            <a href={`/clients/${clientInfo.id}/info`} rel='noopener noreferrer' target='_blank'>
                              {clientInfo.first_name } { clientInfo.last_name }
                            </a>
                          </span>
                          <span className='accref'>{ clientInfo.acc_ref }</span>
                        </span>

                      </div>
                      { clientInfo.client_leave_id
                        ? <span className='leave-info'><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' />
                          &nbsp;{`Leave ${formatter.toShortDate(clientInfo.client_leave_start_date)} - ${clientInfo.client_leave_is_ufn ? 'UFN' : formatter.toShortDate(clientInfo.client_leave_end_date)}`}
                        </span>
                        : null }
                      <div className='detail'>
                        { clientInfo.phone
                          ? <div className='detail-item'>
                            <Icon type='phone' theme='twoTone' twoToneColor='#ed6d1e' />&nbsp;&nbsp;
                            { clientInfo.phone }
                          </div>
                          : null }
                        { clientInfo.address
                          ? <div className='detail-item'>
                            <Icon type='home' theme='twoTone' twoToneColor='#ed6d1e' />&nbsp;&nbsp;
                            { clientInfo.unit_building ? `${clientInfo.unit_building},` : '' }
                            { clientInfo.address }
                          </div>
                          : null }
                      </div>
                      <div className='detail' style={{ marginTop: 15 }}>
                        <b>Preference</b>
                        { clientInfo.preferred_gender
                          ? <div className='detail-item-span'>
                              <Icon type='user-add' style={{ color: '#ed6d1e' }} />&nbsp;
                              { formatter.capitalize(clientInfo.preferred_gender) }
                          </div>
                          : null }

                        <div className='detail-item'>
                          { validator.isNotEmptyArray(clientLanguagesName)
                          ? <div className='detail-item-span'>
                            <Icon type='font-size' style={{ color: '#ed6d1e' }} />&nbsp;
                            { clientLanguagesName.join(', ') }
                          </div>
                          : null }
                        </div>
                      </div>
                      <div className='detail' style={{ marginTop: 5 }}>
                        <b>Skills</b>
                        <div className='detail-item'>
                          { validator.isNotEmptyArray(clientSkillsName)
                          ? <div className='detail-item-span'>
                            <Icon type='check-circle' style={{ color: '#ed6d1e' }} />&nbsp;
                            { clientSkillsName.join(', ') }
                          </div>
                          : null }
                        </div>
                      </div>
                    </Col>
                  </Row> }
              </div>
            </Col>
            <Col className='row-info-col' md={12} lg={12}>
              <div className='info' style={{backgroundColor: funderInfo && funderInfo.isInvalidFunder ? 'red' : undefined }}>
                { !(funderInfo && funderInfo.id)
                  ? <Row>
                    <Col lg={24} className='panel-empty'>
                      <Skeleton paragraph={{ rows: 1 }} />
                    </Col>
                  </Row>
                  : <Row>
                    <Col className='panel-info'>
                      <div className='space-between'>
                        <span className='space-between'>
                          { funderInfo.fullname
                            ? <span className='name'>
                              <a href={`/funders/${funderInfo.id}`} rel='noopener noreferrer' target='_blank'>
                                { funderInfo.fullname }
                              </a>
                            </span>
                            : <span className='name warning'>
                              <Icon type='exclamation-circle' /> Invalid Funder. Please select a new one.
                            </span> }
                          <span className='accref'>{ funderInfo.acc_ref }</span>
                        </span>
                        <span className='act-button' onClick={() => this.triggerFunderModal(true)}>
                          <Icon type='form' />
                        </span>
                      </div>

                      <div className='detail'>
                        { funderInfo.phone_number
                          ? <div className='detail-item'>
                            <Icon type='phone' theme='twoTone' twoToneColor='#ed6d1e' />&nbsp;&nbsp;
                            { funderInfo.phone_number }
                          </div>
                          : null }
                        { funderInfo.address
                          ? <div className='detail-item'>
                            <Icon type='home' theme='twoTone' twoToneColor='#ed6d1e' />&nbsp;&nbsp;
                            { funderInfo.unit_building ? `${funderInfo.unit_building},` : '' }
                            { funderInfo.address }
                          </div>
                          : null }
                      </div>

                      { funderInfo.isInvalidFunder
                        ? <div>
                          <span className='name warning' style={{marginTop: '15px'}}>
                          <Icon type='exclamation-circle' /> Invalid Funder. Please select a new one.
                        </span>
                        </div>
                        : null }

                      { isCurrentFundingPeriod === 2
                        ? <div>
                          <div className='detail' style={{ marginTop: 15 }}>
                            <b>Fund Period</b>
                            <div className='detail-item'>
                              <div className='detail-item-span'>
                                <Icon type='calendar' style={{ color: '#ed6d1e' }}/>&nbsp;{fundingPeriodText}
                              </div>
                            </div>
                          </div>
                          <div className='detail' style={{ marginTop: 5 }}>
                            <b>Invoice Notes</b>
                            <div className='detail-item'>
                              <div className='detail-item-span'>
                                <Icon type='highlight' style={{ color: '#ed6d1e' }}/>&nbsp;{ currentFundingPeriodInfo.invoice_note ? currentFundingPeriodInfo.invoice_note : '-' }
                              </div>
                            </div>
                          </div>
                        </div>
                        : isCurrentFundingPeriod === 1
                          ? <span className='detail'>
                            <span className='detail-item'>
                              <span className='name warning'>
                                <Icon type='exclamation-circle' style={{ color: '##ff0000' }}/>&nbsp;Client has no funding period<span style={{fontWeight: 'normal', fontSize: '11px'}}>&nbsp;&nbsp;The job date does not fall on any available funding period.</span>
                              </span>
                            </span>
                          </span>
                          : null
                       }
                    </Col>
                  </Row> }
              </div>
            </Col>
          </Row>

          <Panel
            className='info'
            disabled={isJobCostingUpdated}
            title={`${jobDatePanelTitle}`}
            subtitle={<Row>
              <Col lg={24}>
                <div className='duration-text'>
                  { jobDurationTextBreakdown
                    ? <span>
                      <Icon type='clock-circle' />&nbsp;&nbsp;
                      { jobDurationTextBreakdown }
                    </span>
                    : null }
                </div>
              </Col>
            </Row>}
          >
            <Row>
              { isClientLeave && clientLeaveInfo
                ? <div className='alert-block'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Client Not Available</span> {clientInfo.first_name} {clientInfo.last_name} is on leave from {formatter.toShortDate(clientLeaveInfo.leave_start_date)} to {clientLeaveInfo.leave_is_ufn ? 'UFN' : formatter.toShortDate(clientLeaveInfo.leave_end_date)}</div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div>
                : null }

              { validator.isNotEmptyArray(conflictJobListClient)
                ? <div className='alert-block'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Client Has Another Shift</span> {clientInfo.first_name} {clientInfo.last_name} has shift on: {conflictJobListClient.map((cfj, idx) => {
                      return <span>{idx !== 0 ? '; ' : ''}{formatter.toShortDate(cfj.job_start_date)} {formatter.toShortTime(cfj.job_start_date)} to {formatter.toShortTime(cfj.job_end_date)}<a href={`${jobURL}/single/${cfj.id}/info`} target='_blank'>  - <strong><Icon type='eye' /> View Job</strong></a></span>
                    })}</div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div>
                : null }

              { jsd && jed && jed < jsd
                ? <div className='alert-block'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Job Time Error</span> End Time should not be earlier than Start Time </div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div>
                : null }

              { isHoliday && validator.isNotEmptyArray(holidayInfoList)
                ? <div className='alert-block'>
                  <Alert
                    banner
                    message={<div><span style={{ fontWeight: 'bold' }}>Public Holiday</span> {holidayInfoList.map(e => `${e.name} (${formatter.toShortDate(e.date)})`).join(', ')}</div>}
                    type='info'
                    showIcon
                  />
                </div>
                : null }

              { isShowSleepoverAlert
                ? <div className='alert-block'>
                  <Alert
                    banner
                    message={<div><span style={{ fontWeight: 'bold' }}>Is This A Sleepover Job?</span> This job has more than 4 After Hours.</div>}
                    type='info'
                    showIcon
                  />
                </div>
                : null }

              { textSleepoverMismatch
                ? <div className='alert-block'>
                  <Alert
                    banner
                    message={<div><span style={{ fontWeight: 'bold' }}>Sleepover Duration Mismatch</span> {textSleepoverMismatch}</div>}
                    type='warning'
                    showIcon
                  />
                </div>
                : null }

              { isDisableCategoriesSelect
                ? <div className='alert-block'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Client: No Available Funding Period</span> {`${clientInfo.first_name} ${clientInfo.last_name} does not have available funding period for selected job date time.`} </div>}
                    type='warning'
                    banner
                    showIcon
                  />
                </div>
                : null }
            </Row>

            <Row>
              <Col lg={12}>
                <FormItem {...shortFormItemLayout} label='Start Time'>
                  {getFieldDecorator('job_start_date', {
                    initialValue: item.job_start_date ? moment(item.job_start_date) : null,
                    rules: [
                      { required: true, message: 'Please enter job start time' }
                    ]
                  })(
                    <DateTimePicker onChange={this.handleStartDateChange} />
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...shortFormItemLayout} label='End Time'>
                  {getFieldDecorator('job_end_date', {
                    initialValue: item.job_end_date ? moment(item.job_end_date) : null,
                    rules: [
                      { required: true, message: 'Please enter job end time' }
                    ]
                  })(
                    <DateTimePicker onChange={this.handleEndDateChange} />
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row>
              <Col lg={8}>
                <FormItem {...threeFormItemLayout} label='Emergency Job'>
                  {getFieldDecorator('emergency', {
                    initialValue: item.emergency || false,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                      onChange={this.handleEmergencyToggle}
                    />
                  )}
                </FormItem>
              </Col>
              <Col lg={16}>
                { isEmergency
                  ? <div>
                    <Row>
                      <Col lg={12}>
                        <FormItem {...threeFormItemLayout} label='Emergency Pay'>
                          {getFieldDecorator('emergency_pay', {
                            initialValue: item.emergency_pay || false,
                            valuePropName: 'checked'
                          })(
                            <Switch
                              onChange={this.handleEmergencyPayToggle}
                              checkedChildren='Yes'
                              unCheckedChildren='No'
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col lg={12}>
                        <FormItem {...threeFormItemLayout} label='Emergency Invoice'>
                          {getFieldDecorator('emergency_invoice', {
                            initialValue: item.emergency_invoice || false,
                            valuePropName: 'checked'
                          })(
                            <Switch
                              onChange={this.handleEmergencyInvoiceToggle}
                              checkedChildren='Yes'
                              unCheckedChildren='No'
                            />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  </div>
                  : null }
              </Col>
            </Row>
            <Row style={{ display: isCancelled ? '' : 'none' }}>
              <Col lg={12}>
                <FormItem {...shortFormItemLayout} label='Late Cancellation?'>
                  {getFieldDecorator('cancellation_penalty', {
                    initialValue: item.cancellation_penalty || false,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                      onChange={(value) => this.onSaveJobCancellation(value, false)}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Panel>

          <Panel
            className='info'
            disabled={isJobCostingUpdated}
            title='Shift Work'
            type={isEmployeePending ? 'warn' : ''}
          >
            <Row gutter={16} style={{marginBottom: '15px'}}>
              { validator.isNotEmptyArray(conflictJobListEmp)
                ? <div style={{marginBottom: '20px'}}>
                  { conflictJobListEmp.map((c) => {
                      const {
                        id,
                        job_start_date: jobStartDate,
                        job_end_date: jobEndDate,
                        employee_id: eid,
                        employee_fullname: empName,
                        client_id: cid,
                        client_fullname: clientName,
                        funder_fullName: fundName
                      } = c

                      const employeeText = `served by ${empName}`
                      return (
                        <Alert
                          key={`emfs-${id}`}
                          banner
                          message={
                            <div>
                              <a href={`${jobURL}/single/${id}/info`} target='_blank' className='error-block-text-dark'>&nbsp;A job {employeeText} started from {formatter.toStandardLongDate(jobStartDate)} to {formatter.toStandardLongDate(jobEndDate)}</a> is conflicting.
                            </div>
                          }
                          type='error'
                          showIcon
                        />
                      )
                    }) }
                  </div>
                  : null }

              { isShowEmployeePrivateAlert || isShowEmployeePublicAlert
                ? <div className='alert-block'>
                  <Alert
                    message={
                      <div>
                        <span style={{ fontWeight: 'bold' }}>{`Employee: ${employeeInfo.first_name} ${employeeInfo.last_name}`}</span>
                        { employeePublicAlert.length > 0
                          ? <div dangerouslySetInnerHTML={{ __html: employeePublicAlert }} />
                          : null }
                        { employeePrivateAlert.length > 0
                          ? <div dangerouslySetInnerHTML={{ __html: employeePrivateAlert }} />
                          : null }
                      </div>
                    }
                    type='warning'
                    showIcon
                  />
                </div>
                : null }

              { isEmployeeLeave
                ? <div className='alert-block'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Employee On Leave</span> {`${employeeInfo.first_name} ${employeeInfo.last_name}  is on leave during this time. Please choose another employee.`} </div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div>
                : null }

              { isShowEmpMaxHoursAlert
                ? <div className='alert-block'>
                  <Alert
                    banner
                    type='error'
                    showIcon
                    message={<div><span style={{ fontWeight: 'bold' }}>Max Hours</span> {`${employeeInfo.first_name} ${employeeInfo.last_name} already has `}<span className='error-msg'>{employeeJobHoursInfo.employee_total_job_hours}</span>{` total job hours this week, including this job will be `}<span className='error-msg'>{formatter.toDecimalS(employeeJobHoursInfo.employee_new_total_job_hours)}</span>{` total job hours`}{employeeJobHoursInfo.is_employee_over_hour ? `, EXCEEDING` : '.'}{employeeJobHoursInfo.employee_max_hours ? <span className={employeeJobHoursInfo.is_employee_over_hour ? 'error-msg' : 'bold-msg'}>{` Max ${employeeJobHoursInfo.employee_max_hours} hours.`}</span> : ''}</div>}
                  />
                </div>
                : null }

                { isMismatchLanguage
                  ? <div className='alert-block'>
                    <Alert
                      message={<div><span style={{ fontWeight: 'bold' }}>Language Mismatch</span> {`${clientInfo.first_name} ${clientInfo.last_name} speaks these languages: ${mismatchedLanguageList.map(e => e.setting_name).join(', ')},
                        but ${employeeInfo.first_name} ${employeeInfo.last_name} does not. `} </div>}
                      type='error'
                      banner
                      showIcon
                    />
                  </div>
                  : null }

                { isMismatchSkill
                  ? <div className='alert-block'>
                    <Alert
                      message={<div><span style={{ fontWeight: 'bold' }}>Skills Mismatch</span> {`${employeeInfo.first_name} ${employeeInfo.last_name}  does not possess following skill(s): ${mismatchedSkillList.map(e => e.setting_name).join(', ')}`} </div>}
                      type='error'
                      banner
                      showIcon
                    />
                  </div>
                  : null }

                { validator.isNotEmptyArray(employeeExpiredFiles)
                  ? (employeeExpiredFiles.map((file, idx) => {
                    return (
                      <div key={`epfs-${idx}`} className='alert-block'>
                        <Alert
                          message={<div><span style={{ fontWeight: 'bold' }}>{`${file.main_category} - ${file.sub_category} Expired`}</span> {`${employeeInfo.first_name} ${employeeInfo.last_name}'s ${file.main_category} - ${file.sub_category} is/will be expired on the selected date`} </div>}
                          type='error'
                          banner
                          showIcon
                        />
                      </div>
                    )
                  }))
                  : null }
            </Row>

            <Form layout='vertical'>
              <Row gutter={16}>
                <Col lg={8}>
                  <FormItem label={<span>Shift Type
                    <Switch
                      disabled={!isSleepoverToggleEnabled}
                      checkedChildren='Sleepover'
                      unCheckedChildren='Sleepover?'
                      style={{ marginLeft: 10 }}
                      checked={isExtSleepover}
                      onChange={(value) => this.handleSleepover(value, false, true)}
                    />
                  </span>}>
                    {getFieldDecorator('category_id', {
                      initialValue: item.category_id || null,
                      rules: [
                        { required: true, message: 'Please Select Shift Type' }
                      ]
                    })(
                      <Select
                        onSelect={(v, o) => this.handleBillingSelectCategory(o)}
                        disabled={isExtSleepover || isDisableCategoriesSelect}
                        showSearch
                        filterOption={(input, option) =>
                          this.filterBillingCategory(input, option)
                        }>
                          { validator.isNotEmptyArray(billingCategoryListPreferred)
                            ? <OptGroup label={<div key={`bcta-sc`} className='label-title'>----- PREFERRED -----</div>}>
                              { billingCategoryListPreferred.map((bill, idx) => {
                                return (!bill.is_sleepover || isExtSleepover) ? <Option key={`bcsa-${idx}`} value={bill.id}>{bill.name}</Option> : null
                              }) }
                            </OptGroup>
                            : null }
                          { validator.isNotEmptyArray(billingCategoryListOther)
                            ? <OptGroup label={<div key={`bctb-sc`} className='label-title'>{validator.isNotEmptyArray(billingCategoryListOther) ? <p></p> : null}<p>----- OTHER -----</p></div>}>
                              { billingCategoryListOther.map((bill, idx) => {
                                return (!bill.is_sleepover || isExtSleepover) ? <Option key={`bcsb-${idx}`} value={bill.id}>{bill.name}</Option> : null
                              }) }
                            </OptGroup>
                            : null }
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col lg={8} style={{ marginTop: '8.5px' }}>
                  <FormItem label='Carer Skill Level Required'>
                    { getFieldDecorator('payroll', this.isEdit()
                      ? { initialValue: item.payroll,
                          rules: [
                            { required: true, message: 'Please Select Carer Skill Level' }
                          ] }
                      : { initialValue: clientInfo.payroll_category,
                          rules: [
                            { required: true, message: 'Please Select Carer Skill Level' }
                          ]})
                      (<Select
                        onChange={this.handlePayrollChange}
                        showSearch
                        filterOption={this.findOption}>
                        { settingsPayroll.map((pay, idx) => {
                            return <Option key={`cskl-${idx}`} value={pay.value}>{pay.name}</Option>
                          }) }
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col lg={8}>
                  <FormItem label={
                    <span>Employee <Switch
                      checkedChildren='Pending'
                      unCheckedChildren='Pending'
                      style={{ marginLeft: 10 }}
                      checked={isEmployeePending}
                      onChange={this.handleEmployeePending}
                    /></span>
                  } hasFeedback>
                    {getFieldDecorator('employee_id', {
                      initialValue: item.employee_id,
                      rules: [
                        !isEmployeePending ? { required: true, message: 'Please select an employee OR toggle Employee Pending' } : {}
                      ]
                    })(
                      <Select showSearch
                        style={{ width: '100%' }}
                        placeholder='Employee'
                        optionFilterProp='children'
                        notFoundContent='Not found'
                        filterOption={(input, option) => this.filterEmployees(input, option)}
                        onChange={this.handleEmployeeChange} disabled={isEmployeePending}>
                        { employeeList.map((items, idx) => {
                            return <Option key={`empls-${idx}`} value={items.id}>{items.first_name} {items.last_name} { items.leave_id ? <span className='job-option-leave-block'><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /> {`Leave ${formatter.toShortDate(items.leave_start_date)} - ${formatter.toShortDate(items.leave_end_date)}`}</span> : null }</Option>
                          }) }
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col lg={8}>
                  <FormItem label='Tasks' hasFeedback>
                    {getFieldDecorator('tasks', {
                      initialValue: this.replacer(item.tasks),
                      rules: [
                        { min: 2, message: 'Tasks must be between 2 and 128 characters' },
                        { required: true, message: 'Please enter tasks' }
                      ]
                    })(
                      <TextArea row={4} />
                    )}
                  </FormItem>
                </Col>
                <Col lg={8}>
                  <FormItem label='Notes'>
                    {getFieldDecorator('notes', {
                      initialValue: this.replacer(item.notes),
                    })(
                      <TextArea row={4} />
                    )}
                  </FormItem>
                </Col>
                {/* <Col lg={8}>
                  { this.isEdit()
                    ? <FormItem label={<span>Feedback
                        {getFieldDecorator('is_job_feedback', {
                          initialValue: item.is_job_feedback ? item.is_job_feedback : false,
                          valuePropName: 'checked'
                        })(
                        <Switch
                          onChange={this.handleJobFeedbackChange}
                          checkedChildren='Yes'
                          unCheckedChildren='No'
                          disabled={!this.hasAccess(PmsUpdateJobFeedback)}
                          style={{ marginLeft: 25 }}
                        />
                        )}
                      </span>}>
                      {getFieldDecorator(`job_feedback_text`, {
                          initialValue: this.replacer(item.job_feedback_text)
                        })(
                          <TextArea
                            rows={2}
                            disabled={!this.hasAccess(PmsUpdateJobFeedback) || !getFieldValue(`is_job_feedback`)}
                          />
                        )}
                    </FormItem>
                    : null }
                </Col> */}
              </Row>

              <Row gutter={16}>
                <Col lg={8}>
                  <FormItem label='Max KM'>
                    {getFieldDecorator('job_kms', {
                      initialValue: this.isEdit() ? item.job_kms : 0,
                      rules: [
                        { validator: this.checkKMSInput }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Col>
                <Col lg={8}>
                  <FormItem label='Recorded KM'>
                    {getFieldDecorator('kms', {
                      initialValue: this.isEdit() ? item.kms : 0,
                      rules: [
                        { validator: this.checkKMSInput }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Panel>

          { isExtSleepover && this.isEdit() && isSleepoverListEnabled > 0
            ? <Panel title='Sleepover Getups'>
              <Form className='getup-panel'>
                <Row>
                  <Col lg={24}>
                    {/* <Row className='getup-row'>
                      <Col offset={4} xs={{ offset: 1 }} lg={22}>
                        <Row>
                          <Col xs={24} lg={3}><div className='getup-row-label-2'>Custom S/O</div></Col>
                          <Col xs={7} lg={3}>
                            <FormItem>
                              {getFieldDecorator('custom_so_start_time', {
                                initialValue: item.custom_so_start_time ? Moment(item.custom_so_start_time) : rateSetInfo.so_hours_start ? Moment(rateSetInfo.so_hours_start) : null
                              })(
                                <TimePicker disabled={!isCustomSO} use12Hours format='h:mm A' minuteStep={15} style={{ width: 100 }} />
                              )}
                            </FormItem>
                          </Col>
                          <Col xs={2} lg={1}><div className='getup-row-label-2'>to</div></Col>
                          <Col xs={7} lg={3}>
                            <FormItem>
                              {getFieldDecorator('custom_so_end_time', {
                                initialValue: item.custom_so_end_time ? Moment(item.custom_so_end_time) : rateSetInfo.so_hours_end ? Moment(rateSetInfo.so_hours_end) : null
                              })(
                                <TimePicker disabled={!isCustomSO} use12Hours format='h:mm A' minuteStep={15} style={{ width: 100 }} />
                              )}
                            </FormItem>
                          </Col>
                          <Col xs={9} lg={9}>
                            <FormItem>
                              {getFieldDecorator('is_custom_so_time', {
                                initialValue: isCustomSO || false
                              })(
                                <Switch
                                  checkedChildren='Enabled Custom SO Time'
                                  unCheckedChildren='Enable Custom SO Time?'
                                  style={{ marginLeft: 10 }}
                                  checked={isCustomSO}
                                  onChange={this.handleSleepoverCustomSO}
                                />
                              )}
                            </FormItem>
                          </Col>
                        </Row>
                      </Col>
                    </Row> */}
                    { isSleepoverListEnabled === 3
                      ? <Row style={{ paddingTop: '5px', marginTop: '0px', paddingTop: '15px', paddingBottom: '20px' }}>
                        <Col lg={11} />
                        <Col lg={2}>
                          Get Up Hours
                        </Col>
                        <Col lg={1}>
                          <Tooltip title='Add Get Up Hour' mouseEnterDelay={0} mouseLeaveDelay={0}>
                            <div className='getup-add-button' onClick={() => this.handleAddGetup()}>
                              <Icon type='plus'/>
                            </div>
                          </Tooltip>
                        </Col>
                        <Col lg={10} />
                      </Row>
                      : isSleepoverListEnabled === 2
                        ? <div className='section'>Unable to edit Sleepover Getups. Please check on Client's Funding Period again.</div>
                        : null }

                    { validator.isNotEmptyArray(extSleepoverList) && extSleepoverList.map((g, index) => {
                        const {
                          id,
                          start_datetime: std,
                          end_datetime: etd,
                          category_id: catId,
                          is_delete: isDelete,
                          notes,
                        } = g
                        return (
                          <Row key={`soitem${index}`} gutter={6} style={{ marginTop: isSleepoverListEnabled < 2 && index === 0 ? '15px' : '0px', marginBottom: '5px' }} className='getup-2'>
                            <Col lg={3}>
                              <div className='getup-row-label'>Get Up {index + 1}</div>
                            </Col>
                            <Col lg={3}>
                              <FormItem>
                                {getFieldDecorator(`start_datetime${index}`, {
                                  initialValue: std ? moment(std).seconds(0).milliseconds(0) : null,
                                  rules: [
                                    { required: true, message: 'Please enter Start Time' }
                                  ]
                                })(
                                  <TimePicker
                                    disabled={isDelete}
                                    use12Hours
                                    format='h:mm A'
                                    minuteStep={15}
                                    style={{ width: 120 }}
                                    defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                                    disabledMinutes={() => this.handleSleepoverItemDisableMinutes('end_datetime', index)}
                                    onChange={this.handleChangeGetupTime(`start_datetime${index}`)}
                                  />
                                )}
                              </FormItem>
                            </Col>
                            <Col lg={1}>
                              <div className='getup-row-label'>to</div>
                            </Col>
                            <Col lg={3}>
                              <FormItem>
                                {getFieldDecorator(`end_datetime${index}`, {
                                  initialValue: etd ? moment(etd).seconds(0).milliseconds(0) : null,
                                  rules: [
                                    { required: true, message: 'Please enter End Time' }
                                  ]
                                })(
                                  <TimePicker
                                    disabled={isDelete}
                                    use12Hours
                                    format='h:mm A'
                                    minuteStep={15}
                                    style={{ width: 120 }}
                                    defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                                    disabledMinutes={() => this.handleSleepoverItemDisableMinutes('start_datetime', index)}
                                    onChange={this.handleChangeGetupTime(`end_datetime${index}`)}
                                  />
                                )}
                              </FormItem>
                            </Col>
                            <Col lg={5}>
                              <FormItem>
                                {getFieldDecorator(`category_id${index}`, {
                                  initialValue: catId || null,
                                  rules: [
                                    { required: true, message: 'Please select Shift Type' }
                                  ]
                                })(
                                  <Select disabled={isDelete}  placeholder='Shift Type'>
                                    { validator.isNotEmptyArray(billingCategoryListPreferred)
                                      ? <OptGroup label={'Preferred'}>
                                        { billingCategoryListPreferred.map((bill, idx) => {
                                          return (!bill.is_sleepover) ? <Option key={idx} value={bill.id}>{bill.name}</Option> : null
                                        }) }
                                      </OptGroup>
                                      : null }
                                    { validator.isNotEmptyArray(billingCategoryListOther)
                                      ? <OptGroup label={'Other'}>
                                        { billingCategoryListOther.map((bill, idx) => {
                                          return (!bill.is_sleepover) ? <Option key={idx} value={bill.id}>{bill.name}</Option> : null
                                        }) }
                                      </OptGroup>
                                      : null }
                                  </Select>
                                )}
                              </FormItem>
                            </Col>
                            <Col lg={7}>
                              <FormItem>
                                {getFieldDecorator(`notes${index}`, {
                                  initialValue: notes || null,
                                })(
                                  <Input disabled={isDelete} placeholder='Notes' />
                                )}
                              </FormItem>
                            </Col>
                            <Col lg={1}>
                              { isDelete
                                ? <Popconfirm
                                  title='Confirm to undo delete on this entry?'
                                  onConfirm={(e) => this.handleSleepoverItemUndoDelete(index)}
                                  okText='Yes'
                                  cancelText='No'
                                  placement='left'
                                ><div className='getup-row-remove'><Icon type='undo' /></div>
                                </Popconfirm>
                                : <Popconfirm
                                  title='Confirm to remove this entry?'
                                  onConfirm={(e) => this.handleSleepoverItemDelete(index)}
                                  okText='Yes'
                                  cancelText='No'
                                  placement='left'
                                ><div className='getup-row-remove'><Icon type='delete' /></div>
                                </Popconfirm> }
                            </Col>
                          </Row>
                        )
                      }) }
                  </Col>
                </Row>

              </Form>
            </Panel>
            : null }

          { validator.isNotEmptyArray(employeePrevList)
            ? <Panel title='Previous Carers'>
              <Row>
                <Col lg={24}>
                  { employeePrevList.map((employee, index) => (
                      <span key={`emp_prev${index}`} className='employeeList' style={{ color: '#242b49' }}><a href={`/employees/${employee.id}/info`} rel='noopener noreferrer' target='_blank'><Icon type='user' /> {employee.first_name} {employee.last_name}</a></span>
                    )) }
                </Col>
              </Row>
            </Panel>
            : null }

          { !this.isEdit()
            ? <Panel
                title='Files'
                subtitle={(<div className='btn' onClick={() => this.handleAddFileModal(true)}> {'Add File'}</div>)}
              >
                <List cols={fileColumns} rows={fileList} />
            </Panel>
            : null }
        </Loading>
      </div>
    )
  }

  /**
   * Section 1: Job Related Fetching
   */
  initialize = async () => {
    const isEdit = this.isEdit()
    const jobId = this.getJobId()
    let clientId = null
    let funderId = null
    let employeeId = null

    this.fetchSettings()
    this.fetchSettingsCancellation()

    let item = {}
    if (isEdit) {
      item = await this.fetchSingleJob()

      if (item && item.id) {
        clientId = item.client_id
        funderId = item.funder_id
        employeeId = item.employee_id
        this.renderDuration(item)

        this.fetchUnsentComm(item.id)
      }
    } else {
      const { location } = this.props
      const { client, funder } = common.getQueryString(location.search)
      clientId = client
      funderId = funder
    }

    this.setState({ clientId, employeeId, funderId }, () => {
      this.fetchClient(clientId, employeeId, item.job_start_date || null)
      this.fetchFunders(clientId, funderId, true)
      this.fetchEmployees(clientId, employeeId, true)
      this.fetchFileCategories()

      this.fetchPreviousEmployees(clientId)
    })
  }

  fetchClient = async (clientId = null, employeeId = null, jobStartDate = null) => {
    try {
      const body = {
        client_id: clientId,
        start_date: jobStartDate
      }
      const cinf = await clientService.getCustom(body)

      if (cinf && cinf.item && cinf.item.id) {
        const clientInfo = cinf.item
        const privateAlert = this.getAlertMsgHtml(clientInfo, 'private_alert')
        const publicAlert =  this.getAlertMsgHtml(clientInfo, 'public_alert')
        const clientLanguages = clientInfo.preferences.languages || []
        const clientLanguagesName = clientInfo.preferences.languages_name || []
        const clientSkills = clientInfo.preferences.skills || []
        const clientSkillsName = clientInfo.preferences.skills_name || []
        const clashedClients = cinf.clashClient || null
        const clashedEmployees = cinf.clashEmp || null

        this.setState({
          clientInfo,
          clientPrivateAlert: privateAlert,
          clientPublicAlert: publicAlert,
          clientLanguages,
          clientLanguagesName,
          clientSkills,
          clientSkillsName,
          clashedClients,
          clashedEmployees
        }, () => {
          this.checkClient(clientId)
          // employeeId && this.checkEmployee(employeeId) // done in fetchEmployees()
        })
      }
    } catch (e) {
      console.log('fetch client', e)
      this.showFetchClientError()
    }
  }

  fetchEmployees = async (clientId, employeeId, isCheckEmployee = false) => {
    const { form } = this.props

    try {
      const jsd = form.getFieldValue('job_start_date')
      const body = {
        client_id: clientId,
        start_date: jsd || null
      }
      const emps = await clientService.getClientEmployeesCustom(body)

      if (validator.isArray(emps)) {
        this.setState({ employeeList: emps }, () => {
          // if fetchEmployees() is triggered by start date change, skip the checkEmployee()
          if (isCheckEmployee) {
            setTimeout(() => {
              this.checkEmployee(employeeId, false)
            }, TIMEOUT)
          }
        })
      }
    } catch (e) {
      this.showFetchEmployeeError()
    }
  }

  fetchPreviousEmployees = async (clientId) => {
    try {
      const emps = await clientService.getClientPrevEmployeesActive(clientId)

      if (validator.isArray(emps)) {
        this.setState({ employeePrevList: emps })
      }
    } catch (e) {
      this.showFetchPrevEmployeeError()
    }
  }

  fetchFileCategories = async () => {
    try {
      this.setState({ loadingFileCats: true })
      const mainCats = await settingFileCategoryService.listByPage(1, 0, { active: true, classification: 'job' })
      const subCats = await settingFileTypeService.listByPage(1, 0, { active: true, classification: 'job' })

      this.setState({
        fileMainCategoryList: mainCats && validator.isNotEmptyArray(mainCats.list) ? mainCats.list : [],
        fileSubCategoryList: subCats && validator.isNotEmptyArray(subCats.list) ? subCats.list : [],
        loadingFileCats: false
      })
    } catch (e) {
      this.showFetchFileCatsError()
    }
  }

  fetchFunders = async (clientId, funderId, isUpdateFunderList = false) => {
    let funders = []
    let funderInfo = { id: null }
    let funderPeriods = []

    if (isUpdateFunderList) {
      funders = await fvpClientFundingService.listClientFunders(clientId)
    } else {
      funders = this.state.funderList
    }

    // jvp removed the legacy client funder settings
    if (validator.isNotEmptyArray(funders)) {
      if (funderId) {
        const f = funders.find(e => e.funder_id === parseInt(funderId))
        if (f && f.id) {
          const info = await funderService.get(funderId)
          if (info && info.item && info.item.id) {
            funderInfo = info.item
          }
          const periods = await fvpClientFundingService.listClientFundingPeriod(clientId, funderId)

          if (validator.isNotEmptyArray(periods)) {
            funderPeriods = periods
          }
        } else {
          this.showInvalidFunderNotification()

          if (funderId) {
            const info = await funderService.get(funderId)
            if (info && info.item && info.item.id) {
              funderInfo = info.item
              funderInfo.isInvalidFunder = true
            }
          }
          // confirm({
          //   icon: (<Icon type='close-circle' style={{color: 'red'}} />),
          //   title: `Invalid Funder`,
          //   content: (
          //     <div>
          //       The funder is not set for this client. Please press on <b>Go To Client Page</b> to add the funder or <b>Cancel</b> to back to the listing.
          //     </div>
          //   ),
          //   okText: 'Go To Client Page',
          //   cancelText: 'Cancel',
          //   onOk () {
          //     history.replace(`/clients/${clientId}`)
          //   },
          //   onCancel () {
          //     history.replace(`/jobs/past`)
          //   }
          // })
        }
      }
    }

    this.setState({
      funderList: funders,
      funderInfo,
      funderPeriodList: funderPeriods,
      prevFunderInfo: this.state.prevFunderInfo && this.state.prevFunderInfo.id ? this.state.prevFunderInfo : Object.assign({}, funderInfo),
    }, () => {
      const { item } = this.state
      const { form } = this.props
      const jsd = form.getFieldValue('job_start_date')
        ? form.getFieldValue('job_start_date')
        : (item && item.id)
          ? item.job_start_date
          : null

      if (jsd) {
        this.fetchRateSetInfo(jsd)
        this.checkCategoriesAndUpdate(jsd)
        this.checkClientCurrentFunding(jsd)
      }
    })

    if (funderInfo && funderInfo.id && !validator.isNotEmptyArray(funderPeriods)) {
      this.alertUnassignedTime()
    }
  }

  fetchRateSetInfo = async (jsd) => {
    const { funderInfo } = this.state

    if (!funderInfo.isInvalidFunder) {
      try {
        const body = {
          date: jsd,
          rate_id: funderInfo.fvp_rate_id
        }

        const r = await fvpRatesService.getDetails(body)
        if (r && r.id) {
          this.setState({ rateSetInfo: r })
        } else {
          this.setState({ rateSetInfo: {} })
        }
      } catch (e) {
        this.setState({ rateSetInfo: {} })
      }

      this.checkSleepoverMismatch ()
      // this.checkExtendedDuration()
    }
  }

  fetchSingleJob = async () => {
    if (!this.isEdit()) return

    try {
      this.setState({ loading: true })
      const item = await jvpJobService.get(this.getJobId())

      if (item.job_jts_signed_location) {
        try {
          const loc = JSON.parse(item.job_jts_signed_location)
          item.job_jts_signed_location = loc
        } catch (e) {
          item.job_jts_signed_location = ''
        }
      }

      if (item.job_jts_signed_client_location) {
        try {
          const loc = JSON.parse(item.job_jts_signed_client_location)
          item.job_jts_signed_client_location = loc
        } catch (e) {
          item.job_jts_signed_client_location = ''
        }
      }

      // isLastJobSeries removed
      const isCustomSO = !!item.is_custom_so_time
      const isEmergency = !!item.emergency
      const isEmployeePending = !item.employee_id && item.is_employee_pending === true
      const isExtSleepover = !!item.is_sleepover_job
      const isOvernightSleepover = (item.sleepover_type === 'sleepover')
      const isTimesheetSignoff = !!(item.ts_signed_id || item.job_jts_id)
      const extSleepoverList = item.sleepover_list ? cloneDeep(item.sleepover_list) : []
      const isFrontendUnhide = !!item.is_frontend_unhide

      this.setState({
        item,
        itemOri: cloneDeep(item),
        isCustomSO,
        isEmergency,
        isEmployeePending,
        isExtSleepover,
        isTimesheetSignoff,
        loading: false,
        clientId: item.client_id,
        employeeId: item.employee_id || null,
        upcomingEmployeeId: null,
        funderId: item.funder_id,
        cachedBillingCategoryId: item.category_id,
        isOvernightSleepover,
        extSleepoverList,
        extSleepoverOriList: cloneDeep(extSleepoverList),
        isFrontendUnhide
      }, () => {
        this.checkCommQuery()
      })

      return item
    } catch (e) {
      this.showFetchJobError()
      this.setState({ loading: false })
    }

    return null
  }


  fetchSettings = async () => {
    const filter = {}
    filter.type = {
      $or: [
        { condition: '=', value: 'payroll' },
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)
    const settingOthers = await settingOtherService.get(1)


    this.setState({
      settingsAll: settings.list,
      settingsPayroll: settings.list.filter(item => item.type === 'payroll'),
      settingsOthers: settingOthers.item
    })
  }

  fetchSettingsCancellation = async () => {
    const settingsCancel = await settingCancellationService.getAllCancellations()
    const settingsCancelReason = await settingCancellationService.getAllCancellationReasons()

    this.setState({
      settingsCancelList: settingsCancel,
      settingsCancelListReasonAll: settingsCancelReason,
      settingsCancelListCurrentReason: []
    })
  }

  fetchUnsentComm = async (jobId) => {
    if (!jobId) return
    const { prevUnsentCommTotal } = this.state
    const { unsent_total } = await jvpJobService.checkUnsentComm(jobId)

    // if it is redirected fron new job, prevUnsentCommTotal should be null, so set the unsentCommTotal and go to checkEmail
    // else if load from id, just update both values; or if after edit save, just update unsentCommTotal and prevUnsentCommTotal will be updated at checkEmail().
    if (prevUnsentCommTotal !== null) {
      this.setState({ unsentCommTotal: unsent_total }, () => {
        this.checkComm()
      })
    } else {
      this.setState({ unsentCommTotal: unsent_total, prevUnsentCommTotal: unsent_total })
    }
  }

  async refreshPage () {
    const { singleJobListType } = this.state
    this.fetchSingleJob()
    const jobId = this.getJobId()
    await this.fetchUnsentComm(jobId)

    setTimeout(() => {
      this.checkComm()
    }, 600)

    this.fetchSettingsCancellation()
    this.checkConflictJobs()
  }

  /**
   * Section 2: Check Functions
   */

  checkCategoriesAndUpdate = (jobStartDateTime) => {
    const { form } = this.props
    const { isExtSleepover, funderPeriodList, isDisableCategoriesSelect: isl } = this.state
    let billingCategoryList = []
    let isDisableCategoriesSelect = isl

    if (jobStartDateTime) {
      /**
       * Update on 20220624: funderPeriodList will add new periods which has no period ID but having funder_id and valid category list.
       * this list is to indicate the admin select a job date that beyond of current client's plan but rate set did include those categories which are open to select
       * when this plan is selected, all categories will be in Other list, but no locking on shift type select.
       */
      /**
       * two jsdt set available. first one is following the job start date time and find the period,
       * second one is following current date time. it is possible that the created job (by recurring)
       * is beyond of any current funder period.
       * when second case is met, the shift type (billing category) select should be disabled and show alert
       * to indicate the job start date time is not falling into any availble funding period
       * */
      const jsdt = moment.isMoment(jobStartDateTime) ? jobStartDateTime : moment(jobStartDateTime)
      const pd = funderPeriodList.find(e => {
        const std = moment(e.start_date)
        const etd = moment(e.end_date)
        return std.isBefore(jsdt) && etd.isAfter(jsdt)
      })

      const jsdt2 = moment(new Date())
      const pd2 = funderPeriodList.find(e => {
        const std = moment(e.start_date)
        const etd = moment(e.end_date)
        return std.isBefore(jsdt2) && etd.isAfter(jsdt2)
      })

      // check on pd.id (valid client funding) or pd.funder_id (category list out of client's funding periods) to enter the sequence
      if (pd && (pd.id || pd.funder_id) && validator.isNotEmptyArray(pd.category)) {
        billingCategoryList = pd.category
        isDisableCategoriesSelect = false
        if (!pd.id) this.showNoFundingPeriodNotification()
      } else if (pd2 && (pd2.id || pd2.funder_id) && validator.isNotEmptyArray(pd2.category)) {
        billingCategoryList = pd2.category
        isDisableCategoriesSelect = false
        this.showNoFundingPeriodNotification()
      } else {
        isDisableCategoriesSelect = true
        this.showNoFundingPeriodNotification()
      }
    }

    this.setState({
      isDisableCategoriesSelect,
      isExtSleepover: jobStartDateTime ? isExtSleepover : false,
      billingCategoryList
    }, () => {
      this.fetchRateSetInfo(jobStartDateTime)
    })
  }

  checkClient = async (clientId = null, isUpdate = false) => {
    const { form } = this.props

    if (!clientId) return

    const jsd = form.getFieldValue('job_start_date') || null
    const jed = form.getFieldValue('job_end_date') || null

    if (jsd && jed) {
      // 1. Check if client is on leave
      this.checkClientLeave(clientId)

      // 2. Check for any conflict shifts
      this.checkConflictJobs()

      // 3. Check if it is public holiday
      this.checkHolidayDouble(jsd, jed)

      // 4. Check if it is sleepover
      this.checkSleepover(jsd, jed, isUpdate)

      // 5. Check job sleepover extended period
      // setTimeout(() => {
      //   this.checkExtendedDuration()
      // }, 200)
    }
  }

  checkClientCurrentFunding = (jobStartDate) => {
    let period = {}
    const { funderPeriodList } = this.state
    const p = funderPeriodList.find(e => {
      const std = moment(e.start_date)
      const etd = moment(e.end_date)
      const jsd = moment.isMoment(jobStartDate) ? jobStartDate : moment(jobStartDate)

      return jsd.isSameOrAfter(std) && jsd.isSameOrBefore(etd)
    })

    if (p && p.id) {
      period = p
    } else {
      period = { id: null }
    }

    this.setState({ currentFundingPeriodInfo: period })
  }

  checkClientLeave = async (clientId) => {
    const { form } = this.props

    if (!clientId) return

    const jsd = form.getFieldValue('job_start_date') || null
    const jed = form.getFieldValue('job_end_date') || null

    const body = {
      job_start_date: jsd,
      job_end_date: jed,
      client_id: clientId
    }

    const info = await jvpJobService.validateClientLeave(body)

    if (info && info.ts) {
      const isClientLeave = !!info.leave_id
      this.setState({
        clientLeaveInfo: info,
        isClientLeave
      })
    }
  }

  checkEmployee = async (employeeId = null, isEmployeeChange = false) => {
    const { form } = this.props
    const { clientId } = this.state

    if (!employeeId) {
      this.setState({
        employeeInfo: {},
        employeePrivateAlert: '',
        employeePublicAlert: '',
        employeeLanguages: [],
        employeeSkills: []
      })

      return
    }

    const startDate = form.getFieldValue('job_start_date')
    const endDate = form.getFieldValue('job_end_date')

    const jsd = !startDate ? null : moment.isMoment(startDate) ? startDate.clone() : moment(startDate)
    const jed = !endDate ? null : moment.isMoment(endDate) ? endDate.clone() : moment(endDate)

    const { item: empInfo } = await employeeService.get(employeeId)

    const privateAlert = this.getAlertMsgHtml(empInfo, 'private_alert')
    const publicAlert =  this.getAlertMsgHtml(empInfo, 'public_alert')
    const employeeLanguages = empInfo.preferences.languages || []
    const employeeSkills = empInfo.preferences.skills || []

    this.setState({
      employeeId: empInfo.id,
      employeeInfo: empInfo,
      employeePrivateAlert: privateAlert,
      employeePublicAlert: publicAlert,
      employeeLanguages,
      employeeSkills
    }, async () => {
      // alert check
      const isShowEmployeePrivateAlert = privateAlert.length > 0
      const isShowEmployeePublicAlert = publicAlert.length > 0

      // max hour check
      const employeeJobHoursInfo = await this.checkEmployeeWeeklyMaxHour(employeeId)
      const isShowEmpMaxHoursAlert = employeeJobHoursInfo.is_employee_has_max_hours !== undefined && employeeJobHoursInfo.is_employee_has_max_hours

      // skill mismatch check
      const mismatchList = this.checkSkillsMismatch()
      const isShowMismatchAlert = mismatchList.isMismatchLanguage || mismatchList.isMismatchSkill

      // leave check
      const leaveInfo = await this.checkEmployeeLeave(employeeId)
      const isShowLeaveAlert = leaveInfo && leaveInfo.ts && leaveInfo.leave_id

      const isInfoTab = this.state.currentTab === '1'

      if (isInfoTab &&
        // ((isEmployeeChange && (isShowEmployeePrivateAlert || isShowEmployeePublicAlert)) || isShowEmpMaxHoursAlert || isShowLeaveAlert || isShowMismatchAlert)) {
        isEmployeeChange) {
        const maxHourAlert = isShowEmpMaxHoursAlert
          ? `<br />
            <span style="font-weight: bold;">Max Hours: </span><span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.employee_max_hours} hours</span>
            <span style="font-weight: bold;">Current Total Hours: <span style="color: #f5222d; font-weight: bold;">${employeeJobHoursInfo.employee_total_job_hours || 0} hours</span></span>
            <div style="font-weight: bold;">New Job Hours: ${employeeJobHoursInfo.employee_new_total_job_hours} hour${employeeJobHoursInfo.employee_new_total_job_hours === 1 ? '' : 's'}${employeeJobHoursInfo.is_employee_over_hour ? ` EXCEEDING` : ''}</span></div>
          `
          : ''

        const mismatchAlert = isShowMismatchAlert
          ? `<br /><div style="color: #f5222d; font-weight: bold;">
              Some mismatches are available.
              ${mismatchList.isMismatchLanguage ? `<br />Mismatch Languages: ${mismatchList.mismatchedLanguageList.map(e => e.setting_name).join(', ')}` : ''}
              ${mismatchList.isMismatchSkill ? `<br />Mismatch Skills: ${mismatchList.mismatchedSkillList.map(e => e.setting_name).join(', ')}` : ''}
            </div>`
          : ''

        const leaveAlert = isShowLeaveAlert
          ? `<br /><span style="color: #f5222d; font-weight: bold;">
            Leave is available. It starts from ${formatter.toShortDate(leaveInfo.leave_start_date)} to ${formatter.toShortDate(leaveInfo.leave_end_date)}.
            </span>`
          : ''

        warning({
          title: `Employee: ${empInfo.first_name} ${empInfo.last_name}`,
          content: (
            <div>
              {isShowEmployeePublicAlert
                ? <div dangerouslySetInnerHTML={{ __html: publicAlert }} />
                : null}
              {isShowEmployeePrivateAlert
                ? <div dangerouslySetInnerHTML={{ __html: privateAlert }} />
                : null}
              {isShowEmpMaxHoursAlert
                ? <div dangerouslySetInnerHTML={{ __html: maxHourAlert }} />
                : null}
              {isShowMismatchAlert
                ? <div dangerouslySetInnerHTML={{ __html: mismatchAlert }} />
                : null}
              {isShowLeaveAlert
                ? <div dangerouslySetInnerHTML={{ __html: leaveAlert }} />
                : null}
            </div>
          ),
          okText: 'OK',
          onOk() { },
        })
      }

      this.checkConflictJobs()
      this.checkEmployeeFilesExpiry(employeeId, jsd, jed)
    })
  }

  checkEmployeeFilesExpiry = async (employeeId, jsd, jed) => {
    const filter = {}
    const employeeExpiredFiles = []

    const jobStartDate = !jsd ? null : moment.isMoment(jsd) ? jsd.clone() : moment(jsd)
    const jobEndDate = !jed ? null : moment.isMoment(jed) ? jed.clone() : moment(jed)

    if (employeeId && jobStartDate && jobEndDate) {
      filter.has_expiry = { condition: '=', value: 'true' }
      filter.active = { condition: '=', value: 'true' }
      filter.module = { condition: '=', value: 'employee' }
      filter.module_id = { condition: '=', value: employeeId }

      const list = await employeeFileService.listByPage(1, 0, filter)

      if (list && validator.isNotEmptyArray(list.list) && jobStartDate) {
        const files = list.list
        for (let i = 0; i < files.length; i++) {
          const file = files[i]

          if (file.expiry_date) {
            if ((jobStartDate && jobStartDate.isAfter(file.expiry_date)) || (jobEndDate && jobEndDate.isAfter(file.expiry_date))) {
              employeeExpiredFiles.push(file)
            }
          }
        }
      }
    }

    this.setState({ employeeExpiredFiles })
  }

  checkEmployeeLeave = async (employeeId) => {
    const { form } = this.props

    const startDate = form.getFieldValue('job_start_date')
    const endDate = form.getFieldValue('job_end_date')

    if (!employeeId || !startDate || !endDate) return {}

    const body = {
      job_start_date: startDate,
      job_end_date: endDate,
      employee_id: employeeId
    }

    const info = await jvpJobService.validateEmployeeLeave(body)

    if (info && info.ts) {
      const isEmployeeLeave = !!info.leave_id
      this.setState({
        employeeLeaveInfo: info,
        isEmployeeLeave
      })
    } else {
      this.setState({
        employeeLeaveInfo: {},
        isEmployeeLeave: false
      })
    }

    return info
  }

  checkEmployeeWeeklyMaxHour = async (employeeId) => {
    const { form } = this.props

    const jsd = form.getFieldValue('job_start_date')
    const jed = form.getFieldValue('job_end_date')

    if (!employeeId || !jsd || !jed) return

    const body = {
      job_start_date: jsd,
      job_end_date: jed,
      type: 'single',
      type_id: this.isEdit() ? this.getJobId() : null,
      employee_id: employeeId
    }

    try {
      const info = await jvpJobService.validateEmployeeMaxHour(body)

      if (info && info.ts) {
        this.setState({ employeeJobHoursInfo: info })

        return info
      }
    } catch (e) {
      console.log('check emp max hour', e)
    }

    return {}
  }

  checkConflictJobs = async () => {
    setTimeout(async () => {
      const { clientId = null } = this.state
      const { form } = this.props

      const jst = form.getFieldValue('job_start_date')
      const jet = form.getFieldValue('job_end_date')
      const jobId = this.getJobId()
      const employeeId = form.getFieldValue('employee_id') || null

      const body = {
        clientId: clientId,
        employeeId: employeeId,
        startDate: jst,
        endDate: jet,
        jobId: jobId === 'add' ? '' : jobId
      }

      const r = await jvpJobService.checkSingleConflictJobs(body)

      if (r && validator.isNotEmptyArray(r.job_list)) {
        const conflictJobList = r.job_list || []
        const conflictJobListClient = clientId ? conflictJobList.filter(e => parseInt(e.client_id) === parseInt(clientId)) : []
        const conflictJobListEmp = employeeId ? conflictJobList.filter(e => parseInt(e.employee_id) === parseInt(employeeId)) : []

        this.setState({
          conflictJobList,
          conflictJobListClient,
          conflictJobListEmp,
          conflictJobErrorMsg: null
        })
      } else {
        this.setState({
          onflictJobList: [],
          conflictJobListClient: [],
          conflictJobListEmp: [],
          conflictJobErrorMsg: 'Unable to validate conflict jobs.'
        })
      }
    }, TIMEOUT)
  }

  checkComm = (isForcedTrigger = false) => {
    const that = this
    const { prevUnsentCommTotal, unsentCommTotal } = this.state

    if ((prevUnsentCommTotal === null && unsentCommTotal > 0) ||
      (prevUnsentCommTotal !== null && unsentCommTotal > 0 && prevUnsentCommTotal < unsentCommTotal) ||
      isForcedTrigger === true) {
      confirm({
        title: `Send Communication?`,
        content: `A communication has been generated. Click Yes to review and send.`,
        okText: 'Yes',
        cancelText: 'No',
        onOk () {
          setTimeout(() => {
            that.handleTabChange('2')
          }, TIMEOUT)
        },
        onCancel () {

        }
      })

      // update on prevUnsentCommTotal after confirm pop up appears
      // set the prevUnsentCommTotal only when isForcedTrigger is false. If didnt check, the popup will show two times becos of prevUnsentCommTotal available
      if (!isForcedTrigger) {
        this.setState({ prevUnsentCommTotal: unsentCommTotal })
      }
    }
  }

  checkCommQuery = () => {
    const { history, location } = this.props
    if (location && location.search) {
      const queries = common.getQueryStringSearchParams(location.search)

      if (queries && queries['comm'] && queries['comm'] === 'true') {
        this.checkComm(true)
        if (location && location.pathname) {
          history.replace(`${location.pathname}`)
        }
      }
    }
  }

  checkEmergencyJob = () => {
    const {
      isJobStartDateAfterNow,
      isEmergencyHoursExceed,
      emergencyDuration,
      isEmergencyEmployeeHoursExceed,
      emergencyEmployeeDuration
    } = this.getEmergencyDurationHours()

    if (isEmergencyHoursExceed) {
      if (emergencyDuration) {
        this.showJobEmergencyConfirm({
          emgPay: isEmergencyEmployeeHoursExceed,
          emgInv: isEmergencyHoursExceed,
          emgHours: emergencyDuration
        })
      } else {
        this.showJobEmergencyConfirm({
          emgPay: true,
          emgInv: true,
          emgHours: emergencyDuration
        })
      }
    }
  }

  checkJobLessThan2Hrs = async (jsd, jed) => {
    const differentHours = formatter.toDurationDiff(jsd, jed, 'hours')

    if (differentHours < JobDefaultAssignedHours) {
      warning({
        title: 'This job\'s duration is less than 2 hours.'
      })
    }
  }

  checkHolidayDouble = async (date1, date2) => {
    let list = []

    const d1 = moment.isMoment(date1) ? date1.format(dateFormat2) : moment(new Date()).format(dateFormat2)
    const d2 = moment.isMoment(date2) ? date2.format(dateFormat2) : null
    const holiday = d2 ? await settingHolidayService.getApproachHoliday(d1, d2) : await settingHolidayService.getTodayHoliday(d1)

    if (holiday && validator.isNotEmptyArray(holiday.list)) {
      this.setState({ isHoliday: true, holidayInfoList: holiday.list })
    } else {
      this.setState({ isHoliday: false, holidayInfoList: [] })
    }

    return list
  }

  /**
   * this function is used for check extended duration if S/O job is different with rate set / custom S/O period
   * it shall be have more extended entries if the job period is wider than S/O period
   * on hold for this update currently.
   */
  checkExtendedDuration = () => {
    const { form } = this.props
    const { isCustomSO, isExtSleepover, extBreakdownInfo, rateSetInfo, item } = this.state
    const jobStartDate = form.getFieldValue('job_start_date') || moment(item.job_start_date)
    const jobEndDate = form.getFieldValue('job_end_date') || moment(item.job_end_date)
    const soStartDate = form.getFieldValue('custom_so_start_time')
    const soEndDate = form.getFieldValue('custom_so_end_time')

    if (jobStartDate && jobEndDate && rateSetInfo && rateSetInfo.id && isExtSleepover) {
      let finalSoStartDate = null
      let finalSoEndDate = null
      if (isCustomSO) {
        finalSoStartDate = soStartDate
        finalSoEndDate = soEndDate
      } else {
        finalSoStartDate = rateSetInfo.so_hours_start
        finalSoEndDate = rateSetInfo.so_hours_end
      }

      if (finalSoStartDate && finalSoEndDate) {
        extBreakdownInfo.updateValue({soHourStart: finalSoStartDate, soHourEnd: finalSoEndDate})
        extBreakdownInfo.getBreakdown(jobStartDate, jobEndDate)
      }
    }
  }

  checkKMSInput = (rule, value, callback) => {
    const { form } = this.props

    if (value && value.length > 0) {
      if (!validator.isDecimal(value)) {
        callback(new Error('Please enter valid decimal value'))
      }
    }

    callback()
  }

  checkSkillsMismatch = () => {
    const { clientInfo, employeeId, employeeInfo } = this.state

    let info = {
      isMismatchLanguage: false,
      isMismatchSkill: false,
      mismatchedLanguageList: [],
      mismatchedSkillList: []
    }

    if (!employeeId) {
      // empty action
    } else {
      let mismatchedLanguageList = []
      let mismatchedSkillList = []

      const clientLanguages = clientInfo.preferences.languages_list || []
      const clientSkills = clientInfo.preferences.skills_list || []
      const empLanguages = employeeInfo.preferences.languages_list || []
      const empSkills = employeeInfo.preferences.skills_list || []

      if (validator.isNotEmptyArray(clientLanguages)) {
        mismatchedLanguageList = cloneDeep(clientLanguages).filter(e => empLanguages.findIndex(f => f.setting_id === e.setting_id) < 0)
      }

      if (validator.isNotEmptyArray(clientSkills)) {
        mismatchedSkillList = cloneDeep(clientSkills).filter(e => empSkills.findIndex(f => f.setting_id === e.setting_id) < 0)
      }

      info = {
        isMismatchLanguage: !!validator.isNotEmptyArray(mismatchedLanguageList),
        isMismatchSkill: !!validator.isNotEmptyArray(mismatchedSkillList),
        mismatchedLanguageList,
        mismatchedSkillList
      }
    }

    this.setState({
      isMismatchLanguage: info.isMismatchLanguage,
      isMismatchSkill: info.isMismatchSkill,
      mismatchedLanguageList: info.mismatchedLanguageList,
      mismatchedSkillList: info.mismatchedSkillList
    })

    return info
  }

  checkSleepover = (start, end, isUpdate) => {
    // check whether the current job start/end time able to auto trigger sleepover flag or not
    // condition: job duration must be MORE THAN 4 HOURS OF AH.
    // so if job duration is beyond of AH, sleepover toggle shall not be triggered anyhow.
    const { isExtSleepover, rateSetInfo } = this.state

    // only process if rateSetInfo is available, because need to get AH Hours
    if (rateSetInfo && rateSetInfo.id && isUpdate) {
      const std = moment.isMoment(start) ? start : moment(start)
      const etd = moment.isMoment(end) ? end : moment(end)

      const isSameDay = std.isSame(etd, 'day')
      const durations = moment.duration(Math.abs(end.diff(start))).asMinutes()

      // get after hours moment obj
      const saah = rateSetInfo.after_hours_start ? moment(rateSetInfo.after_hours_start) : null
      const eaah = rateSetInfo.after_hours_end ? moment(rateSetInfo.after_hours_end) : null

      // set valid AH hours based on the setting. for AH end time, if the start&end dates are same day, the AH end time shall be on the next day.
      const sah = saah ? std.clone().set('hours', saah.get('hours')).set('minutes', saah.get('minutes')) : null
      const eah = eaah ? (isSameDay ? etd.clone().add(1, 'day').set('hours', eaah.get('hours')).set('minutes', eaah.get('minutes')) : etd.clone().set('hours', eaah.get('hours')).set('minutes', eaah.get('minutes'))) : null

      // get the 12:00AM moment object of the day, if same day, then it should be end of the day (next day 12.00AM), else it would be end date's start of the day.
      const eth = isSameDay ? etd.clone().endOf('day').add(1, 'second') : etd.clone().startOf('day')

      let ovlDuration = 0

      if (sah && eah) {
        // case for same day:
        // if job end time is before start of AH: no possibilities for auto sleepover detection since it is out of AH duration
        // if job end time is after start of AH: check on job start time is whether before or after start of AH.
        // if before, get the duration between start of AH and job end time
        // if after, get the duration between job start time and job end time (i.e. job duration is within the period of AH but before the day ends)
        if (isSameDay) {
          if (!etd.isBefore(sah)) {
            if (std.isBefore(sah)) {
              ovlDuration = moment.duration(Math.abs(etd.diff(sah))).asMinutes()
            } else {
              ovlDuration = moment.duration(Math.abs(etd.diff(std))).asMinutes()
            }
          }
        } else {
          // case for diff day:
          // for day 1, check job start time is before/after start of AH
          // if before, get the duration between start of AH and end of day
          // if after, get the duration bewteen job start date and end of day
          // for day 2, check job end time is before/after end of AH
          // if before, get the duration between start of AH and end of day
          // if after, get the duration bewteen job start date and end of day
          let d1c = 0
          let d2c = 0
          if (std.isBefore(sah)) {
            d1c = moment.duration(Math.abs(sah.diff(eth))).asMinutes()
          } else {
            d1c = moment.duration(Math.abs(std.diff(eth))).asMinutes()
          }

          if (etd.isAfter(eah)) {
            d2c = moment.duration(Math.abs(eth.diff(eah))).asMinutes()
          } else {
            d2c = moment.duration(Math.abs(eth.diff(etd))).asMinutes()
          }

          ovlDuration = d1c + d2c
        }
      }

      if (durations > JobSleepoverDetectionMinutes && ovlDuration > JobSleepoverDetectionMinutes) {
        if (std.isBefore(etd)) {
          this.handleSleepover(isUpdate ? true : isExtSleepover, true)
        }
      } else {
        this.handleSleepover(false, false, isUpdate)
      }
    }
  }

  checkSleepoverMismatch = () => {
    const { form } = this.props
    const { isCustomSO, isExtSleepover, extBreakdownInfo, rateSetInfo } = this.state
    const jobStartDate = form.getFieldValue('job_start_date')
    const jobEndDate = form.getFieldValue('job_end_date')
    const soStartDate = form.getFieldValue('custom_so_start_time')
    const soEndDate = form.getFieldValue('custom_so_end_time')
    let soType = ''

    if (jobStartDate && jobEndDate && rateSetInfo && rateSetInfo.id && isExtSleepover) {
      let finalSoStartDate = null
      let finalSoEndDate = null
      if (isCustomSO) {
        finalSoStartDate = soStartDate
        finalSoEndDate = soEndDate
        soType = 'custom'
      } else {
        finalSoStartDate = rateSetInfo.so_hours_start
        finalSoEndDate = rateSetInfo.so_hours_end
        soType = 'rateset'
      }

      if (finalSoStartDate && finalSoEndDate) {
        const std = moment.isMoment(jobStartDate) ? jobStartDate.format('HH:mm') : moment(jobStartDate).format('HH:mm')
        const etd = moment.isMoment(jobEndDate) ? jobEndDate.format('HH:mm') : moment(jobEndDate).format('HH:mm')
        const sod = moment.isMoment(finalSoStartDate) ? finalSoStartDate.format('HH:mm') : moment(finalSoStartDate).format('HH:mm')
        const eod = moment.isMoment(finalSoEndDate) ? finalSoEndDate.format('HH:mm') : moment(finalSoEndDate).format('HH:mm')

        let text = ''

        if (std !== sod || etd !== eod) {
          if (soType === 'custom') {
            text = 'The job start end time does not match with Custom S/O duration.'
          } else if (soType === 'rateset') {
            text = 'The job start end time does not match with Rate Set S/O duration.'
          }
        }

        this.setState({textSleepoverMismatch: text})
      } else {
        this.setState({textSleepoverMismatch: ''})
      }
    }
  }

  /**
   * Section 3: UI change handling
   */

  alertUnassignedTime = () => {
    const { history } = this.props
    const { clientId } = this.state
    confirm({
      title: 'Funding Period Not Defined!',
      content: <div><p>Please assign a funding period for this funder before proceeding.</p> <p>Click <b>Go to Client Page</b> to be redirected to the client page or <b>Continue</b> to choose another Funder.</p></div>,
      okText: 'Go To Client Page',
      cancelText: 'Continue',
      onOk () {
        history.replace(`/clients/${clientId}/info`)
      },
      onCancel () {
      }
    })
  }

  getAlertMsgHtml = (info = {}, key) => {
    if (validator.isNotEmptyObject(info) && info[key] !== undefined) {
      const alert = info[key] !== null && !validator.isEmptyString(info[key]) ? info[key] : ''
      let alertHtml = alert.replace(/(?:\r\n|\r|\n)/g, '<br />')

      if (alertHtml) {
        if (key === 'public_alert') {
          alertHtml = `<strong><ins>${alertHtml}</ins></strong>`
        } else if (key === 'private_alert') {
          alertHtml = `<i>${alertHtml}</i>`
        }
      }

      return alertHtml
    }

    return ''
  }

  getCancellationChargeHours = () => {
    const { settingsOthers = {} } = this.state
    const { form } = this.props

    const std = form.getFieldValue('job_start_date')
    const etd = form.getFieldValue('job_end_date')

    const jsd = !std ? null : moment.isMoment(std) ? std.clone().seconds(0).milliseconds(0) : moment(std).seconds(0).milliseconds(0)
    const jed = !etd ? null : moment.isMoment(etd) ? etd.clone().seconds(0).milliseconds(0) : moment(etd).seconds(0).milliseconds(0)

    if (jsd && jed) {
      const diffHours = formatter.toDurationDiff(jsd, jed, 'hours')
      const jobHours = Math.round(diffHours * 100) / 100

      return jobHours > settingsOthers.cancellation_charge_hour
        ? settingsOthers.cancellation_charge_hour
        : jobHours
    }

    return null
  }

  getCancellationOriginalHours = () => {
    const { item } = this.state

    if (!this.isEdit()) return null

    const jsd = moment(item.job_start_date)
    const jed = moment(item.job_end_date)
    const diffHours = formatter.toDurationDiff(jsd, jed, 'hours')

    return diffHours
  }

  getCancellationNoticeHours = () => {
    const { settingsOthers = {} } = this.state
    const { form } = this.props

    let result = {
      isJobStartDateAfterNow: false,
      isCancellationNoticeExceed: false,
      cancellationNoticeDurationFromNow: null
    }

    const std = form.getFieldValue('job_start_date')
    const jsd = !std ? null : moment.isMoment(std) ? std.clone().seconds(0).milliseconds(0) : moment(std).seconds(0).milliseconds(0)
    const now = moment(new Date())

    if (jsd) {
      const cancellationDuration = settingsOthers.cancellation_notice
      const isJobStartDateAfterNow = now.isAfter(jsd)
      const durationNow = formatter.toDurationDiff(jsd, now, 'hours')

      return {
        isJobStartDateAfterNow,
        isCancellationNoticeExceed: durationNow > 0 && durationNow <= cancellationDuration,
        cancellationNoticeDurationFromNow: durationNow
      }
    }

    return result
  }

  getEmergencyDurationHours = () => {
    const { form } = this.props
    const { settingsOthers = {} } = this.state
    let result = {
      isJobStartDateAfterNow: false,
      isEmergencyHoursExceed: false,
      emergencyDuration: null,
      isEmergencyEmployeeHoursExceed: false,
      emergencyEmployeeDuration: null
    }

    const std = form.getFieldValue('job_start_date')

    const jsd = !std ? null : moment.isMoment(std) ? std.clone().seconds(0).milliseconds(0) : moment(std).seconds(0).milliseconds(0)
    const now = moment(new Date())

    if (jsd) {
      const emergencyDuration = settingsOthers.emergency_notice
      const emergencyEmployeeDuration = settingsOthers.emergency_notice_emp
      const durationNow = formatter.toDurationDiff(jsd, now, 'hour')
      const isJobStartDateAfterNow = now.isAfter(jsd)

      return {
        isJobStartDateAfterNow,
        isEmergencyHoursExceed: durationNow > 0 && durationNow <= emergencyDuration,
        emergencyDuration: emergencyDuration,
        isEmergencyEmployeeHoursExceed: durationNow > 0 && durationNow <= emergencyEmployeeDuration,
        emergencyEmployeeDuration: durationNow
      }
    }

    return result
  }

  handleEditButton = () => {
    this.setState({ isShowSave: true })
  }

  handleTabChange = (index) => {
    const id = this.getJobId()
    const tab = TabList.find(e => e.tabId === parseInt(index))

    this.setState({ currentTab: index })

    if (tab && tab.tabId) {
      this.props.history.replace(`${jobURL}/single/${id}${tab.path}`)
      if (tab.tabId === 1) {
        this.fetchSingleJob()
      }
    }
  }

  handleStartDateChange = (std) => {
    const { form } = this.props
    const { clientId, employeeId } = this.state

    const selectedEmployeeId = form.getFieldValue('employee_id')
    const etd = form.getFieldValue('job_end_date')

    const jsd = !std ? null : moment.isMoment(std) ? std.clone().seconds(0).milliseconds(0) : moment(std).seconds(0).milliseconds(0)
    const jed = !etd ? jsd.clone().add(JobDefaultAssignedHours, 'hours') : moment.isMoment(etd) ? etd.clone().seconds(0).milliseconds(0) : moment(etd).seconds(0).milliseconds(0) // if no job end date, auto assign a new job end date with JobDefaultAssignedHours hours added

    if (!etd) {
      form.setFieldsValue({ job_end_date: jed })
    }

    // Display the shift time breakdown
    this.renderDuration({job_start_date: jsd, job_end_date: jed})

    setTimeout(() => {
      // this.checkClient(clientId, true)
      this.fetchClient(clientId, employeeId, jsd) // update to fetch client because the leaves of client and employee need to check according to jsd
      this.fetchEmployees(clientId, employeeId, true)
      this.checkEmergencyJob()
      // this.checkEmployeeWeeklyMaxHour(selectedEmployeeId) // done in fetchEmployees()
    }, TIMEOUT)

    this.checkJobLessThan2Hrs(jsd, jed)

    /* fvp added functions */
    this.checkCategoriesAndUpdate(jsd)
    this.checkSleepoverMismatch()
    this.checkClientCurrentFunding(jsd)

    if (typeof selectedEmployeeId === 'number' && jsd !== null) {
      this.checkEmployeeFilesExpiry(selectedEmployeeId, jsd, jed)
    }
  }

  handleEndDateChange = (etd) => {
    const { form } = this.props
    const { clientId, employeeId } = this.state

    const selectedEmployeeId = form.getFieldValue('employee_id')

    const std = form.getFieldValue('job_start_date')

    const jsd = !std ? null : moment.isMoment(std) ? std.clone().seconds(0).milliseconds(0) : moment(std).seconds(0).milliseconds(0)
    const jed = !etd ? null : moment.isMoment(etd) ? etd.clone().seconds(0).milliseconds(0) : moment(etd).seconds(0).milliseconds(0)

    // Display the shift time breakdown
    this.renderDuration({job_start_date: jsd, job_end_date: jed})

    setTimeout(() => {
      this.checkClient(clientId, true)
      this.checkEmergencyJob()
      this.checkEmployeeWeeklyMaxHour(selectedEmployeeId)
    }, TIMEOUT)

    this.checkJobLessThan2Hrs(jsd, jed)
    this.checkSleepoverMismatch()

    if (typeof selectedEmployeeId === 'number' && jsd !== null) {
      this.checkEmployeeFilesExpiry(selectedEmployeeId, jsd, jed)
    }
  }

  handleEmergencyToggle = (value) => {
    const { form } = this.props
    const { settingsOthers } = this.state

    this.setState({ isEmergency: !!value })

    if (value === false) {
      setTimeout(() => {
        form.setFieldsValue({ emergency_pay: value, emergency_invoice: value })
      }, TIMEOUT)
    } else {
      // Check if is emergency pay or emergency invoice
      const {
        isJobStartDateAfterNow,
        isEmergencyHoursExceed,
        emergencyDuration,
        isEmergencyEmployeeHoursExceed,
        emergencyEmployeeDuration
      } = this.getEmergencyDurationHours()

      setTimeout(() => {
        if (emergencyEmployeeDuration) {
          form.setFieldsValue({
            emergency_pay: isEmergencyEmployeeHoursExceed,
            emergency_invoice: isEmergencyHoursExceed
          })
        } else {
          form.setFieldsValue({
            emergency_pay: value,
            emergency_invoice: value
          })
        }
      }, TIMEOUT)
    }
  }

  handleEmergencyPayToggle = (value) => {
    const { form } = this.props

    if (!value) {
      confirm({
        title: 'Not Emergency Pay?',
        content: 'Not Paying Employee Emergency Rate?',
        onOk () {
          form.setFieldsValue({ emergency_pay: value })
        },
        onCancel () {
          form.setFieldsValue({ emergency_pay: true })
        }
      })
    }
  }

  handleEmergencyInvoiceToggle = (value) => {
    const { form } = this.props

    if (!value) {
      confirm({
        title: 'Not Emergency Invoice?',
        content: 'Not Charging Client Emergency Loading?',
        onOk () {
          form.setFieldsValue({ emergency_invoice: value })
        },
        onCancel () {
          form.setFieldsValue({ emergency_invoice: true })
        }
      })
    }
  }

  handleEmergencyYes = ({ emgPay = false, emgInv = false }) => {
    const { form } = this.props
    const { settingsOthers } = this.state
    form.setFieldsValue({ emergency: true })
    this.setState({ isEmergency: true })
    setTimeout(() => {
      form.setFieldsValue({ emergency_pay: emgPay, emergency_invoice: emgInv })
    }, TIMEOUT)
  }

  handleEmergencyNo = () => {
    const { form } = this.props
    form.setFieldsValue({ emergency: false, emergency_pay: false, emergency_invoice: false })
    this.setState({ isEmergency: false })
  }

  resetEmployeeDetails = (callback = () => {}) => {
    this.setState({
      employeeInfo: {},
      employeeExpiredFiles: [],
      employeeJobHoursInfo: {},
      employeePrivateAlert: '',
      employeePublicAlert: '',
      employeeLanguages: [],
      employeeSkills: [],
      isEmployeeOnLeave: false,
      isMismatchLanguage: false,
      isMismatchSkill: false,
      mismatchedLanguageList: [],
      mismatchedSkillList: []
    }, callback && callback())
  }


  handleEmployeeChange = (value) => {
    let isCheckLater = true

    if (this.isEdit()) {
      const { isJobStartDateAfterNow, isEmergencyHoursExceed, emergencyDuration } = this.getEmergencyDurationHours()

      if (isEmergencyHoursExceed) {
        this.setState({ isEmployeeEmergencyModal: true, upcomingEmployeeId: value })
      } else {
        isCheckLater = false
      }
    } else {
      isCheckLater = false
    }

    if (!isCheckLater) {
      this.resetEmployeeDetails(() => {
        setTimeout(() => {
          this.checkEmployee(value, true)
          this.checkConflictJobs()
        }, TIMEOUT)
      })
    }
  }

  handleEmployeeChangeEmergency = async (isEmergency = false) => {
    const { form } = this.props
    const { employeeId, upcomingEmployeeId, settingsOthers } = this.state
    const that = this

    if (this.isEdit()) {
      this.setState({ isEmergency, isEmployeeEmergencyModal: false }, () => {
        if (isEmergency) {
          const emergency = form.getFieldValue('emergency')
          const emergencyInvoice = form.getFieldValue('emergency_invoice')

          const { isJobStartDateAfterNow, isEmergencyEmployeeHoursExceed, emergencyEmployeeDuration } = that.getEmergencyDurationHours()

          form.setFieldsValue({
            emergency: true,
            emergency_pay: (emergencyEmployeeDuration ? isEmergencyEmployeeHoursExceed : true) || isJobStartDateAfterNow,
            emergency_invoice: !emergency ? false : (emergencyInvoice || false),
            employee_id: upcomingEmployeeId
          })
        } else {
          // when "Not Emergency" is selected, forcely set all fields to false as per requested
          form.setFieldsValue({
            employee_id: upcomingEmployeeId,
            emergency: false,
            emergency_pay: false,
            emergency_invoice: false
          })
        }
      })

      this.resetEmployeeDetails(() => {
        setTimeout(() => {
          this.checkEmployee(upcomingEmployeeId, true)
          this.checkConflictJobs()
        }, TIMEOUT)
      })
    }
  }

  handleEmployeeChangeCancel = () => {
    this.setState({ isEmployeeEmergencyModal: false, upcomingEmployeeId: null })
  }

  handleEmployeePending = (value) => {
    const { form } = this.props
    if (value) {
      this.setState({
        isEmployeePending: true,
        isEmployeeOnLeave: false,
        isMismatchLanguage: false,
        isMismatchSkill: false,
        employeeExpiredFiles: [],
        employeeJobHoursInfo: {}
      })
      form.setFieldsValue({ employee_id: null })
    } else {
      this.setState({ isEmployeePending: false })
      form.setFieldsValue({ employee_id: null })
    }
  }

  handleJobFeedbackChange = (value) => {
    const { form } = this.props
    const { setFieldsValue } = form

    if (value === false) {
      setFieldsValue({ job_feedback_text: null })
    }
  }

  handleJobFrontendUnhide = (value) => {
    this.setState({ isFrontendUnhide: value })
  }

  handleSleepover = (value, isShowSleepoverAlert = false, isUpdate = false) => {
    const { form } = this.props
    const { item, billingCategoryList, cachedBillingCategoryId, isExtSleepover } = this.state
    const that = this

    const ct = billingCategoryList.find(e => e.is_sleepover === true)
    const catId = item.category_id || cachedBillingCategoryId
    const isPreviouslySOCat = ct && ct.id && ct.id === catId

    const ts = () => setTimeout(() => {
      this.renderDuration({ job_start_date: form.getFieldValue('job_start_date'), job_end_date: form.getFieldValue('job_end_date') })
      this.checkSleepoverMismatch()
    }, TIMEOUT)


    if (isShowSleepoverAlert && !isExtSleepover) {
      confirm({
        title: 'Is This A Sleepover Job?',
        content: `This job has more than 4 After Hours.`,
        okText: 'Yes',
        cancelText: 'Not Sleepover',
        onOk () {
          if (ct && ct.id) {
            form.setFieldsValue({ category_id: ct.id })

            that.setState({ isExtSleepover: true, isShowSleepoverAlert, textSleepoverMismatch: '' }, ts)
          }
        },
        onCancel () {
          /**
           * to revert the previous billing category if cancel the selection on sleepover, two ways to do so:
           * 1. for saved jobs, assign back the previous billing category id
           * 2. for new jobs, new value "cachedBillingCategoryId" applied. this will be set when shift type is selected
           *
           * the shift type will only be empty when
           * 1. change funder
           * 2. new jobs - no shift type is previously selected
           * 3. previously job shift type is sleepover
           * */
          form.setFieldsValue({ category_id: isPreviouslySOCat ? undefined : item.category_id ? item.category_id : cachedBillingCategoryId ? cachedBillingCategoryId : undefined })
          that.setState({ isExtSleepover: false,  isShowSleepoverAlert, textSleepoverMismatch: '' })
        }
      })
    } else {
      if (value === false) {
        if (isUpdate) {
          form.setFieldsValue({ category_id: isPreviouslySOCat ? undefined : item.category_id ? item.category_id : cachedBillingCategoryId ? cachedBillingCategoryId : undefined })
        }
      } else {
        if (ct && ct.id) {
          form.setFieldsValue({ category_id: ct.id })
        }
      }

      this.setState({ isExtSleepover: value, isShowSleepoverAlert, textSleepoverMismatch: '' })
    }

    ts()

    // setTimeout(() => {
    //   this.checkExtendedDuration()
    // }, 200)
  }

  handleSleepoverCustomSO = (value) => {
    const { item, rateSetInfo } = this.state
    const { form } = this.props
    if (value) {
      if (item.custom_so_start_time) {
        form.setFieldsValue({custom_so_start_time: moment(item.custom_so_start_time)})
      }

      if (item.custom_so_end_time) {
        form.setFieldsValue({custom_so_end_time: moment(item.custom_so_end_time)})
      }
    } else {
      if (rateSetInfo && rateSetInfo.id) {
        form.setFieldsValue({custom_so_start_time: moment(rateSetInfo.so_hours_start), custom_so_end_time: moment(rateSetInfo.so_hours_end) })
      }
    }

    this.setState({ isCustomSO: value })
    this.checkSleepoverMismatch()
  }

  handleBillingSelectCategory = (option) => {

  }

  filterBillingCategory = (input, option) => {
    const { children } = option.props

    if (option.type.isSelectOptGroup) {
      return children.includes(child => child.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
    }
    return children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  filterEmployees = (input, option) => {
    const emp = `${option.props.children[0]} ${option.props.children[2]}`
    return emp.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  handlePayrollChange = (value) => {
    const { form } = this.props

    confirm({
      title: 'Change Carer Skill Level Required',
      content: 'Do you really want to change the required skill level?',
      onOk () {
        form.setFieldsValue({ payroll: value })
      },
      onCancel () {
        if (form.getFieldValue('payroll') === 'complex') {
          form.setFieldsValue({ payroll: 'standard' })
        } else {
          form.setFieldsValue({ payroll: 'complex' })
        }
      }
    })
  }

  // comm related
  handleCommUpdate = (value) => {
    this.setState({ unsentCommTotal: value })
  }

  // funder modal related
  triggerFunderModal = (isShowFunderModal) => {
    this.setState({ isShowFunderModal })
  }

  handleFunderSubmit = async () => {
    const { clientId, funderId: currentFunderId } = this.state
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(['funder_id'], async (errors, values) => {
      if (currentFunderId !== parseInt(values.funder_id)) {
        this.setState({ funderInfo: {}, funderId: null, isShowFunderModal: false })
        form.setFieldsValue({ category_id: undefined })
        this.fetchFunders(clientId, values.funder_id)
      }
    })
  }

  handleFunderCancel = async () => {
    const { form } = this.props
    form.setFieldsValue({ funder_id: null })
    this.setState({ isShowFunderModal: false })
  }

  // job cancel modal related
  triggerJobCancelModal = (isJobCancelModal) => {
    const { form } = this.props
    form.setFieldsValue({
      job_cancel_reason_type: null,
      job_cancel_reason: null,
      job_cancel_other_reason: null,
      job_cancel_note: null
    })

    this.setState({
      isJobCancelModal,
      isJobCancelReasonOther: false,
      settingsCancelListCurrentReason: []
    })
  }

  triggerJobCancelConfirmModal = (isJobCancelConfirmModal) => {
    this.setState({ isJobCancelConfirmModal })
  }

  handleJobCancelTypeChange = async (id) => {
    const { form } = this.props
    const { settingsCancelListReasonAll } = this.state

    // reset change reson input for every cancel type change made
    form.setFieldsValue({ ['employee_change_reason_id']: null, ['job_cancel_reason_id']: null })

    const selectedReasons = settingsCancelListReasonAll.filter(e => e.cancellation_id === id)
    this.setState({
      settingsCancelListCurrentReason: selectedReasons,
      employeeChangeReasonId: null,
      employeeChangeReasonName: null,
      jobCancelReasonId: null,
      jobCancelReasonName: null
    })
  }

  handleJobCancelReasonChange = async (id) => {
    const { settingsCancelListCurrentReason } = this.state
    let isJobCancelReasonOther = false

    const rs = settingsCancelListCurrentReason.find(e => e.id === id)
    if (rs && rs.id) {
      if (rs.name.trim().toLowerCase() === 'other') {
        isJobCancelReasonOther = true
      }
    }

    this.setState({ isJobCancelReasonOther })
  }

  handleJobUncancelAlert = () => {
    const { form } = this.props
    const { onSaveJobUncancellation } = this
    const { loading, loadingSave, loadingJobCancelUpdate, funderInfo } = this.state

    const { validateFieldsAndScroll } = form
    const jobId = this.getJobId()

    if (loading || loadingSave, loadingJobCancelUpdate) return

    if (funderInfo && funderInfo.isInvalidFunder) {
      this.showInvalidFunderNotification()
      return
    }

    validateFieldsAndScroll(async (errors, values) => {
      confirm({
        title: 'Are you sure to uncancel this job?',
        content: 'Click Yes to uncancel, No to leave the job cancelled.',
        okText: 'Yes',
        cancelText: 'No',
        async onOk () {
          onSaveJobUncancellation()
        }
      })
    })
  }

  resetJobCancelValues = () => {
    const { form } = this.props
    this.setState({
      jobCancelReasonId: null,
      jobCancelReasonName: null,
      jobCancelReasonTypeId: null,
      jobCancelReasonTypeName: null,
      jobCancelOtherReason: null,
      jobCancelNote: null,
      isJobCancelReasonOther: false
    }, () => {
      form.setFieldsValue({
        job_cancel_reason_type_id: null,
        job_cancel_reason_id: null,
        job_cancel_other_reason: null,
        job_cancel_note: null
      })
    })
  }

  // employee change modal related
  triggerEmployeeChangeModal = (isShowEmployeeReasonModal) => {
    const { form } = this.props
    form.setFieldsValue({
      employee_change_reason_type_id: null,
      employee_change_reason_id: null,
      employee_change_other_reason: null,
      employee_change_note: null
    })

    this.setState({
      isShowEmployeeReasonModal,
      isEmployeeChangeDone: false,
      isEmployeeChangeReasonOther: false,
      settingsCancelListCurrentReason: []
    })
  }

  handleEmployeeChangeReasonChange = async (id) => {
    const { settingsCancelListCurrentReason } = this.state
    let isEmployeeChangeReasonOther = false

    const rs = settingsCancelListCurrentReason.find(e => e.id === id)
    if (rs && rs.id) {
      if (rs.name.trim().toLowerCase() === 'other') {
        isEmployeeChangeReasonOther = true
      }
    }

    this.setState({ isEmployeeChangeReasonOther })
  }

  handleSubmitEmployeeChangeReason = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const { settingsCancelList, settingsCancelListCurrentReason } = this.state
    const that = this

    validateFieldsAndScroll(['employee_change_reason_id', 'employee_change_other_reason', 'employee_change_note', 'employee_change_reason_type_id'],
      async (errors, values) => {
        if (!errors) {
          const rst = settingsCancelList.find(e => e.id === values.employee_change_reason_type_id)
          const rsc = settingsCancelListCurrentReason.find(e => e.id === values.employee_change_reason_id)
          this.setState({
            isShowEmployeeReasonModal: false,
            employeeChangeReasonId: values.employee_change_reason_id,
            employeeChangeReasonName: rsc && rsc.id ? rsc.name : null,
            employeeChangeReasonTypeId: values.employee_change_reason_type_id,
            employeeChangeReasonTypeName: rst && rst.id ? rst.name : null,
            employeeChangeOtherReason: values.employee_change_other_reason,
            employeeChangeNote: values.employee_change_note,
            isEmployeeChangeDone: true
          })
          // Proceed to checkBeforeSave
          setTimeout(() => {
            this.checkBeforeSave()
          }, TIMEOUT)
        }
      })
  }

  resetEmployeeChangeValues = () => {
    const { form } = this.props
    this.setState({
      employeeChangeReasonId: null,
      employeeChangeReasonName: null,
      employeeChangeReasonTypeId: null,
      employeeChangeReasonTypeName: null,
      employeeChangeOtherReason: null,
      employeeChangeNote: null,
      isEmployeeChangeDone: false,
      isEmployeeChangeReasonOther: false
    }, () => {
      form.setFieldsValue({
        employee_change_reason_type_id: null,
        employee_change_reason_id: null,
        employee_change_other_reason: null,
        employee_change_note: null
      })
    })
  }

  // file modal related
  handleAddFileModal = (isShowAddFileModal, info = {}) => {
    this.setState({ isShowAddFileModal, modalFileInfo: info })
  }

  handleFileDelete = (item) => {
    const { fileList } = this.state

    if (item.seq) {
      const idx = fileList.findIndex(e => e.seq === item.seq)
      if (idx > -1) {
        fileList.splice(idx, 1)

        for (let i = 0; i < fileList.length; i++) {
          fileList[i].seq = String(i)
        }
      }

      this.setState({ fileList })
    }
  }

  updateFileAdded = (values) => {
    const { fileList } = this.state
    if (values.seq) {
      const idx = fileList.findIndex(e => e.seq === values.seq)
      if (idx > -1) {
        fileList.splice(idx, 1, values)
      }
    } else {
      values.seq = String(fileList.length) // need to change to string to make sure the checking item.seq wont return false for first item, because first item of seq is 0
      fileList.push(values)
    }

    this.setState({ fileList }, () => {
      this.handleAddFileModal(false)
    })
  }

  // SO Sleepover list related
  handleAddGetup = () => {
    const { extSleepoverList } = this.state
    extSleepoverList.push({ id: null, type: 'getup' })

    this.setState({ extSleepoverList })
  }

  handleSleepoverItemDelete = (index) => {
    const { extSleepoverList } = this.state

    if (extSleepoverList.length > index) {
      const so = extSleepoverList[index]
      if (so && so.id) {
        so.is_delete = true
      } else {
        extSleepoverList.splice(index, 1)
      }
    }

    this.setState({ extSleepoverList })
  }

  handleSleepoverItemUndoDelete = (index) => {
    const { extSleepoverList } = this.state

    if (extSleepoverList.length > index) {
      const so = extSleepoverList[index]
      if (so && so.id && so.is_delete) {
        so.is_delete = false
      }
    }

    this.setState({ extSleepoverList })
  }

  handleSleepoverItemDisableMinutes = (type, index) => {
    const { form } = this.props
    const std = form.getFieldValue(`${type}${index}`)

    if (std) {
      if (std.format('mm') === '00' || std.format('mm') === '30') {
        return [15, 45]
      } else {
        return [0, 30]
      }
    } else {
      return []
    }
  }

  /** timesheet eelated */
  checkBeforeDeleteTimesheet = () => {
    const that = this

    confirm({
      title: 'Are you sure you want to delete this timesheet?',
      content: <div><p>Press Ok to continue, No to return</p><p><strong>This action CANNOT BE UNDONE!</strong></p></div>,
      okText: 'Yes',
      cancelText: 'No',
      onOk () {
        that.handleDeleteTimesheet()
      },
      onCancel () {

      }
    })
  }

  handleDeleteTimesheet = async () => {
    const { item } = this.state

    try {
      this.setState({ loading: true })
      const r = await jobTimesheetService.remove(item.ts_signed_id, item.id)

      if (r && r.id) {
        if (r.errors) {
          notify.error('Unable to delete timesheet', r.message || 'Unable to remove the timesheet. Please try again later')
        } else {
          await this.fetchSingleJob()
          log.updateJobExtra(item.id, 'update timesheet', `Timesheet with raw ID ${item.ts_signed_id} is removed.`)
          this.handleTabChange('1')
          notify.success('Delete Timesheet Successfully', 'The timesheet is removed successfully.')
        }
      } else {
        notify.error('Unable to delete timesheet', 'Unable to remove the timesheet. Please try again later')
      }
    } catch (e) {
      notify.error('Unable to delete timesheet', 'Unable to remove the timesheet. Please try again later')
    }

    this.setState({ loading: false })
  }

  renderDuration = async (item) => {
    const { isExtSleepover, funderId } = this.state
    const jsd = item.job_start_date ? (moment.isMoment(item.job_start_date) ? item.job_start_date.clone() : moment(item.job_start_date)) : null
    const jed = item.job_end_date ? (moment.isMoment(item.job_end_date) ? item.job_end_date.clone() : moment(item.job_end_date)) : null

    if (jsd && jed) {
      const holidayList = await this.checkHolidayDouble(jsd, jed)
      const isD1Holiday = holidayList.find(e => e.date === jsd.format(dateFormat2))
      const isD2Holiday = holidayList.find(e => e.date === jed.format(dateFormat2))
      const breakdown = new DurationBreakdown(item.funder_id || funderId, isD1Holiday, isD2Holiday, isExtSleepover)
      const durationBreakdown = await breakdown.get(jsd, jed)

      if (durationBreakdown) {
        this.setState({ jobDurationTextBreakdown: durationBreakdown.breakdown, jobDurationTextHours: durationBreakdown.hours})
      } else {
        this.setState({ jobDurationTextBreakdown: 'Invalid Job Time', jobDurationTextHours:'Invalid Job Time'})
      }
    }
  }

  findOption = (input, option) => {
    const pvd = `${option.props.children}`
    return pvd.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  /**
   * Section 4: Save related features
   */

  checkBeforeSave = async () => {
    const { form } = this.props
    const { getFieldValue, validateFieldsAndScroll } = form
    const { onSaveJob, triggerEmployeeChangeModal } = this
    const {
      // loading flag
      loading,
      loadingSave,
      // info
      clientInfo,
      employeeInfo,
      funderInfo,
      currentFundingPeriodInfo,
      item,
      // job list
      conflictJobList,
      conflictJobListClient,
      conflictJobListEmp,
      // files
      employeeExpiredFiles,
      // leave flag
      isClientLeave,
      isEmployeeLeave,
      // skill
      isMismatchSkill,
      mismatchedSkillList,
      // private alert
      clientPrivateAlert,
      clientPublicAlert,
      employeePrivateAlert,
      employeePublicAlert,
      // emp job hours
      employeeJobHoursInfo,
      // clashed info
      clashedClients,
      clashedEmployees,
      // leave info
      clientLeaveInfo,
      employeeLeaveInfo,
      // employee change info
      isShowEmployeeReasonModal,
      isEmployeeChangeDone,
      // sleepover
      isExtSleepover
    } = this.state

    const isShowClientPrivateAlert = clientPrivateAlert && clientPrivateAlert.length > 0
    const isShowClientPublicAlert = clientPublicAlert && clientPublicAlert.length > 0
    const isShowEmployeePrivateAlert = employeePrivateAlert && employeePrivateAlert.length > 0
    const isShowEmployeePublicAlert = employeePublicAlert && employeePublicAlert.length > 0
    const isAnyJobConflicted = validator.isNotEmptyArray(conflictJobList)
    const isClientJobConflicted = validator.isNotEmptyArray(conflictJobListClient)
    const isEmpJobConflicted = validator.isNotEmptyArray(conflictJobListEmp)
    const isFileExpired = validator.isNotEmptyArray(employeeExpiredFiles)
    const isClientNoFundingPeriod = currentFundingPeriodInfo && currentFundingPeriodInfo.id === null
    const isSleepoverTurnOff = item.is_sleepover_job === true && isExtSleepover === false
    const isShowEmpMaxHoursAlert = employeeJobHoursInfo.is_employee_has_max_hours !== undefined && employeeJobHoursInfo.is_employee_has_max_hours

    if (loading || loadingSave || isShowEmployeeReasonModal) return

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        if (this.isEdit() && item.employee_id && item.employee_id !== values.employee_id && !isEmployeeChangeDone) {
          triggerEmployeeChangeModal(true)
          return
        }

        const startDate = getFieldValue('job_start_date')
        const endDate = getFieldValue('job_end_date')
        const jsd = !startDate ? null : moment.isMoment(startDate) ? startDate : moment(startDate)
        const jed = !endDate ? null : moment.isMoment(endDate) ? endDate : moment(endDate)

        if (jsd && jed.isBefore(jsd)) {
          this.showDateNotification()
          return
        }

        if (funderInfo && funderInfo.isInvalidFunder) {
          this.showInvalidFunderNotification()
          return
        }

        if (isShowClientPrivateAlert || isShowEmployeePrivateAlert || isAnyJobConflicted || isFileExpired || isClientNoFundingPeriod ||
          isClientLeave || isEmployeeLeave || isMismatchSkill || isSleepoverTurnOff) {
          confirm({
            title: 'Proceed To Save?',
            content: (
              <div className='alert-block'>
                <p>The job will be { this.isEdit() ? 'updated' : 'created' } with following issue(s):</p>
                <br />

                {/** No funding period */}
                { isClientNoFundingPeriod
                  ? <div style={{color: '#FF0000'}}>
                    <p style={{ fontSize: '14px', margin: '0px 0px 5px'}}>
                      <Icon type='exclamation-circle' theme='filled' style={{ fontSize: '12pt' }} />
                      &nbsp;<strong>Client Has No Funding Period</strong>
                    </p>
                    <p>The job date does not fall on any available funding period.</p>
                  </div>
                  : null }

                {/* Private Alert */}
                { isShowClientPrivateAlert
                  ? <div>
                    <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                      <span dangerouslySetInnerHTML={{
                        __html: ` <strong>Client: ${clientInfo.first_name} ${clientInfo.last_name}</strong><br />${`${clientPrivateAlert}`}`
                      }} />
                    </p>
                  </div>
                  : null }

                { isShowEmployeePrivateAlert
                  ? <div>
                    <p style={{ fontSize: '14px' }}>
                      <Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                      <span dangerouslySetInnerHTML={{
                        __html: ` <strong>Employee: ${employeeInfo.first_name} ${employeeInfo.last_name}</strong><br />${`${employeePrivateAlert}`}`
                      }} />
                    </p>
                  </div>
                  : null }

                {/* clashed address detail */}
                { clashedClients || clashedEmployees
                  ? <div>
                      <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                      &nbsp;<span dangerouslySetInnerHTML={{
                        __html: `<strong>Address</strong>
                        ${clashedClients ? `<div>Same address with client: ${clashedClients}</div>` : ''}
                        ${clashedEmployees ? `<div>Same address with employee: ${clashedEmployees}</div>` : ''}`
                      }} />
                    </p>
                  </div>
                  : null }

                {/* Files Expiry */}
                { isFileExpired
                  ? (<div style={{color: '#FF0000'}}>
                      <p style={{ fontSize: '14px' }}>
                        <Icon type='exclamation-circle' theme='filled' style={{ fontSize: '12pt' }} />
                        &nbsp;<strong>Expired Files:</strong>
                      </p>
                      <li>
                        <ul>{employeeExpiredFiles.map((file, idx) => { return (<li key={`expns${idx}`}>{`${file.main_category} - ${file.sub_category} Expired`}</li>) })}</ul>
                      </li>
                      <br />
                    </div>)
                  : null }

                {/** Employee job max hour warning */}
                { isShowEmpMaxHoursAlert
                  ? <div style={{color: '#FF0000'}}>
                      <p style={{ fontSize: '14px' }}>
                        <Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} />
                        &nbsp;<span style={{ fontWeight: 'bold' }}>Max Hours</span>
                        <div>
                          {`${employeeInfo.first_name} ${employeeInfo.last_name} already has `}<span className='error-msg'>{employeeJobHoursInfo.employee_total_job_hours}</span>{` total job hours this week, including this job will be `}<span className='error-msg'>{formatter.toDecimalS(employeeJobHoursInfo.employee_new_total_job_hours)}</span>{` total job hours`}{employeeJobHoursInfo.is_employee_over_hour ? `, EXCEEDING` : '.'}{employeeJobHoursInfo.is_employee_has_max_hours ? <span className={employeeJobHoursInfo.is_employee_over_hour ? 'error-msg' : 'bold-msg'}>{` Max ${employeeJobHoursInfo.employee_max_hours} hours.`}</span> : ''}
                        </div>
                      </p>
                    </div>
                  : null }

                {/* Client On Leave */}
                { isClientLeave
                  ? <div style={{color: '#FF0000'}}>
                    <p className='content'>
                      <Icon type='exclamation-circle' theme='filled' style={{ fontSize: '13pt' }} />
                      &nbsp;<strong>Client On Leave</strong>
                    </p>
                    <p>{clientInfo.first_name} {clientInfo.last_name} is on leave from {formatter.toShortDate(clientLeaveInfo.leave_start_date)} to {clientLeaveInfo.leave_is_ufn ? `UFN` : formatter.toShortDate(clientLeaveInfo.leave_end_date)}</p>
                    <br />
                  </div>
                  : null }

                { /** TODO: client & employee shift conflict */}
                {/* Client Shift Conflict */}
                { isClientJobConflicted
                  ? <div style={{color: '#FF0000'}}>
                    <p style={{ fontSize: '14px' }}>
                      <Icon type='exclamation-circle' theme='filled' style={{ fontSize: '12pt' }} />
                      &nbsp;<strong>Client Has Another Shift</strong>
                    </p>
                    <p> {clientInfo.first_name} {clientInfo.last_name} has shift on {conflictJobListClient.map((e, idx) => `${formatter.toShortDate(e.job_start_date)} ${formatter.toShortTime(e.job_start_date)} to ${formatter.toShortTime(e.job_end_date)}${idx === conflictJobListClient.length ? '' : ', '}`)}</p>
                    <br />
                  </div>
                  : null }

                {/* Employee On Leave */}
                { isEmployeeLeave
                  ? <div style={{color: '#FF0000'}}>
                    <p className='content'>
                      <Icon type='exclamation-circle' theme='filled' style={{ fontSize: '13pt' }} />
                      &nbsp;<strong>Employee On Leave</strong>
                    </p>
                    <p>{employeeInfo.first_name} {employeeInfo.last_name} is on leave from {formatter.toShortDate(employeeLeaveInfo.leave_start_date)} to {formatter.toShortDate(employeeLeaveInfo.leave_end_date)}</p>
                    <br />
                  </div>
                  : null }

                {/* Employee Shift Conflict */}
                { isEmpJobConflicted
                  ? <div style={{color: '#FF0000'}}>
                    <p style={{ fontSize: '14px' }}>
                      <Icon type='exclamation-circle' theme='filled' style={{ fontSize: '12pt' }} />
                      &nbsp;<strong>Employee Has Another Shift</strong>
                    </p>
                    <p> {employeeInfo.first_name} {employeeInfo.last_name} has shift on {conflictJobListEmp.map((e, idx) => `${formatter.toShortDate(e.job_start_date)} ${formatter.toShortTime(e.job_start_date)} to ${formatter.toShortTime(e.job_end_date)}${idx === conflictJobListEmp.length ? '' : ', '}`)}</p>
                    <br />
                  </div>
                  : null }

                {/* Skill Mismatch */}
                { isMismatchSkill
                  ? <div style={{color: '#FF0000'}}>
                    <p className='content'>
                      <Icon type='exclamation-circle' theme='filled' style={{ fontSize: '13pt' }} />
                      &nbsp;<strong>{employeeInfo.first_name} {employeeInfo.last_name} does not possess following skill(s):</strong>
                    </p>
                    <ul>
                    { mismatchedSkillList.map((skills, index) => (
                        <li key={`mskl-${index}`}>{skills.setting_name}</li>
                      )) }
                    </ul>
                    <br />
                  </div>
                  : null }

                {/* Sleepover revoke */}
                { isSleepoverTurnOff
                  ? <div style={{color: '#FF0000'}}>
                      <p style={{ fontSize: '14px' }}>
                        <Icon type='exclamation-circle' theme='filled' style={{ fontSize: '12pt' }} />
                        &nbsp;<strong>Sleepover Is Removed:</strong>
                      </p>
                      <p>Sleepover is about to be updated to normal shift type.</p>
                      <p><strong>ALL RECORDED GET UP LIST WILL BE REMOVED IF UPDATED TO NORMAL SHIFT TYPE.</strong></p>
                      <br />
                    </div>
                  : null }

                <mark><strong>Please click OK to proceed or Cancel to go back.</strong></mark>
              </div>
            ),
            okText: 'OK',
            cancelText: 'Cancel',
            onOk () {
              // eslint-disable-next-line no-lone-blocks
              onSaveJob(values)
            },
            onCancel () {
            }
          })
        } else {
          onSaveJob(values)
        }
      }
    })
  }

  onSaveJob = async (values) => {
    const { form, history } = this.props
    const {
      extSleepoverList,
      extSleepoverOriList,
      loading,
      loadingSave,
      isShowEmployeeReasonModal,
      employeeChangeReasonId,
      employeeChangeReasonName,
      employeeChangeNote,
      employeeChangeOtherReason,
      employeeChangeReasonTypeId,
      employeeChangeReasonTypeName,
      isEmployeeChangeDone,
      isEmployeePending,
      clientId,
      funderId,
      funderInfo,
      fileList,
      item,
      isCustomSO,
      isExtSleepover,
      rateSetInfo
    } = this.state
    const { setFieldsValue } = form

    if (loading || loadingSave || isShowEmployeeReasonModal) return

    this.setState({ loadingSave: true })

    values.client_id = clientId
    values.funder_id = funderInfo.id || item.funder_id

    values.is_employee_pending = isEmployeePending
    if (isEmployeePending) {
      values.employee_id = null
    }

    if (values.tasks && values.tasks.length > 0) {
      values.tasks = this.replacer(values.tasks).trim()
      setFieldsValue({ tasks: values.tasks })
    }

    if (values.notes && values.notes.length > 0) {
      values.notes = this.replacer(values.notes).trim()
      setFieldsValue({ notes: values.notes })
    }

    // Replace and remove if job_survey_feedback_text has special character, tab or extra blank spaces
    if (values.job_feedback_text && values.job_feedback_text.length > 0) {
      values.job_feedback_text = this.replacer(values.job_feedback_text).trim()
      setFieldsValue({ job_feedback_text: values.job_feedback_text })
    }

    // Check if job is not emergency then clear invoice and pay
    if (!values.emergency) {
      values.emergency_invoice = false
      values.emergency_pay = false
    }

    if (values.job_kms === '') {
      values.job_kms = null
    }

    const newKms = formatter.roundKMS(values.kms)
    values.kms = newKms
    setFieldsValue({ kms: newKms })

    // Remove kms if kms empty string
    if (values.kms === '') {
      values.kms = null
    }

    // fvp sleepover
    values.is_sleepover_job = isExtSleepover
    values.is_custom_so_time = isCustomSO
    values.sleepover_type = isExtSleepover ? 'sleepover' : null

    if (!isCustomSO) {
      values.custom_so_start_time = null
      values.custom_so_end_time = null
    }

    if (item.base_job_id !== null) {
      values.is_single_updated = true
    }

    // employee change reason
    if (employeeChangeReasonId && employeeChangeReasonTypeId) {
      values.employee_change_reasons = {
        is_employee_change: true,
        employee_change_reason_id: employeeChangeReasonId,
        employee_change_reason_name: employeeChangeReasonName,
        employee_change_reason_type_id: employeeChangeReasonTypeId,
        employee_change_reason_type_name: employeeChangeReasonTypeName,
        employee_change_note: employeeChangeNote,
        employee_change_other_reason: employeeChangeOtherReason,
      }
    }

    // fvp sleepover list implementation
    const soList = []
    if (validator.isNotEmptyArray(extSleepoverList)) {
      for (let i = 0; i < extSleepoverList.length; i++) {
        const so = extSleepoverList[i]
        if (so.id) {
          if (so.is_delete) {
            soList.push({
              id: so.id,
              is_delete: so.is_delete
            })
          } else {
            soList.push({
              id: so.id,
              start_datetime: values[`start_datetime${i}`],
              end_datetime: values[`end_datetime${i}`],
              category_id: values[`category_id${i}`],
              notes: values[`notes${i}`] || null,
              type: 'getup'
            })
          }
          delete values[`start_datetime${i}`]
          delete values[`end_datetime${i}`]
          delete values[`category_id${i}`]
          delete values[`notes${i}`]
        } else {
          soList.push({
            id: null,
            start_datetime: values[`start_datetime${i}`],
            end_datetime: values[`end_datetime${i}`],
            category_id: values[`category_id${i}`],
            notes: values[`notes${i}`] || null,
            type: 'getup'
          })

          delete values[`start_datetime${i}`]
          delete values[`end_datetime${i}`]
          delete values[`category_id${i}`]
          delete values[`notes${i}`]
        }
      }
    }

    values.sleepover_list = soList
    values.sleepover_ori_list = extSleepoverOriList

    try {
      let r = null
      if (this.isEdit()) {
        const jobId = this.getJobId()
        r = await jvpJobService.save(jobId, values)
      } else {
        r = await jvpJobService.add(values)
      }

      if (r && r.id) {
        this.showSaveJobSuccess()

        // Log / Trigger / Single Job update log / Action Log moved to backend
        if (!this.isEdit()) {
          const newId = r.id

          if (validator.isNotEmptyArray(fileList)) {
            await this.onSaveUploadFiles(r.id)
          }

          const redirect = () => {
            const commCheckQuery = r && r.is_comm_created && r.is_comm_created === true ? `?comm=true` : ''
            window.location.replace(`${jobURL}/single/${r.id}/info${commCheckQuery}`)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          }

          redirect()
        } else {
          this.resetEmployeeChangeValues()
          this.refreshPage()
        }

        this.updateRefreshLogTrigger()
      } else {
        this.showSaveJobError()
      }
    } catch (e) {
      this.showSaveJobError()
    }

    this.setState({ loadingSave: false })
  }

  onSaveJobCancel = async () => {
    const { form } = this.props
    const {
      funderInfo,
      loading,
      loadingSave,
      loadingJobCancelUpdate,
      isJobCancelModal,
      isJobCancelConfirmModal,
      settingsCancelList,
      settingsCancelListCurrentReason
    } = this.state
    const { validateFieldsAndScroll } = form
    const jobId = this.getJobId()

    if (loading || loadingSave || loadingJobCancelUpdate) return

    if (funderInfo && funderInfo.isInvalidFunder) {
      this.showInvalidFunderNotification()
      return
    }

    validateFieldsAndScroll(['job_start_date', 'job_cancel_reason_type_id', 'job_cancel_reason_id', 'job_cancel_other_reason', 'job_cancel_note'], async (errors, values) => {
      const rst = settingsCancelList.find(e => e.id === values.job_cancel_reason_type_id)
      const rsc = settingsCancelListCurrentReason.find(e => e.id === values.job_cancel_reason_id)

      this.setState({
        jobCancelReasonId: values.job_cancel_reason_id,
        jobCancelReasonName: rsc & rsc.id ? rsc.name : null,
        jobCancelReasonTypeId: values.job_cancel_reason_type_id,
        jobCancelReasonTypeName: rst & rst.id ? rst.name : null,
        jobCancelOtherReason: values.job_cancel_other_reason,
        jobCancelNote: values.job_cancel_note
      })
      const { onSaveJobCancellation } = this
      const { isJobStartDateAfterNow, isCancellationNoticeExceed, cancellationNoticeDurationFromNow } = this.getCancellationNoticeHours()

      if (isCancellationNoticeExceed || isJobStartDateAfterNow) {
        // trigger late cancellation confirm modal
        this.triggerJobCancelConfirmModal(true)
      } else {
        // normal cancellation
        confirm({
          title: 'Are you sure you want to cancel this job? Other edited entries will not be updated.',
          content: 'Press Yes to continue, No to return',
          okText: 'Yes',
          cancelText: 'No',
          async onOk () {
            onSaveJobCancellation(false)
          }
        })
      }
    })
  }

  onSaveJobCancellation = async (isLateCancellation = false, isRedirectedFromModal = true) => {
    const {
      jobCancelReasonId,
      jobCancelReasonName,
      jobCancelReasonTypeId,
      jobCancelReasonTypeName,
      jobCancelOtherReason,
      jobCancelNote,
      item,
      loading,
      loadingSave,
      loadingJobCancelUpdate
    } = this.state

    const id = this.getJobId()
    const isCancelled = !!item.is_cancel
    const job_cancel_reasons = {
      is_job_cancel: true,
      job_cancel_reason_id: jobCancelReasonId || null,
      job_cancel_reason_name: jobCancelReasonName || null,
      job_cancel_reason_type_id: jobCancelReasonTypeId || null,
      job_cancel_reason_type_name: jobCancelReasonTypeName || null,
      job_cancel_other_reason: jobCancelOtherReason || null,
      job_cancel_note: jobCancelNote || null,
    }
    const values = {
      is_redirected_from_modal: isRedirectedFromModal, // this flag is to identify the cancellation save is from modal entry instead of late cancellation update from main form
      job_cancel_reasons,
      is_cancel: true,
      cancellation_penalty: isLateCancellation
    }

    if (loading || loadingSave || loadingJobCancelUpdate) return

    this.setState({ loadingSave: true, loadingJobCancelUpdate: true })

    try {
      const r = await jvpJobService.saveCancel(id, values)

      if (r && r.id) {
        // Log / Trigger / Single Job update log / Action Log moved to backend
        if (isCancelled) {
          this.showCancelJobUpdateSuccess()
        } else {
          this.showCancelJobSuccess()
        }

        this.triggerJobCancelModal(false)
        this.triggerJobCancelConfirmModal(false)
        this.resetJobCancelValues()

        // only refresh page when the job is updated from not cancel -> cancel
        if (!isCancelled) {
          this.refreshPage()
        }

        this.updateRefreshLogTrigger()
      } else {
        if (isCancelled) {
          this.showCancelJobUpdateError()
        } else {
          this.showCancelJobError()
        }
      }
    } catch (e) {
      if (isCancelled) {
        this.showCancelJobUpdateError()
      } else {
        this.showCancelJobError()
      }
    }

    this.setState({ loadingSave: false, loadingJobCancelUpdate: false })
  }

  onSaveJobUncancellation = async () => {
    const { loading, loadingSave, loadingJobCancelUpdate } = this.state

    if (loading || loadingSave, loadingJobCancelUpdate) return

    const id = this.getJobId()

    const values = {
      is_cancel: false,
      cancellation_penalty: false
    }

    this.setState({ loadingSave: true, loadingJobCancelUpdate: true })

    try {
      const r = await jvpJobService.saveCancel(id, values)

      if (r && r.id) {
        // Log / Trigger / Single Job update log / Action Log moved to backend
        this.showUncancelJobSuccess()

        this.refreshPage()

        this.updateRefreshLogTrigger()
      } else {
        this.showUncancelJobError()
      }
    } catch (e) {
      this.showUncancelJobError()
    }

    this.setState({ loadingSave: false, loadingJobCancelUpdate: false })
  }

  onSaveUploadFiles = async (jobId) => {
    const { fileList, fileMainCategoryList, fileSubCategoryList } = this.state
    for (let i = 0; i < fileList.length; i++) {
      let file = fileList[i]
      delete file.seq
      file.module_id = jobId

      const cat = fileMainCategoryList.find(e => e.id === file.main_category_id)
      const subcat = fileSubCategoryList.find(e => e.id === file.sub_category_id)
      // console.log(`upload file ${i}`, file)

      const response = await jvpJobFileService.add(file)

      if (response && response.id) {
        let logItemAfter = {
          label: file.label || '',
          file_name: formatter.toStandardFileName(file.file_name),
          active: file.active,
          issuance_date: file.issuance_date
        }

        if (subcat && (subcat.has_expiry && file.expiry_date)) {
          logItemAfter.expiry_date = file.expiry_date || ''
        }

        const extraLog = []
        extraLog.push(`${cat.name} - ${subcat.name}${file.label ? ` - ${file.label}` : ''}${file.file_name ? `(${formatter.toStandardFileName(file.file_name)})` : ''}`)

        log.addJobFile(jobId, logItemAfter, [], extraLog.join())
      }
    }
  }

  /**
   * Section 5: Miscellaneous
   */
  updateRefreshLogTrigger = () => {
    this.setState({ isLogRefresh: true })
  }

  updateRefreshLogComplete = () => {
    this.setState({ isLogRefresh: false })
  }

  showFetchClientError = () => {
    notify.error('Unable to load successfully, Unable to load clients successfully. Please try again later.')
  }

  showFetchEmployeeError = () => {
    notify.error('Unable to load successfully, Unable to load employees successfully. Please try again later.')
  }

  showFetchPrevEmployeeError = () => {
    notify.error('Unable to load successfully, Unable to load previous employee list successfully. Please try again later')
  }

  showFetchFileCatsError = () => {
    notify.error('Unable to load successfully', 'Unable to load file categories successfully. Please try again later.')
  }

  showFetchJobError = () => {
    notify.error('Unable to load successfully', 'Unable to load job successfully. Please try again later.')
  }

  showSaveJobSuccess = () => {
    notify.success('Saved successfully', 'Job saved successfully.')
  }

  showSaveJobError = () => {
    notify.error('Unable to Save', 'Unable to update the job. Please try again later.')
  }

  showCancelJobSuccess = () => {
    notify.success('Cancelled successfully', 'Job is cancelled successfully.')
  }

  showCancelJobError = () => {
    notify.error('Unable to Cancel', 'Unable to cancel the job. Please try again later.')
  }

  showCancelJobUpdateSuccess = () => {
    notify.success('Updated successfully', 'Job cancel status is updated successfully.')
  }

  showCancelJobUpdateError = () => {
    notify.error('Unable to Update', 'Unable to update the job. Please try again later.')
  }

  showUncancelJobSuccess = () => {
    notify.success('Uncancelled successfully', 'Job is uncancelled successfully.')
  }

  showUncancelJobError = () => {
    notify.error('Unable to Uncancel', 'Unable to uncancel the job. Please try again later.')
  }

  showSaveJobError = () => {
    notify.error('Unable to save successfully', 'Unable to save job successfully. Please try again later.')
  }

  showInvalidFunderNotification = () => {
    error({
      title: `Invalid Funder`,
      content: <div><p>The funder is not set for this client.</p> <p>Please go to Client Page to update funder or update this job's funder in order to proceed to edit and save.</p></div>
    })
  }

  showNoFundingPeriodNotification = () => {
    warning({
      title: `Client has no Funding Period`,
      content: `The client does not have any available funding period.`
    })
  }

  showDateNotification = () => {
    Notification['error']({
      message: 'Recurring Date Error',
      description: 'End Date should not be earlier than Start Date',
      top: 130
    })
  }

  showErrorNotification = (type) => {
    Notification[type]({
      message: 'Incomplete Job Information',
      description: 'Please fill up all required the fields to proceed.',
      top: 130
    })
  }

  showJobEmergencyConfirm = ({ emgPay, emgInv, emgHours }) => {
    const { handleEmergencyNo, handleEmergencyYes } = this
    confirm({
      title: 'Emergency Job?',
      content: `Shift Start In Less Than ${emgHours} Hour${parseFloat(emgHours) === 1 ? '' : 's'}.`,
      okText: 'Yes',
      cancelText: 'Not EMG',
      onOk () {
        handleEmergencyYes({ emgPay, emgInv })
      },
      onCancel () {
        handleEmergencyNo()
      }
    })
  }

  // Forces selected time to the correct time zone
  handleChangeGetupTime = (fieldName) => (value, strTime) => {
    const { form } = this.props
    const timer = setTimeout(() => {
      clearTimeout(timer)
      form.setFieldsValue({ [fieldName]: moment(strTime, 'hh:mm:ss A') })
    }, 200)
  }

  isEdit = () => {
    return this.getJobId() !== 'add'
  }

  getJobId = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  replacer = (value) => {
    if (value) {
      let str = value
      // Replace special characters
      str = str.replace(/[•·]/g, '-')

      // Remove extra blank space or tab
      str = str.replace(/([ ]{2,})|(\t)/g, ' ')

      return str
    }

    return null
  }
}

const mapDispatchToProps = {
  fetchJobList,
  fetchJobSummary
}

const mapStateToProps = (state) => {
  return { ...state.JvpJob, ...state.JvpJobSummary }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(JvpSingleJobPage))
