import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { jobService, jobActionLogService, jvpJobService, fvpPayrollService, employeeService, clientService, settingReasonService } from '../../../services'
import { PayrollFvpMenu } from '../../../constants'
import { formatter, log, trigger, validator } from '../../../util'
import moment from 'moment-timezone'
import debounce from 'lodash.debounce'

// UI
import { ControlLabel, List, Page, Pager, SearchInput, SideModal } from '../../../components'
import notify from '../../../components/Notification'
import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Row from 'antd/lib/row'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

import TimesheetTemplate from '../../JobJvp/Timesheet'
import './styles.css'

const { Item: FormItem } = Form
const { TextArea } = Input
const { Option } = Select

const pageSize = 30
let currentKMs = 0

const ViewEmployee = 'employee'
const ViewClient = 'client'
const ViewReview = 'review'
const ViewSigned = 'signed'

export class IndividualTimesheet extends Component {
  constructor (props) {
    super(props)
    this.state = {
      employeeIds: [],
      currentPage: 1,
      filter: { payroll_id: 1, employee_id: 1 },
      list: [],
      loadingList: false,
      loadingNext: false,
      loadingSummary: false,
      loadingSearch: false,
      isLoaded: false,
      searchText: '',
      showKM: false,
      sort: {},
      suburbs: [],
      total: 0,
      leaveId: null,
      leaveStart: '',
      leaveEnd: '',
      leaveIsUFN: false,
      client: null,
      employee: null,
      // signoff
      isShowSignoff: false,
      signoffInfo: {},
      // modal
      selectedItem: {},
      isShowModal: false,
      loadingForm: false,
      rejectReasons: []
    }

    this.delayedUpdateKM = debounce(this.delayedUpdateKM, 1000)
    this.onSearch = debounce(this.onSearch, 1000)
  }

  componentDidMount () {
    const { currentPage, loadingList, searchText, sort } = this.state
    const { match } = this.props
    const { params } = match
    const { pid, view, id } = params

    const filter = { payroll_id: pid }

    if (view === ViewEmployee) {
      filter.employee_id = id
    } else if (view === ViewClient) {
      filter.client_id = id
    }

    this.fetchAllSummary(false)
    this.fetchLeaveInfo(id)
    this.fetchTimesheet({ currentPage, filter, loading: true, searchText, sort })
    this.fetchRejectReasons()
    this.setState({ filter })
  }

  onSearch (value) {
    const { filter, loadingList, sort } = this.state
    this.setState({ loadingSearch: true, isLoaded: false }, () => {
      this.fetchTimesheet({ currentPage: 1, filter, loading: true, searchText: value, sort })
      this.setState({ searchText: value, currentPage: 1 })
    })
  }

  onFilter (event) {
    const { filter, loadingList, sort, searchText } = this.state
    const value = event.target.value

    delete filter.status
    delete filter.signed_timesheet_id

    if (value === 'approved') {
      filter.status = { condition: '=', value: 'approved' }
    } else if (value === 'review') {
      filter.status = { $or: [{ condition: '=', value: 'pending' }, { condition: '=', value: 'review' }] }
    } else if (value === 'rejected') {
      filter.status = { condition: '=', value: 'rejected' }
    } else if (value === 'signed') {
      filter.status = { condition: '!=', value: 'approved' }
      filter.signed_timesheet_id = { condition: '>', value: '0' }
    } else {
      delete filter.status
    }


    this.setState({ currentPage: 1, filter, isLoaded: false }, () => {
      this.fetchTimesheet({ currentPage: 1, filter, loading: true, searchText, sort })
    })
  }

  onShowSignoff (item) {
    if (item.signed_timesheet_id) {
      let signoffCoord = { latitude: NaN, longitude: NaN }
      try {
        signoffCoord = JSON.parse(item.signed_location)
      } catch (e) {
        console.log('sign off coords e', e)
      }

      let clientCoord = { latitude: NaN, longitude: NaN }
      try {
        clientCoord = JSON.parse(item.signed_client_location)
      } catch (e) {
        console.log('client coords e', e)
      }

      const clientCoords = {latitude: parseFloat(clientCoord.latitude), longitude: parseFloat(clientCoord.longitude)}
      const signoffCoords = {latitude: parseFloat(signoffCoord.latitude), longitude: parseFloat(signoffCoord.longitude)}

      this.setState({
        isShowSignoff: true,
        signoffInfo: Object.assign({}, item, { signed_client_location: clientCoords, signed_location: signoffCoords })
      })
    }
  }

  onCloseSignoff (item) {
    this.setState({
      isShowSignoff: false,
      signoffInfo: {}
    })
  }

  updateKMs (e) {
    currentKMs = e.target.value
    return false
  }

  async saveKMs (id) {
    const { list } = this.state
    const result = await jobService.save(id, { kms: currentKMs })

    if (result.id) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === id) {
          list[i].kms = currentKMs
          break
        }
      }
    }

    let totalKM = 0

    for (let i = 0; i < list.length; i++) {
      totalKM += parseFloat(list[i].kms)
    }

    this.setState({ list, kms: totalKM })
  }

  async checkBeforeSave (id, prevValues = {}) {
    const { form } = this.props
    const { validateFields } = form
    const { rejectReasons } = this.state

    validateFields(['reject_reason_id', 'reject_reason_note'],
      async (errors, values) => {
        if (!errors) {
          const rs = rejectReasons.find(e => e.id === values.reject_reason_id)
          values.reject_reason_name = rs && rs.id ? rs.name : null
          values.is_rejected = true
          const body = Object.assign({}, values, prevValues)
          this.saveStatus(id, body)
        }
      })
  }

  async saveStatus (id, values = {}) {
    const { list } = this.state
    this.setState({ loadingForm: true })

    values.id = id
    values.view = this.getTimesheetView()
    const result = await jvpJobService.savePayroll(values)

    if (result && result.id) {
      const idx = list.findIndex(e => e.id === id)
      console.log('idx', idx, id)
      if (idx > -1) {
        list[idx].status = values.status
      }

      if (values.status !== 'approved' && result.reject_reason_log) {
        trigger.updateJobTimesheetUpdate(result.id, '', result.reject_reason_log)
      }

      notify.success('Update job status successfully', 'The job status is updated successfully.')
      this.handleRejectModal(false)
      this.fetchAllSummary(false)
    } else {
      notify.error('Unable to update job status', 'Please try again later.')
    }

    this.setState({ list, loadingForm: false })
  }

  updateKM = async (id, event) => {
    event.persist()

    this.delayedUpdateKM(id, event)
  }

  delayedUpdateKM = async (id, event) => {
    const ckms = event.target.value
    const { form } = this.props

    if (!!ckms && validator.isDecimal(ckms)) {
      const currentKMs = formatter.roundKMS(ckms)
      const { list } = this.state
      const result = await jvpJobService.savePayroll({ id, kms: currentKMs, view: this.getTimesheetView() })

      if (result && result.id) {
        const idx = list.findIndex(e => e.id === id)

        if (idx > -1) {
          list[idx].kms = currentKMs
        }
      }

      form.setFieldsValue({[`kms${id}`]: currentKMs})

      this.setState({ list })
      this.fetchAllSummary(true)
    }
  }

  render () {
    const { currentPage, list, jobs = 0, payroll_id, date_from, date_to, employee, employees = 0, client, clients = 0, total = 0, kms = 0, hours = 0, sleepovers = 0, getups = 0, review = 0, rejected = 0, signed = 0, approved = 0,
      leaveId = null, leaveStart = '', leaveEnd = '', leaveIsUFN = false, isShowSignoff, signoffInfo, rejectReasons, isShowModal, selectedItem, loadingList, loadingNext, loadingSearch, loadingSummary, loadingForm } = this.state
    const { match: { params: { pid, view } }, form } = this.props
    const { getFieldDecorator } = form

    const columns = [
      {
        title: '',
        width: 2,
        render: ({ emergency_invoice, emergency_pay, cancellation_penalty, is_sleepover_job, is_job_feedback }) => {
          return <div className='indicator-list'>
            { emergency_invoice && emergency_pay
              ? <div className='job-indicator' style={{ backgroundColor: '#ff526ebb' }}>EE</div>
              : emergency_invoice
                ? <div className='job-indicator' style={{ backgroundColor: '#ff5b5bbb' }}>EI</div>
                : emergency_pay
                  ? <div className='job-indicator' style={{ backgroundColor: '#ea3471bb' }}>EP</div>
                  : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} />
            }
            { cancellation_penalty > 0 ? <div className='job-indicator' style={{ backgroundColor: '#b17bcd' }}>LC</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} /> }
            { is_sleepover_job ? <div className='job-indicator' style={{ backgroundColor: '#33ccff' }}>SO</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }}>SO</div> }
            { is_job_feedback ? <Tooltip title='Job Feedback'><div className='job-indicator' style={{ backgroundColor: '#f5a142' }}>F</div></Tooltip> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >F</div> }
          </div>
        }
      },
      {
        title: 'Day',
        width: 1,
        render: ({ job_start_date: startDate }) => startDate ? formatter.capitalize(moment(startDate).format('ddd'), false) : '-'
      },
      {
        title: 'Date',
        width: 2,
        render: ({ job_start_date: startDate, holiday_date, holiday_name }) => holiday_date ? <div>{formatter.toShortDate(startDate)} <Tooltip title={holiday_name} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='bell' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> </div> : formatter.toShortDate(startDate)
      },
      {
        title: 'Hours',
        width: 1,
        key: 'job_hours',
        render: ({ job_hours, is_sleepover_job, so_sum_hour_day }) => is_sleepover_job
          ? formatter.toDecimalS((so_sum_hour_day || 0))
          : formatter.toDecimalS(job_hours)
      },
      view !== ViewClient
      ? {
        title: 'Client',
        width: 3,
        key: 'client',
        render: ({ client, client_leave_id, client_leave_start, client_leave_end, client_active, client_leave_is_ufn }) => client_leave_id
          ? <div className={`${!client_active ? 'timesheet-individual-label-dim' : ''}`}>{client} <Tooltip title={`Leave ${formatter.toShortDate(client_leave_start)} - ${client_leave_is_ufn ? 'UFN' : formatter.toShortDate(client_leave_end)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip></div>
          : <div className={`${!client_active ? 'timesheet-individual-label-dim' : ''}`}>{client}</div>
      }
      : {},
      view !== ViewEmployee
      ? {
        title: 'Employee',
        width: 3,
        key: 'employee',
        render: ({ employee, employee_leave_id, employee_leave_start, employee_leave_end, emp_active }) => employee_leave_id
          ? <div className={`${!emp_active ? 'timesheet-individual-label-dim' : ''}`}>{employee} <Tooltip title={`Leave ${formatter.toShortDate(employee_leave_start)} - ${formatter.toShortDate(employee_leave_end)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip></div>
          : <div className={`${!emp_active ? 'timesheet-individual-label-dim' : ''}`}>{employee}</div>
      }
      : {},
      {
        title: 'Start',
        width: 2,
        render: ({ job_start_date: startDate }) => formatter.toShortTime(startDate)
      },
      {
        title: 'End',
        width: 2,
        render: ({ job_end_date: endDate }) => formatter.toShortTime(endDate)
      },
      {
        title: 'Funder',
        width: 3,
        key: 'funder',
        render: ({ cf_id, funder, contact_client_for_jobs }) => !cf_id
          ? <div style={{textTransform: 'none'}}>{funder}&nbsp;<Tooltip title={`Outside of client's funding period`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip>{contact_client_for_jobs ? <Tooltip title='Contact client for jobs' mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='phone' style={{ color: '#1890ffbb'}} /></Tooltip> : null}</div>
          : <div>{funder} {contact_client_for_jobs ? <Tooltip title='Contact client for jobs' mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='phone' style={{ color: '#1890ffbb'}} /></Tooltip> : null}</div>
      },
      // {
      //   title: 'KMs',
      //   width: 2,
      //   render: ({ id, kms, cancellation_penalty }) => cancellation_penalty > 0 ? null : <Input value={kms} onChange={(e) => this.updateKM(id, e)} style={{ width: '50px', height: '24px', fontSize: '9pt' }} />
      // },
      {
        title: 'KMs',
        width: 2,
        render: ({ id, kms, cancellation_penalty }) => cancellation_penalty > 0
          ? null
          : <FormItem style={{padding: 0, margin: 0, borderCollapse: 'collapse'}}>
            {getFieldDecorator(`kms${id}`, {
              initialValue: kms,
            })(
              <Input onChange={(e) => this.updateKM(id, e)} style={{ width: '50px', height: '24px', fontSize: '9pt' }} />
            )}
          </FormItem>
      },
      {
        title: 'Status',
        width: 3,
        render: (item) => {
          const jobEnd = formatter.toMoment(item.job_end_date)
          const now = formatter.toMoment()
          const isAllowReview = jobEnd.isBefore(now)

          const isOutsiteKMS = !(item.job_kms === null || item.job_kms === '' || item.job_kms === undefined) && ((!parseFloat(item.job_kms) && parseFloat(item.kms) > 0) || (parseFloat(item.job_kms) > 0 && parseFloat(item.kms) > 0 && parseFloat(item.job_kms) < parseFloat(item.kms)))

          return (<div style={{ color: '#ccc', fontSize: '12pt' }}>
            { isAllowReview || !isShowModal
              ? <Tooltip mouseLeaveDelay={0} title='Approve?'>
                { item.status === 'approved'
                  ? <span style={{ color: '#2ec77a' }}><Icon type='check-circle' theme='filled' /></span>
                  : <Icon style={{ color: '#bbb' }} type='check-circle' onClick={() => this.saveStatus(item.id, { status: 'approved' })} /> }
                </Tooltip>
              : <Icon style={{ color: '#eee' }}  type='check-circle' /> } &nbsp;
            { isAllowReview || !isShowModal
              ? <Tooltip mouseLeaveDelay={0} title='To review?'>
                { item.status === 'review' || item.status === 'pending'
                  ? <span style={{ color: '#fcca03' }}><Icon type='exclamation-circle' theme='filled' /></span>
                  : <Icon style={{ color: '#bbb' }} type='exclamation-circle' onClick={() => this.saveStatus(item.id, { status: 'review' })} /> }
                </Tooltip>
                : <Icon style={{ color: '#eee' }}  type='exclamation-circle' /> } &nbsp;
            { isAllowReview || !isShowModal
              ? <Tooltip mouseLeaveDelay={0} title='Reject?'>
                { item.status === 'rejected'
                  ? <span style={{ color: '#f50' }}><Icon type='close-circle' theme='filled' /></span>
                  : <Icon style={{ color: '#bbb' }} type='close-circle' onClick={() => this.handleRejectModal(true, item)} /> }
                </Tooltip>
                : <Icon style={{ color: '#eee' }}  type='close-circle' /> } &nbsp;
            <Tooltip mouseLeaveDelay={0} title={item.signed_timesheet_id ? 'Signed' : isAllowReview ? 'Not Signed' : 'To Sign'}>
              { item.signed_timesheet_id
                ? <span style={{ color: '#4f9fe3' }}>
                  <Icon type='edit' onClick={() => this.onShowSignoff(item)} style={{fontSize: '12pt', color: item.is_signed_late ? 'red' : (isOutsiteKMS || item.is_outside_loc || item.is_outside_acc) ? 'red' : 'green'}} theme='filled' />
                </span>
                : <Icon style={{ color: '#bbb' }} type='edit' style={{fontSize: '12pt'}} /> }
            </Tooltip>
          </div>)
        }
      },
      {
        title: '',
        width: 1,
        render: ({ job_id: jobId }) => <div style={{ textAlign: 'right', width: '40px' }}>
          <Link to={`/jobs/single/${jobId}`} className='button'><Icon type='eye' /></Link>
        </div>
      }
    ]

    const header = view === ViewClient && client
      ? `${client}'s Timesheet`
      : view === ViewEmployee && employee
        ? `${employee}'s Timesheet`
        : view === ViewReview
          ? `Timesheet (To Review)`
          : view === ViewSigned
            ? `Timesheet (Signed)`
            : `Timesheet`

    const searchText = view === ViewClient && client
      ? `Employee, Funder, Date`
      : view === ViewEmployee && employee
        ? `Client, Funder, Date`
        : view === ViewReview || view === ViewSigned
          ? `Client, Employee, Funder, Date`
          : ''

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Timesheet' menu={PayrollFvpMenu} backLink={`/payrolls/${pid}`} />
        </Page.Left>
        <Page.Content full>
          <Page.Header icon={<Icon type='user' />} title={`${header}`} leaveFlag={ leaveId ? <div style={{ color: '#ff0000', fontSize: '8.5pt' }}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' />{` Leave ${formatter.toShortDate(leaveStart)} - ${leaveIsUFN ? 'UFN' : formatter.toShortDate(leaveEnd)}`}</div> : null } total={payroll_id ? `${formatter.toShortDate(date_from)} - ${formatter.toShortDate(date_to)}` : null}>
            <Link to={`/payrolls/${pid}`}>
              <div className='btn'>Back</div>
            </Link>
          </Page.Header>

          <div className='page-summary'>
            { view === ViewClient || view === ViewEmployee
              ? <Row>
                { view === ViewEmployee
                  ? <Col lg={3} className='col'>
                      <div className='number'>{ clients }</div>
                      Clients
                    </Col>
                  : view === ViewClient
                    ? <Col lg={3} className='col'>
                      <div className='number'>{ employees }</div>
                      Employees
                    </Col>
                    : <Col lg={1} className='col'></Col>}
                <Col lg={3} className='col'>
                  <div className='number'>{jobs}</div>
                  Jobs
                </Col>
                <Col lg={3} className='col'>
                  <div className='number'>{approved}</div>
                  Approved
                </Col>
                <Col lg={3} className='col'>
                  <div className='number'>{review} / {signed}</div>
                  To Review / Signed
                </Col>
                <Col lg={2} className='col'>
                  <div className='number'>{rejected}</div>
                  Rejected
                </Col>
                <Col lg={3} className='col'>
                  <div className='number'>{hours}</div>
                  Hours
                </Col>
                <Col lg={2} className='col'>
                  <div className='number'>{sleepovers || 0}</div>
                  S/O
                </Col>
                <Col lg={2} className='col'>
                  <div className='number'>{getups || 0}</div>
                  Getups
                </Col>
                <Col lg={3} className='col'>
                  <div className='number'>{kms}</div>
                  KMs
                </Col>
              </Row>
              : <Row>
                <Col lg={3} className='col'>
                  <div className='number'>{ clients }</div>
                  Clients
                </Col>
                <Col lg={3} className='col'>
                  <div className='number'>{ employees }</div>
                  Employees
                </Col>
                <Col lg={2} className='col'>
                  <div className='number'>{jobs}</div>
                  Jobs
                </Col>
                <Col lg={2} className='col'>
                  <div className='number'>{approved}</div>
                  Approved
                </Col>
                <Col lg={3} className='col'>
                  <div className='number'>{review} / {signed}</div>
                  To Review / Signed
                </Col>
                <Col lg={2} className='col'>
                  <div className='number'>{rejected}</div>
                  Rejected
                </Col>
                <Col lg={3} className='col'>
                  <div className='number'>{hours}</div>
                  Hours
                </Col>
                <Col lg={1} className='col'>
                  <div className='number'>{sleepovers || 0}</div>
                  S/O
                </Col>
                <Col lg={1} className='col'>
                  <div className='number'>{getups || 0}</div>
                  Getups
                </Col>
                <Col lg={3} className='col'>
                  <div className='number'>{kms}</div>
                  KMs
                </Col>
              </Row> }
          </div>

          <Page.Filter>
            <Row gutter={8}>
              <Col lg={8}>
                <ControlLabel>Search</ControlLabel>
                <SearchInput placeholder={`${searchText}`} onChange={(v) => this.onSearch(v)} isSearching={loadingSearch} />
              </Col>
              { view === ViewClient || view === ViewEmployee
                ? <Col lg={14}>
                  <ControlLabel>Status</ControlLabel>
                  <Radio.Group defaultValue='all' onChange={(v) => this.onFilter(v)}>
                    <Radio.Button value='all'> All </Radio.Button>
                    <Radio.Button value='approved'>Approved</Radio.Button>
                    <Radio.Button value='review'>To Review</Radio.Button>
                    <Radio.Button value='rejected'>Rejected</Radio.Button>
                    <Radio.Button value='signed'>Signed</Radio.Button>
                  </Radio.Group>
                </Col>
                : <Col lg={14}></Col> }
            </Row>
          </Page.Filter>

          <Form>
            <div className='payroll'>
              <Skeleton loading={loadingList} active>
                <Spin spinning={loadingNext} blur>
                  <div>
                    <List cols={columns} rows={list} />

                    <Pager
                      size={pageSize}
                      total={total}
                      totalText={`Total ${total} jobs`}
                      current={currentPage}
                      onChange={this.changePage}
                      style={{ marginTop: '15px' }}
                    />
                  </div>
                </Spin>
              </Skeleton>
            </div>
          </Form>

          <TimesheetTemplate
            info={signoffInfo}
            visible={isShowSignoff}
            onCloseSignoffModal={() => this.onCloseSignoff()}
            mode={'modal'}
          />


        </Page.Content>

        <SideModal
          title='Timesheet Reject'
          showModal={isShowModal}
          onClose={loadingForm ? null : () => this.handleRejectModal(false)}
          buttons={[
            <Button key={'tsrc'} type='primary' disabled={loadingForm} onClick={() => this.checkBeforeSave(selectedItem.id, { status: 'rejected' })}>Submit</Button>
          ]}
        >
          <Form>
            <FormItem label='Reason'>
              {getFieldDecorator('reject_reason_id', {
                rules: [
                  { required: true, message: 'Please select reason' }
                ]
              })(
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  disabled={loadingForm}
                >
                  {
                    rejectReasons.map((reason) => {
                      return <Option key={reason.name} value={reason.id}>{reason.name}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
            <FormItem label='Note'>
              {getFieldDecorator('reject_reason_note', {
                rules: []
              })(
                <TextArea rows={4} disabled={loadingForm} />
              )}
            </FormItem>
          </Form>
        </SideModal>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchTimesheet({ currentPage, filter, searchText, sort })
  }

  fetchTimesheet = async ({ currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      const { isLoaded } = this.state

      if (isLoaded) {
        this.setState({ loadingNext: true })
      } else {
        this.setState({ loadingList: true })
      }

      const view = this.getTimesheetView()
      let timesheet

      if (view === ViewEmployee) {
        timesheet = await fvpPayrollService.listEmployeeTimesheetByPage(currentPage, pageSize, filter, sort, searchText)
      } else if (view === ViewClient) {
        timesheet = await fvpPayrollService.listClientTimesheetByPage(currentPage, pageSize, filter, sort, searchText)
      } else if (view === ViewReview || view === ViewSigned) {
        timesheet = await fvpPayrollService.listStatusTimesheetByPage(view, currentPage, pageSize, filter, sort, searchText)
      }

      const { list, total } = timesheet
      this.setState({ currentPage, total, list, isLoaded: true, loadingList: false, loadingNext: false, loadingSearch: false }, () => {
        this.toTop()
      })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load timesheet successfully. Please try again later.')
    }
  }

  fetchAllSummary = async (isUpdate = false) => {
    const { match } = this.props
    const { params } = match
    const { pid, view, id } = params

    this.fetchSummary(pid, id, isUpdate)
  }

  fetchSummary = async (payrollId, id, isUpdate = false) => {
    try {
      this.setState({ loadingSummary: !isUpdate })

      const view = this.getTimesheetView()
      let timesheet

      if (view === ViewEmployee) {
        timesheet = await fvpPayrollService.getEmployeeTimesheetSummary(payrollId, id)
      } else if (view === ViewClient) {
        timesheet = await fvpPayrollService.getClientTimesheetSummary(payrollId, id)
      } else if (view === ViewReview || view === ViewSigned) {
        timesheet = await fvpPayrollService.getStatusTimesheetSummary(view, payrollId)
      }

      const { payroll_id, date_from, date_to, jobs, kms, hours, sleepovers, getups, employee, employees, client, clients, review, signed, approved, rejected } = timesheet

      this.setState({ payroll_id, date_from, date_to, jobs, kms, hours, sleepovers, getups, loadingSummary: false, employee, employees, client, clients, review, signed, approved, rejected })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load timesheet successfully. Please try again later.')
    }
  }

  fetchLeaveInfo = async (id) => {
    let individual = {}

    const view = this.getTimesheetView()

    if (view === ViewEmployee) {
      individual = await employeeService.get(id)
    } else if (view === ViewClient) {
      individual = await clientService.get(id)
    }

    if (validator.isNotEmptyObject(individual)) {
      const { item } = individual
      const { first_name, last_name, leave_id: leaveId, leave_start_date: leaveStart, leave_end_date: leaveEnd, leave_is_ufn: leaveIsUFN } = item
      const name = `${first_name} ${last_name}`
      this.setState({ leaveId, leaveStart, leaveEnd, leaveIsUFN, client: view === ViewClient ? name : this.state.client, employee: view === ViewEmployee ? name : this.state.employee })
    }
  }

  fetchRejectReasons = async () => {
    const filter = {}
    filter.code = { condition: '=', value: 'job-ts-reject' }

    const reasons = await settingReasonService.listItemsByPage(1, 0, filter)
    this.setState({ rejectReasons: reasons && validator.isNotEmptyArray(reasons.list) ? reasons.list : [] })
  }

  handleRejectModal = (isShowModal = false, selectedItem = {}) => {
    const { form } = this.props
    form.resetFields()

    this.setState({ isShowModal, selectedItem })
  }

  getTimesheetView () {
    const { match } = this.props
    const { params } = match
    const { view } = params

    return view || 'employee'
  }

  toTop () {
    if(window) {
      window.scrollTo(0, 0)
    }
  }
}

const mapDispatchToProps = {

}

const mapStateToProps = (state) => {
  return { ...state.PayrollFvp }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(IndividualTimesheet))
