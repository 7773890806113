import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { jvpJobService, clientService, authService } from '../../../../services'
import { ExportType, JobExportType, JobFilterType } from '../../../../constants'
import moment from 'moment-timezone'
import { exportFile, formatter, log, validator } from '../../../../util'
import { apiHostname, jobURL, jobSelectRangeMaxDay, timezone } from '../../../../config'

// UI
import { Loading, List, Pager, ControlLabel, SearchInput } from '../../../../components'
import notify from '../../../../components/Notification'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Popconfirm from 'antd/lib/popconfirm'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

import AddJobModal from '../../../JobJvp/AddJobModal'

const pageSize = 20

moment.tz.setDefault(timezone)

const { Item: FormItem } = Form
const { confirm } = Modal
const { RangePicker } = DatePicker

export class ClientJobsSingleListJvp extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      exportType: null,
      filter: {},
      filterParam: JobFilterType.JOB_TYPE_FUTURE,
      isAddJobModal: false,
      isLoaded: false,
      isSearching: false,
      jobSummary: {},
      list: [],
      loading: false,
      loadingExport: false,
      selectedFirstDateEmail: null,
      selectedFirstDateDownload: null,
      searchText: '',
      total: 0,
      showJobModal: false,
      showReminderDate: false,
      showExportDate: false
    }
    this.filterJob = this.filterJob.bind(this)
    this.onSearchName = debounce(this.onSearchName, 500)
  }

  componentDidMount () {
    this.refreshJob()
  }

  filterJob (e) {
    const { filterParam, loading, searchText, sort } = this.state
    const filterValue = e.target.value

    this.setState({ filterParam: filterValue })
    this.fetchClientJobs({ currentPage: 1, loading, searchText, sort, filterParam: filterValue })
  }

  onSearchName (value) {
    const { currentPage, filter, filterParam, loading, sort } = this.state

    value = value ? value.trim() : value

    if (value.indexOf(' ') >= 0) {
      const words = value.split(' ')

      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          const w = words[i]

          if (w) {
            filter.$and.push({
              $or: [
                { client_fullname: { condition: 'ilike', value: `%${w}%` } },
                { employee_fullname: { condition: 'ilike', value: `%${w}%` } },
                { funder_fullname: { condition: 'ilike', value: `%${w}%` } },
                { client_suburb: { condition: 'ilike', value: `%${w}%` } },
                { string_job_start_day: { condition: 'ilike', value: `%${w}%` } },
                { tasks: { condition: 'ilike', value: `%${w}%` } },
                { string_job_start_date: { condition: 'ilike', value: `%${w}%` } }
              ]
            })
          }
        }

        if (value && value.trim() !== '') {
          filter.$and.push({
            $or: [
              { client_fullname: { condition: 'ilike', value: `%${value}%` } },
              { employee_fullname: { condition: 'ilike', value: `%${value}%` } },
              { funder_fullname: { condition: 'ilike', value: `%${value}%` } },
              { client_suburb: { condition: 'ilike', value: `%${value}%` } },
              { string_job_start_day: { condition: 'ilike', value: `%${value}%` } },
              { tasks: { condition: 'ilike', value: `%${value}%` } },
              { string_job_start_date: { condition: 'ilike', value: `%${value}%` } }
            ]
          })
        }
      }
    } else {
      if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }
    this.fetchClientJobs({ currentPage, filter, loading, searchText: (filter.$and ? '' : value), sort, filterParam })
    this.setState({ searchText: value })
  }

  sendJobList(d1, d2) {
    const { clientId, data } = this.props
    const that = this
    this.setState({ showReminderDate: false, selectedFirstDateEmail: null })

    if (validator.isNotEmptyArray(d1)) {
      const startDate = d1[0]
      const endDate = d1[1]

      if (this.validateRangeMaxDay(startDate, endDate)) {
        confirm({
          title: `Send ${data.first_name} a reminder for jobs from ${formatter.toShortDate(startDate)} to ${formatter.toShortDate(endDate)}?`,
          content: 'Press Ok to continue, Cancel to return',
          async onOk () {
            try {
              that.setState({ loadingExport: true })
              const r = await exportFile.fetchListExport(ExportType.GENERAL.JOB_CLIENT_LIST, {id: clientId, from: startDate, to: endDate, isSendEmail: true})

              if (r && r.id) {
                notify.success('Send successfully', 'Jobs sent successfully.')
              }
            } catch (e) {
              console.log('client send job error', e, e.response)

              if (e && e.response && e.response.invalid) {
                notify.error('Unable to send job list', 'There is no job in the selected date range to send.')
              } else {
                notify.error('Unable to send successfully', 'Unable to send job list successfully. Please try again later.')
              }
            }

            that.setState({ loadingExport: false })
          }
        })
      } else {
        notify.error('Unable to Send Job List', 'You max excced the limit of days count for duration. Please select the dates again.')
      }
    }
  }

  async exportJobList (d1, d2) {
    const { clientId } = this.props
    const { exportType, filterParam } = this.state
    this.setState({ showExportDate: false, selectedFirstDateDownload: null })

    if (validator.isNotEmptyArray(d1)) {
      const startDate = d1[0]
      const endDate = d1[1]

      if (this.validateRangeMaxDay(startDate, endDate)) {
        try {
          this.setState({ loadingExport: true })
          const r = await exportFile.fetchListExport(ExportType.GENERAL.JOB_CLIENT_LIST, {id: clientId, from: startDate, to: endDate, isCancelJob: filterParam === JobFilterType.JOB_TYPE_CANCELLED ? true : false, exportType})

          setTimeout(() => {
            this.setState({ loadingExport: false })
          }, 2000)
        } catch (e) {
          this.setState({ loadingExport: false })
          notify.error('Unable to download jobs successfully', 'Unable download jobs successfully. Please try again later.')
        }
      } else {
        notify.error('Unable to Download Jobs', 'You max excced the limit of days count for duration. Please select the dates again.')
      }
    }
  }

  handleJobReminder = async (value) => {
    const { clientId, data, form } = this.props
    const { setFieldsValue } = form
    const values = { job_reminder: value }

    const reset = (msg) => {
      notify.error('Unable to set job reminder', msg)
      setTimeout(() => { setFieldsValue({ job_reminder: false }) }, 200)
    }

    if (data && data.email) {
      const r = await clientService.save(clientId, values)

      if (r && r.id) {
        log.updateClientText(r.id, `Job Reminder from "${!value}" to "${value}"`)
        notify.success('Updated successfully', 'Job reminder updated successfully.')
      } else {
        reset('Unable to update Job Reminder.')
      }
    } else {
      reset('Client does not have email provided.')
    }
  }

  render () {
    const { clientId, data, form } = this.props
    const { currentPage, loading, loadingExport, list, showJobModal, showReminderDate, showExportDate, total } = this.state
    const { getFieldDecorator } = form
    const isClientActive = !!data.active

    const sideBySideFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 15 },
      wrapperCol: { sm: 14, md: 14, lg: 9 }
    }

    const columns = [
      {
        title: '',
        width: 2,
        render: ({ emergency_invoice, emergency_pay, base_job_id, cancellation_penalty, is_sleepover_job, is_job_feedback }) => <div className='indicator-list'>
          { emergency_invoice & emergency_pay
            ? <div className='job-indicator' style={{ backgroundColor: '#ff526ebb' }}>EE</div>
            : emergency_invoice
              ? <div className='job-indicator' style={{ backgroundColor: '#ff5b5bbb' }}>EI</div>
              : emergency_pay
                ? <div className='job-indicator' style={{ backgroundColor: '#ea3471bb' }}>EP</div>
                : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >EE</div> }
          { cancellation_penalty ? <div className='job-indicator' style={{ backgroundColor: '#b17bcd' }}>LC</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >LC</div> }
          { base_job_id > 0 ? <div className='job-indicator' style={{ backgroundColor: '#1890ffbb' }}>R</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >R</div> }
          { is_sleepover_job ? <div className='job-indicator' style={{ backgroundColor: '#33ccff' }}>SO</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >SO</div> }
          { is_job_feedback ? <Tooltip title='Job Feedback'><div className='job-indicator' style={{ backgroundColor: '#f5a142' }}>F</div></Tooltip> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >F</div> }
        </div>
      },
      {
        title: 'Date',
        width: 2,
        render: ({ client_leave_id, is_frontend_unhide, job_start_date, holiday_date, holiday_name }) => holiday_date
          ? <div className={client_leave_id && !is_frontend_unhide ? `client-label-dim-unhover` : ''}>
              {formatter.toShortDate(job_start_date)}
              &nbsp;<Tooltip title={holiday_name} mouseEnterDelay={0} mouseLeaveDelay={0}>
                <Icon type='bell' theme='twoTone' twoToneColor='#ff0000' />
              </Tooltip>
            </div>
          : <div className={client_leave_id && !is_frontend_unhide ? `client-label-dim-unhover` : ''}>{formatter.toShortDate(job_start_date)}</div>
      },
      {
        title: 'Day',
        width: 2,
        render: ({ client_leave_id, is_frontend_unhide, string_job_start_day }) => <div className={client_leave_id && !is_frontend_unhide ? `client-label-dim-unhover` : ''}>{formatter.capitalize(string_job_start_day)}</div>
      },
      {
        title: 'Employees',
        width: 3,
        render: ({ client_leave_id, is_frontend_unhide, employee_fullname, employee_id, employee_leave_id, employee_leave_start_date, employee_leave_end_date, employee_active, employee_leave_is_ufn }) => employee_leave_id
        ? (
          <div style={{ color: '#ff0000', fontSize: '8.5pt' }}>
            <a className={`${!employee_active || (client_leave_id && !is_frontend_unhide) ? 'client-label-dim' : ''}`} href={`/employees/${employee_id}/info`} rel='noopener noreferrer' target='_blank'>{employee_fullname}</a>
            <div><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' />&nbsp;{`Leave ${formatter.toShortDate(employee_leave_start_date)} - ${employee_leave_is_ufn ? 'UFN' : formatter.toShortDate(employee_leave_end_date)}`}</div>
          </div>
        )
        : (
          <div>
            <a className={`${!employee_active || (client_leave_id && !is_frontend_unhide) ? 'client-label-dim' : ''}`} href={`/employees/${employee_id}/info`} rel='noopener noreferrer' target='_blank'>{employee_fullname}</a>
          </div>
        )
      },
      {
        title: 'Client Difficulty',
        width: 2,
        render: ({ client_leave_id, is_frontend_unhide, payroll }) => <div style={{ textTransform: 'capitalize' }} className={client_leave_id && !is_frontend_unhide ? `client-label-dim-unhover` : ''}>{payroll}</div>
      },
      {
        title: 'Start',
        width: 2,
        render: ({ client_leave_id, is_frontend_unhide, job_start_date }) => <div className={client_leave_id && !is_frontend_unhide ? `client-label-dim-unhover` : ''}>{formatter.toShortTime(job_start_date)}</div>
      },
      {
        title: 'End',
        width: 2,
        render: ({ client_leave_id, is_frontend_unhide, job_end_date }) => <div className={client_leave_id && !is_frontend_unhide ? `client-label-dim-unhover` : ''}>{formatter.toShortTime(job_end_date)}</div>
      },
      {
        title: 'Hours',
        width: 1,
        render: ({ client_leave_id, is_frontend_unhide, is_sleepover_job, job_start_date, job_end_date, jcs_id, jcs_is_forced_update, jps_id }) => {
          const isCostingUpdated = (jcs_id && jcs_is_forced_update) || !!jps_id
          const costingMsg = (jcs_id && jcs_is_forced_update) && !!jps_id
            ? `Costing - Invoice / Payroll updated`
            : (jcs_id && jcs_is_forced_update)
              ? `Costing - Invoice updated`
              : !!jps_id
                ? `Costing - Payroll updated`
                : ''

          return (<span className={client_leave_id && !is_frontend_unhide ? `client-label-dim-unhover` : ''}>
            {is_sleepover_job
              ? 1
              : formatter.toDurationDiff(job_end_date, job_start_date, 'hour')}&nbsp;
            { isCostingUpdated
              ? (<Tooltip
                title={<div>{costingMsg}</div>}
                mouseEnterDelay={0}
                mouseLeaveDelay={0}
              >
                <Icon style={{fontSize: '11pt'}} type='dollar' theme='twoTone' twoToneColor='#d60b29' />
              </Tooltip>)
              : null }
          </span>)
        }
      },
      {
        title: 'Location',
        width: 2,
        render: ({ client_leave_id, is_frontend_unhide, client_suburb, acvd_client_names, acvd_employee_names }) =>
          <div className={client_leave_id && !is_frontend_unhide ? `client-label-dim-unhover` : ''}>{client_suburb}
            {acvd_client_names || acvd_employee_names
              ? <span style={{cursor: 'pointer'}}>
                  &nbsp;
                  <Tooltip
                    title={(
                      <span>
                        {acvd_client_names ? <div>Same address with client: {acvd_client_names}</div> : ''}
                        {acvd_employee_names ? <div>Same address with employee: {acvd_employee_names}</div> : ''}
                      </span>
                    )}
                    mouseEnterDelay={0}
                    mouseLeaveDelay={0}
                  >
                    <Icon type='home' theme='twoTone' twoToneColor='#d60b29' />
                  </Tooltip>
                </span>
              : null}
          </div>
      },
      {
        title: 'Funder',
        width: 4,
        render: ({ client_leave_id, is_frontend_unhide, cf_id, funder_fullname, funder_id, cf_contact_client_for_jobs }) => <div style={{ textTransform: 'capitalize' }}>
          <a className={client_leave_id && !is_frontend_unhide ? `client-label-dim` : ''} href={`/funders/${funder_id}`} rel='noopener noreferrer' target='_blank'>{funder_fullname}</a>
          {!cf_id
            ? <div style={{ color: '#ff0000', fontSize: '9pt', textTransform: 'none' }}>
              <Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' />
              &nbsp;{`Outside of client's funding period`}</div>
            : null }
          { cf_contact_client_for_jobs
            ? <Tooltip title='Contact client for jobs' mouseEnterDelay={0} mouseLeaveDelay={0}>
              <Icon type='phone' style={{ color: '#1890ffbb'}} />
            </Tooltip>
            : null}
        </div>
      },
      {
        title: 'Action',
        width: 1,
        render: ({ id }) => <div className='action-buttons'>
          <Link to={`${jobURL}/single/${id}/info`}>
            <Tooltip mouseLeaveDelay={0} title='Manage Job'>
              <Icon type='form' style={{ color: 'orange' }} />
            </Tooltip>
          </Link>
        </div>
      }
    ]
    return (
      <Loading loading={loading} blur>
        <div className='search-bar'>
          <Row gutter={8}>
            <Col lg={8}>
              <ControlLabel>Client, Employee, Funder, Date, Day, Suburb, Tasks</ControlLabel>
              <SearchInput placeholder='Search' onChange={(v) => this.onSearchName(v)} />
            </Col>
            <Col lg={16} style={{display: 'flex', flexiDirection: 'horizontal', justifyContent: 'flex-end'}}>
              <div className='btn' onClick={() => this.refreshJob()}>
                Refresh
              </div>
              <div className='btn btn-ghost' onClick={() => this.openSendJobList()}>
                { loadingExport ? <img src='/icon/button-loading-color.svg' alt='loading' /> : `Send Job List` }
              </div>
              <div className='btn btn-ghost'  onClick={() => this.openDownloadJobList(JobExportType.EXPORT_JOB_COMPLETE)}>
                { loadingExport ? <img src='/icon/button-loading-color.svg' alt='loading' /> : `DL Job List (Full)` }
              </div>
              {/* <div className='btn btn-ghost'  onClick={() => this.openDownloadJobList(JobExportType.EXPORT_JOB_FEEDBACK)}>
                { loadingExport ? <img src='/icon/button-loading-color.svg' alt='loading' /> : `DL Job List (Feedback)` }
              </div> */}
              { this.hasAccess('createJob') && isClientActive
                ? <div className='btn' onClick={() => this.triggerAddJobModal(true)}>
                  Add Job
                </div>
                : null }
            </Col>
          </Row>
          <Row gutter={8}>
            <Col lg={14} />
            <Col lg={10}>
              <div style={{ display: showReminderDate ? 'inline' : 'none' }}>
                <RangePicker
                  format='YYYY-MM-DD'
                  open={showReminderDate}
                  disabledDate={(date) => this.validateSelectRange(date, 'selectedFirstDateEmail')}
                  onCalendarChange={(d1) => this.onRangeChange(d1, 'selectedFirstDateEmail')}
                  onChange={(d1, d2) => this.sendJobList(d1, d2)}
                  renderExtraFooter={() => <div style={{fontWeight: 'bold'}}>SEND JOB LIST</div>}
                />
              </div>
              <div style={{ display: showExportDate ? 'inline' : 'none' }}>
                <RangePicker
                  format='YYYY-MM-DD'
                  open={showExportDate}
                  disabledDate={(date) => this.validateSelectRange(date, 'selectedFirstDateDownload')}
                  onCalendarChange={(d1) => this.onRangeChange(d1, 'selectedFirstDateDownload')}
                  onChange={(d1, d2) => this.exportJobList(d1, d2)}
                  renderExtraFooter={() => <div style={{fontWeight: 'bold'}}>DOWNLOAD JOB LIST</div>}
                />
              </div>
            </Col>
          </Row>
        </div>

        <Row gutter={8}>
          <Col lg={8}>
            <Radio.Group onChange={this.filterJob} defaultValue={JobFilterType.JOB_TYPE_FUTURE} style={{ marginLeft: 20 }}>
              <Radio.Button value={JobFilterType.JOB_TYPE_FUTURE}>Future</Radio.Button>
              <Radio.Button value={JobFilterType.JOB_TYPE_PAST}>Past</Radio.Button>
              <Radio.Button value={JobFilterType.JOB_TYPE_CANCELLED}>Cancelled</Radio.Button>
            </Radio.Group>
          </Col>
          <Col lg={16} style={{display: 'flex', flexiDirection: 'horizontal', justifyContent: 'flex-end', paddingRight: 20}}>
            <FormItem {...sideBySideFormItemLayout} label='Job Reminder'>
              {getFieldDecorator('job_reminder', {
                initialValue: data.job_reminder || false,
                valuePropName: 'checked'
              })(
                <Switch
                  checkedChildren='Yes'
                  unCheckedChildren='No'
                  onChange={this.handleJobReminder}
                />
              )}
            </FormItem>
          </Col>
        </Row>

        <div className='setting-list'>
          <Skeleton loading={loading} active>
            <List cols={columns} rows={list} />
          </Skeleton>
        </div>
        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} job${total === 1 ? '' : 's'}`}
          current={currentPage}
          onChange={(e) => this.changePage(e)}
          style={{ marginTop: '15px' }}
        />

        { isClientActive
          ? <AddJobModal
              clientId={clientId}
              visible={showJobModal}
              history={this.props.history}
              onClose={() => this.triggerAddJobModal(false)}
            />
          : null }
      </Loading>
    )
  }

  refreshJob = async () => {
    const { currentPage, filter, filterParam, loading, searchText, sort } = this.state
    this.fetchClientJobs({ currentPage, filter, loading, searchText, sort, filterParam })
  }

  fetchClientJobs = async ({ startPage = null, currentPage = 1, filter = {}, sort = {}, searchText, filterParam }) => {
    this.setState({ loading: true })
    const { clientId } = this.props

    const page = startPage || currentPage
    const newFilter = { client_id: { condition: '=', value: clientId } }

    try {
      const { list, total } = await jvpJobService.listViewByPage(filterParam, currentPage, pageSize, newFilter, sort, searchText)

      this.setState({ list, filterParam, loading: false, total, currentPage: page, filter: newFilter, searchText })
    } catch (e) {
      notify.error('Unable to fetch Jobs', 'Please try again later')
    }
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort, filterParam } = this.state
    this.fetchClientJobs({ currentPage, filter, searchText, sort, filterParam })
  }

  onRangeChange = (d1, field) => {
    if (d1 && validator.isNotEmptyArray(d1) && d1[0]) {
      this.setState({ [field]: d1[0] })
    } else {
      this.setState({ [field]: null })
    }
  }

  validateSelectRange = (date, field) => {
    const value = this.state[field]

    return value ? (value.isAfter(date, 'day') || date.isAfter(value.clone().add(jobSelectRangeMaxDay, 'days'), 'days')) : false
  }

  validateRangeMaxDay (startDate, endDate) {
    if (startDate && endDate) {
      // +1 because the diff range could be between max day and may day + 1 and it would trigger error if select the last day of range
      return formatter.toDurationDiff(startDate, endDate, 'day') <= (jobSelectRangeMaxDay + 1)
    }

    return false
  }

  openDownloadJobList = (exportType = null) => {
    const { showExportDate, loadingExport } = this.state
    if (loadingExport) return

    this.setState({ showExportDate: !showExportDate, showReminderDate: false, selectedFirstDateDownload: null, exportType })
  }

  openSendJobList = () => {
    const { showReminderDate, loadingExport } = this.state
    if (loadingExport) return

    this.setState({ showReminderDate: !showReminderDate, showExportDate: false, selectedFirstDateEmail: null  })
  }

  triggerAddJobModal = (showJobModal) => {
    this.setState({ showJobModal })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }
}

const mapDispatchToProps = {

}

const mapStateToProps = (state) => {
  return { ...state.Employee }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientJobsSingleListJvp))
