import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import Moment from 'moment-timezone'
import { ExportType, FeedbackMenu } from '../../../constants'
import {
  authService, feedbackActionService, settingFeedbackCategoryService, settingFeedbackStatusService,
  settingFeedbackSubCategoryService
} from '../../../services'
import { fetchFeedbacks } from '../../../states/actions/feedback'
import { exportFile, formatter } from '../../../util'

import { Button, Checkbox, ControlLabel, FeedbackStatus, List, Page, Pager, SearchInput } from '../../../components'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const pageSize = 40
const { Option } = Select
const { RangePicker } = DatePicker

export class Feedback extends Component {
  constructor(props) {
    super(props)
    const { feedbacks: { list, total }, loading } = props
    this.state = {
      currentPage: 1,
      feedbackCategories: [],
      feedbackStatuses: [],
      feedbackSubCategories: [],
      filter: {},
      isCheckFeedback: true,
      isCheckMsgShow: false,
      isGenerating: false,
      isShowExportModal: false,
      list,
      loading,
      searching: false,
      searchText: '',
      sort: {},
      total
    }
    this.handleSearch = debounce(this.handleSearch, 500)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { feedbacks: { list, total }, loading } = nextProps
    const state = { ...prevState, list, loading, total }

    if (list !== prevState.list) {
      state.searching = false
    }

    return state
  }

  componentDidMount() {
    const { currentPage, filter, loading, searchText, sort } = this.state
    this.fetchFeedbacks({ currentPage, filter, loading, searchText, sort })
    this.fetchFeedbackCategories()
    this.fetchFeedbackStatuses()
    this.fetchFeedbackSubCategories()
  }

  render() {
    const {
      currentPage, feedbackCategories, feedbackStatuses, feedbackSubCategories, isCheckFeedback, isCheckMsgShow, isGenerating,
      isShowExportModal, list, loading, searching, total
    } = this.state
    const actionColumns = [
      {
        key: 'description',
        title: 'Actions Taken',
        width: 10
      },
      {
        key: 'created_at',
        title: 'Added',
        width: 3,
        render: ({ created_at: createdAt, created_by_name: createdByName }) => (
          <>
            <div>{formatter.toShortDate(createdAt)}</div>

            <div>{formatter.toShortTime(createdAt)}</div>

            <div className='subtitle'>{createdByName}</div>
          </>
        )
      },
      {
        key: 'updated_at',
        title: 'Updated',
        width: 3,
        render: ({ updated_at: updatedAt, updated_by_name: updatedByName }) => (
          <>
            <div>{updatedAt ? formatter.toShortDate(updatedAt) : ''}</div>

            <div>{updatedAt ? formatter.toShortTime(updatedAt) : ''}</div>

            <div className='subtitle'>{updatedByName}</div>
          </>
        )
      },
      {
        key: 'status',
        title: 'Status',
        width: 3,
        render: ({ status, status_colour: statusColour, status_name: statusName }) => status
          ? <FeedbackStatus colour={statusColour} label={statusName} />
          : null
      },
      {
        key: 'due_at',
        title: 'Due Date',
        width: 3,
        render: ({ due_at: dueAt }) => dueAt ? formatter.toShortDate(dueAt) : ''
      },
      {
        key: 'action',
        title: '',
        width: 1,
        render: (item) => {
          const { id, feedback_id: feedbackId } = item

          return (
            <div className='action-buttons'>
              {this.hasAccess('readFeedback') ? (
                <Link to={`/feedbacks/${feedbackId}/actions/${id}`}>
                  <div style={{ color: '#D66E00' }}>
                    <Tooltip mouseLeaveDelay={0} title='Manage action under feedback'>
                      <Icon type='form' />
                    </Tooltip>
                  </div>
                </Link>
              ) : <div style={{ width: '12px' }} />}
            </div>
          )
        }
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={FeedbackMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title='Feedbacks'>
            <Link to='/feedbacks/add'>
              {this.hasAccess('createFeedback') ? (
                <div className='btn'>
                  Add
                </div>
              ) : null}
            </Link>

            {this.hasAccess('listFeedbacks') ? (
              <div className='btn' onClick={() => this.handleExportModal(true)}>
                {isGenerating ? <img src='/icon/button-loading.svg' alt='loading' /> : 'Export'}
              </div>
            ) : null}
          </Page.Header>

          <Page.Filter>
            <Row gutter={8}>
              <Col sm={24} md={24} lg={6} xl={6} xxl={6}>
                <ControlLabel>FID, Employee, Client, Description, Location</ControlLabel>

                <SearchInput placeholder='Search feedback' onChange={this.handleSearch} isSearching={searching} />
              </Col>

              <Col sm={24} md={24} lg={4} xl={4} xxl={4}>
                <ControlLabel>Category</ControlLabel>

                <Select allowClear dropdownMatchSelectWidth={false} onChange={this.handleChangeFeedbackCategory} style={{ width: '100%' }}>
                  {feedbackCategories.map(({ id, name }) => <Option key={id}>{name}</Option>)}
                </Select>
              </Col>

              <Col sm={24} md={24} lg={4} xl={4} xxl={4}>
                <ControlLabel>Sub-Category</ControlLabel>

                <Select allowClear dropdownMatchSelectWidth={false} onChange={this.handleChangeFeedbackSubCategory} style={{ width: '100%' }}>
                  {feedbackSubCategories.map(({ id, name }) => <Option key={id}>{name}</Option>)}
                </Select>
              </Col>

              <Col sm={24} md={24} lg={4} xl={4} xxl={4}>
                <ControlLabel>Status</ControlLabel>

                <Select allowClear dropdownMatchSelectWidth={false} onChange={this.handleChangeStatus} style={{ width: '100%' }}>
                  {feedbackStatuses.map(({ id, name }) => <Option key={id}>{name}</Option>)}
                </Select>
              </Col>
            </Row>

            <div style={{ height: 10 }} />

            <Row gutter={8}>
              <Col sm={24} md={24} lg={6} xl={6} xxl={6}>
                <ControlLabel>Incident Date</ControlLabel>

                <RangePicker allowClear disabledDate={this.disableFutureDate} onChange={this.handleChangeOccurredAt} style={{ width: '100%' }} />
              </Col>

              <Col sm={24} md={24} lg={6} xl={6} xxl={6}>
                <ControlLabel>Report Date</ControlLabel>

                <RangePicker allowClear disabledDate={this.disableFutureDate} onChange={this.handleChangeReportedAt} style={{ width: '100%' }} />
              </Col>
            </Row>
          </Page.Filter>

          <div className='feedbacks'>
            <Skeleton loading={loading} active avatar>
              {list.map((item, idx) => {
                const {
                  id, case_no: caseNo, category_name: categoryName, client_id: clientId, description, employee_id: employeeId,
                  feedback_actions: feedbackActions, is_expand: isExpand, is_reportable: isReportable, job_id: jobId, location,
                  occurred_at: occurredAt, reported_at: reportedAt, rb_client_first_name: rbClientFirstName,
                  rb_client_last_name: rbClientLastName, rb_employee_first_name: rbEmployeeFirstName,
                  rb_employee_last_name: rbEmployeeLastName, status, status_colour: statusColour, status_name: statusName,
                  sub_category_name: subCategoryName, updated_at: updatedAt
                } = item
                let { total_actions: totalActions } = item
                totalActions = formatter.toNumber(totalActions)

                return (
                  <div className={formatter.toClassName(['list-item', isReportable ? 'reportable' : ''])} key={id}>
                    <Row gutter={12}>
                      <Col lg={23}>
                        <Row className='divider'>
                          <Col lg={2}>
                            <div className='subtitle'>FID</div>

                            <div className='name title'>#{caseNo}</div>
                          </Col>

                          <Col lg={3}>
                            <div className='subtitle'>Incident Date</div>

                            <div className='name date'>{formatter.toShortDate(occurredAt)}</div>
                          </Col>

                          <Col lg={3}>
                            <div className='subtitle'>Report Date</div>

                            <div className='name date'>{formatter.toShortDate(reportedAt)}</div>
                          </Col>

                          <Col lg={4}>
                            <div className='subtitle'>Category</div>

                            <div className='name'>{categoryName} ({subCategoryName})</div>
                          </Col>

                          <Col lg={4}>
                            <div className='subtitle'>Location</div>

                            <div className='name'>{location}</div>
                          </Col>

                          <Col lg={4}>
                            <div className='subtitle'>Updated At</div>

                            <div className='name date'>{formatter.toStandardDate(updatedAt)}</div>
                          </Col>

                          <Col className='status' lg={4}>
                            {status
                              ? <FeedbackStatus colour={statusColour} label={statusName} />
                              : null}
                          </Col>
                        </Row>
                      </Col>

                      <Col lg={1} className='action-icon'>
                        {this.hasAccess('readFeedback') ? (
                          <Link to={`/feedbacks/${id}`}>
                            <div>
                              <Tooltip mouseLeaveDelay={0} title='Manage feedback'>
                                <Icon type='form' />
                              </Tooltip>
                            </div>
                          </Link>
                        ) : null}
                      </Col>
                    </Row>

                    <Row className='sub-row'>
                      <Col lg={4}>
                        <div className='expandable subtitle'>
                          <div>Actions Taken</div>

                          {totalActions > 0 ? (
                            <div className='icon' onClick={this.handleExpand(idx)}>
                              <Icon type={isExpand ? 'up-circle' : 'down-circle'} />
                            </div>
                          ) : null}
                        </div>

                        <div className='message'>
                          {totalActions > 0
                            ? `${totalActions} action${totalActions > 1 ? 's' : ''} taken`
                            : 'No action taken'}
                        </div>
                      </Col>

                      <Col lg={4}>
                        <div className='subtitle'>Employee</div>

                        <div className='name'>
                          {employeeId ? (
                            <Link
                              className='link' rel='noopener noreferrer' target='_blank' to={`/employees/${employeeId}`}
                            >
                              {rbEmployeeFirstName} {rbEmployeeLastName}
                            </Link>
                          ) : '-'}
                        </div>
                      </Col>

                      <Col lg={4}>
                        <div className='subtitle'>Client</div>

                        <div className='name'>
                          {clientId ? (
                            <Link
                              className='link' rel='noopener noreferrer' target='_blank' to={`/clients/${clientId}`}
                            >
                              {rbClientFirstName} {rbClientLastName}
                            </Link>
                          ) : '-'}
                        </div>
                      </Col>

                      <Col lg={3}>
                        <div className='subtitle'>Job</div>

                        <div className='name'>
                          {jobId ? (
                            <Link
                              className='link' rel='noopener noreferrer' target='_blank' to={`/jobs/single/${jobId}`}
                            >
                              Job #{jobId}
                            </Link>
                          ) : '-'}
                        </div>
                      </Col>

                      <Col lg={8}>
                        <div className='subtitle'>Description</div>

                        <div className='name'>{description}</div>
                      </Col>
                    </Row>

                    <Row>
                      {totalActions > 0 && isExpand ? (
                        Array.isArray(feedbackActions)
                          ? <List className='feedback-actions' cols={actionColumns} rows={feedbackActions} />
                          : <Skeleton loading active avatar></Skeleton>
                      ) : null}
                    </Row>
                  </div>
                )
              })}
            </Skeleton>
          </div>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} feedbacks`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />

          {isShowExportModal ? (
            <Modal
              width='450px'
              title='Select Feedback Export Items'
              visible={isShowExportModal}
              onCancel={() => this.handleExportModal(false)}
              footer={[
                <div>
                  <Button key='close' ghost feedback={isGenerating} onClick={() => this.handleExportModal(false)}>Cancel</Button>
                  <Button key='submit' feedback={isGenerating} onClick={() => this.preCheckExport()}>Download</Button>
                </div>
              ]}
            >
              <Spin spinning={isGenerating} blur>
                <Form>
                  <div className='inv-title'>Please select item(s) to export:</div>

                  <span>
                    <Checkbox
                      checked={isCheckFeedback}
                      onClick={f => this.handleCheckboxClick(f, { isCheckFeedback: true })}
                    />
                    <span className='checkbox-text'>Feedback Export</span>
                  </span>

                  <div className='checkbox-warning-text'>{isCheckMsgShow ? `Please select at least one item.` : ''}</div>
                </Form>
              </Spin>
            </Modal>
          ) : null}
        </Page.Content>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchFeedbacks({ currentPage, filter, searchText, sort })
  }

  disableFutureDate = (current) => {
    return current && current.isAfter()
  }

  export = async () => {
    const { filter, isCheckFeedback, searchText } = this.state
    const data = {
      export_feedback: isCheckFeedback,
      query: { filter: JSON.stringify(filter) }
    }

    try {
      this.setState({ isGenerating: true })

      const r = await exportFile.fetchExport(ExportType.GENERAL.EXPORT_FEEDBACK, data)

      setTimeout(() => {
        this.setState({ isGenerating: false, isShowExportModal: false })
      }, 7000)
    } catch (e) {
      notify.error('Unable to export', 'Unable to get feedback export successfully. Please try again later.')
      this.setState({ isGenerating: false, isShowExportModal: false })
    }
  }

  fetchFeedbacks = async ({ loading = true, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      const { fetchFeedbacks } = this.props
      this.setState({ currentPage }, () => {
        fetchFeedbacks({ currentPage, filter, loading, pageSize, searchText, sort })
      })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load feedbacks successfully. Please try again later.')
    }
  }

  fetchFeedbackCategories = async () => {
    settingFeedbackCategoryService.getAll().then((feedbackCategories) => {
      this.setState({ feedbackCategories: Array.isArray(feedbackCategories) ? feedbackCategories : [] })
    })
  }

  fetchFeedbackStatuses = async () => {
    settingFeedbackStatusService.getAll().then((feedbackStatuses) => {
      this.setState({ feedbackStatuses: Array.isArray(feedbackStatuses) ? feedbackStatuses : [] })
    })
  }

  fetchFeedbackSubCategories = async () => {
    settingFeedbackSubCategoryService.getAll().then((feedbackSubCategories) => {
      this.setState({ feedbackSubCategories: Array.isArray(feedbackSubCategories) ? feedbackSubCategories : [] })
    })
  }

  handleChangeFeedbackCategory = (value) => {
    const { filter, searchText, sort } = this.state
    this.setState({ filter }, () => {
      if (value) {
        filter.category_id = value
      } else {
        delete filter.category_id
      }

      this.fetchFeedbacks({ currentPage: 1, filter, loading: true, searchText, sort })
    })
  }

  handleChangeFeedbackSubCategory = (value) => {
    const { filter, searchText, sort } = this.state
    this.setState({ filter }, () => {
      if (value) {
        filter.sub_category_id = value
      } else {
        delete filter.sub_category_id
      }

      this.fetchFeedbacks({ currentPage: 1, filter, loading: true, searchText, sort })
    })
  }

  handleChangeFeedbackType = (value) => {
    const { filter, searchText, sort } = this.state
    this.setState({ filter }, () => {
      if (value) {
        filter.feedback_type_id = value
      } else {
        delete filter.feedback_type_id
      }

      this.fetchFeedbacks({ currentPage: 1, filter, loading: true, searchText, sort })
    })
  }

  handleChangeOccurredAt = (value) => {
    const { filter, searchText, sort } = this.state
    this.setState({ filter }, () => {
      if (Array.isArray(value) && value.length > 1) {
        filter.occurred_at = {
          $and: [{ condition: '>=', value: Moment(value[0]).startOf('day') }, { condition: '<=', value: Moment(value[1]).endOf('day') }]
        }
      } else {
        delete filter.occurred_at
      }

      this.fetchFeedbacks({ currentPage: 1, filter, loading: true, searchText, sort })
    })
  }

  handleChangeReportedAt = (value) => {
    const { filter, searchText, sort } = this.state
    this.setState({ filter }, () => {
      if (Array.isArray(value) && value.length > 1) {
        filter.reported_at = {
          $and: [{ condition: '>=', value: Moment(value[0]).startOf('day') }, { condition: '<=', value: Moment(value[1]).endOf('day') }]
        }
      } else {
        delete filter.reported_at
      }

      this.fetchFeedbacks({ currentPage: 1, filter, loading: true, searchText, sort })
    })
  }

  handleChangeStatus = (value) => {
    const { filter, searchText, sort } = this.state
    this.setState({ filter }, () => {
      if (value) {
        filter.status_id = value
      } else {
        delete filter.status_id
      }

      this.fetchFeedbacks({ currentPage: 1, filter, loading: true, searchText, sort })
    })
  }

  handleCheckboxClick(e, { isCheckFeedback }) {
    const check = e.target.checked
    this.setState({
      isCheckFeedback: isCheckFeedback === undefined ? this.state.isCheckFeedback : check
    }, () => {
      const { isCheckFeedback } = this.state
      if (!(isCheckFeedback)) {
        this.setState({ isCheckMsgShow: true })
      } else {
        this.setState({ isCheckMsgShow: false })
      }
    })
  }

  handleExpand = (index) => () => {
    const { list } = this.state

    if (index < list.length) {
      list[index].is_expand = !list[index].is_expand
    }

    this.setState({ list }, () => {
      const { id } = list[index]
      feedbackActionService.listByPage(1, 0, { feedback_id: id }).then((response) => {
        if (response && Array.isArray(response.list)) {
          list[index].feedback_actions = response.list
          this.setState({ list })
        }
      })
    })
  }

  handleExportModal(isShowExportModal) {
    this.setState({ isShowExportModal })
  }

  handleSearch = (value) => {
    const { filter, loading, sort } = this.state
    this.setState({ searching: true }, () => {
      value = value ? value.trim() : value

      if (value.indexOf(' ') >= 0) {
        const words = value.split(' ')

        if (Array.isArray(words)) {
          filter.$and = []

          for (let i = 0; i < words.length; i++) {
            filter.$and.push({
              $or: [
                { case_no: { condition: 'ilike', value: `%${words[i]}%` } },
                { rb_client_first_name: { condition: 'ilike', value: `%${words[i]}%` } },
                { rb_client_last_name: { condition: 'ilike', value: `%${words[i]}%` } },
                { rb_employee_first_name: { condition: 'ilike', value: `%${words[i]}%` } },
                { rb_employee_last_name: { condition: 'ilike', value: `%${words[i]}%` } },
                { description: { condition: 'ilike', value: `%${words[i]}%` } },
                { location: { condition: 'ilike', value: `%${words[i]}%` } }
              ]
            })
          }
        }
      } else {
        if (Array.isArray(filter.$and)) {
          delete filter.$and
        }
      }

      this.setState({ currentPage: 1, searchText: value }, () => {
        this.fetchFeedbacks({ currentPage: 1, filter, loading, searchText: (filter.$and ? '' : value), sort })
      })
    })
  }

  preCheckExport() {
    const { isCheckMsgShow } = this.state

    if (!isCheckMsgShow) {
      this.export()
    }
  }

  hasAccess(accessLevel) {
    return authService.hasAccess(accessLevel)
  }
}

const mapDispatchToProps = {
  fetchFeedbacks
}

const mapStateToProps = (state) => {
  return { ...state.Feedback }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Feedback)
